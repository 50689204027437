import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import moment from "moment";

const UserView = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [userViewDetails, setUserViewDetails] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [deviceDetails, setdeviceDetails] = useState(null);
  const [invitedBy, setInvitedBy] = useState(null);

  const userView = () => {
    let data = {
      url: "userView",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setUserViewDetails(res.data.data);
        setReferrals(res.data.data.referrels);
        setdeviceDetails(res.data.data.deviceDetails);
        setInvitedBy(res.data.data.invitedBy);
      }
      else {
        common.notify("E", res?.data?.message);
      }
    });
  };


  useEffect(() => {
    userView();
  }, []);

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-3">
          <div className="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  User Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <button
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      {userViewDetails ? (
        <div className="container-fluid px-4 mb-4 overflow-auto h-100 flex-fill">
          <div className="row h-100">
            <div className="col-12 mb-4">
              <div className="bg-muted-50 p-4 rounded-1 h-100">
                <div className="row">
                  <div className="col-lg-4 mb-3">
                    <h5>Profile Image</h5>
                    <div className="user-img">
                      {userViewDetails?.profilePicLink ? (
                        <img
                          src={userViewDetails?.profilePicLink}
                          alt="Profile Image"
                        />
                      ) : (
                        <img
                          src="/images/dummyImage.png"
                          alt="Dummy Image"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">First Name</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.firstName}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Last Name</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.lastName}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Email</h5>
                    <p className="fs-6 mb-0 fw-normal text-break">
                      {userViewDetails?.email}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Login Type</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.loginType}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Cancer Type</h5>

                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.cancerTypes &&
                        userViewDetails.cancerTypes.length > 0
                        ? userViewDetails.cancerTypes
                          .map((each) => each?.label)
                          .join(",")
                        : "Not Available"}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Created At</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {moment(userViewDetails?.createdAt).format(
                        "MM-DD-YYYY"
                      )}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Gender</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.gender?.label
                        ? userViewDetails?.gender?.label
                        : "Not Available"}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Phone Number</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {userViewDetails?.phoneNumber
                        ? `+1 ${userViewDetails?.phoneNumber}`
                        : "Not Available"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">User Type</h5>
                    <p className="fs-6 mb-0 fw-normal text-capitalize">
                      {userViewDetails?.userType &&
                        userViewDetails.userType.length > 0
                        ? userViewDetails.userType
                          .map((each) => each?.label)
                          .join(",")
                        : "Not Available"}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Age Group</h5>
                    <p className="fs-6 mb-0 fw-normal text-capitalize">
                      {userViewDetails?.ageGroup.label
                        ? userViewDetails.ageGroup.label
                        : "Not Available"}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Ethnicity</h5>
                    <p className="fs-6 mb-0 fw-normal text-capitalize">
                    {userViewDetails?.race.label
                        ? userViewDetails.race.label
                        : "Not Available"}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Referrels Count</h5>
                    <p className="fs-6 mb-0 fw-normal text-capitalize">
                      {userViewDetails && userViewDetails["Referrels Count"] !== undefined
                        ? userViewDetails["Referrels Count"]
                        : "Not Available"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="bg-muted-50 rounded-1 h-100 tabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link border-0 active"
                      id="referrals-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#referrals-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="referrals-tab-pane"
                      aria-selected="true"
                    >
                      Referrals
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link border-0"
                      id="invited-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#invited-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="invited-tab-pane"
                      aria-selected="false"
                    >
                      Invited by
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link border-0"
                      id="device-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#device-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="device-tab-pane"
                      aria-selected="false"
                    >
                      Device details
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-3" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="referrals-tab-pane"
                    role="tabpanel"
                    aria-labelledby="referrals-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="bg-muted-50">
                          <tr className="text-nowrap">
                            <th scope="col">Name</th>
                            <th scope="col">Contact no</th>
                            <th scope="col">User type</th>
                            <th scope="col">Invited on</th>
                            <th scope="col">Is Registered</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referrals.length > 0 ? referrals.map(
                            ({
                              name,
                              phoneNumber,
                              userType,
                              invitedOn,
                              isRegistered,
                            }) => {
                              return (
                                <tr key={name}>
                                  <th>{name}</th>
                                  <th>{phoneNumber ? phoneNumber : "-"}</th>
                                  <th>{userType}</th>
                                  <th>
                                    {moment(invitedOn).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </th>
                                  <th>{isRegistered === true ? "Registered" : "NotRegistered"}</th>
                                </tr>
                              );
                            }
                          ) :
                            <tr>
                              <td colspan="7" style={{ textAlign: "center" }}>No Data Found!</td>
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="invited-tab-pane"
                    role="tabpanel"
                    aria-labelledby="invited-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="bg-muted-50">
                          <tr className="text-nowrap">
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Contact no</th>
                            <th scope="col">User type</th>
                            <th scope="col">Invited on</th>
                            <th scope="col">Joined on</th>
                          </tr>
                        </thead>
                        <tbody>

                          {invitedBy.length > 0 ? invitedBy.map(
                            ({
                              name,
                              email,
                              phoneNumber,
                              userType,
                              invitedOn,
                              joindOn,
                            }) => {
                              return (
                                <tr key={name}>
                                  <th>{name}</th>
                                  <th>{email}</th>
                                  <th>{phoneNumber ? phoneNumber : "-"}</th>
                                  <th>{userType}</th>
                                  <th>
                                    {moment(invitedOn).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </th>
                                  <th>
                                    {moment(joindOn).format("MM-DD-YYYY")}
                                  </th>
                                </tr>
                              );
                            }
                          ) :
                            <tr>
                              <td colspan="7" style={{ textAlign: "center" }}>No Data Found!</td>
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="device-tab-pane"
                    role="tabpanel"
                    aria-labelledby="device-tab"
                    tabIndex="0"
                  >
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="bg-muted-50">
                          <tr className="text-nowrap">
                            <th scope="col">Device ID</th>
                            <th scope="col">Device Model</th>
                            <th scope="col">Device Type</th>
                            <th scope="col">OS Type</th>
                            <th scope="col">App Version</th>
                            <th scope="col">IP Address</th>
                            <th Scope="col">Install Date</th>
                            <th scope="col">Last Login</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deviceDetails.length > 0 ?
                            deviceDetails.map(
                              ({
                                deviceId,
                                phoneBrand,
                                deviceType,
                                deviceOs,
                                appVersion,
                                lastLogin,
                                ipAddress,
                                firstInstallDate,
                              }) => {
                                return (
                                  <tr key={deviceId}>
                                    <th>{deviceId}</th>
                                    <th>{phoneBrand}</th>
                                    <th>{deviceType}</th>
                                    <th>{deviceOs}</th>
                                    <th>{appVersion}</th>
                                    <th>{ipAddress}</th>
                                    <th>{moment(firstInstallDate).format(
                                      "MM-DD-YYYY"
                                    )}</th>
                                    <th>{moment(lastLogin).format(
                                      "MM-DD-YYYY HH:mm:ss"
                                    )}</th>

                                  </tr>
                                );
                              }
                            ) :
                            <tr>
                              <td colspan="7" style={{ textAlign: "center" }}>No Data Found!</td>
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

    </>
  );
};

export default UserView;
