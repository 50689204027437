import SideNav from "./sidebar";
import Header from "./header";

import { Navigate } from "react-router-dom";
//import { common} from "helpers";

const Main = ({ children }) => {
  function RequireAuth({ children }) {
    let tokens = localStorage.getItem("token");
    return tokens ? children : <Navigate to={"/login"} />;
  }

  return (
    <RequireAuth>
      <div className="d-flex h-100 w-100 flex-row overflow-hidden">
        <div className="p-3 col-sm-2 d-flex flex-column bg-dark h-100">
          <div className="text-start">
            <img src="/images/logo1.png" className="w-75" alt="" />
          </div>
          <SideNav></SideNav>
        </div>
        <div className="w-100 h-100 d-flex flex-column overflow-auto">
          <Header></Header>
          <hr />
          {children}
        </div>
      </div>
    </RequireAuth>
  );
};

export default Main;
