import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import { ValidateForm } from "./validation";
import ModalPopup from '../../elements/Modal';

const EpisodeEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [accordion, setAccordion] = useState(true);
  const { handleEditEpisodeSubmit, handleEditFormSubmit } = ValidateForm();
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [error, setError] = useState([]);
  const [inputEpisodeList, setinputEpisodeList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [episodeName, setEpisodeName] = useState("");
  const [episodeDescription, setEpisodeDescription] = useState("");
  const [showEpisodeErrorNotification, setShowEpisodeErrorNotification] =
    useState(false);
  useEffect(() => {
    getVideoList();
    getQuestionDetails();
  }, []);

  useEffect(() => {}, [showErrorNotification, showEpisodeErrorNotification]);

  useEffect(() => {
    if (isFormSubmitted) {
      if (episodeName !== "" && episodeDescription !== "") {
        setShowEpisodeErrorNotification(false);
        setShowErrorNotification(false);
        setError([]);
      } else {
        let formErrors = {};
        if (episodeName === "") {
          formErrors.episodeName = "Please select episode name";
        }
        if (episodeDescription === "") {
          formErrors.episodeDescription = "Please select episode description";
        }
        setShowEpisodeErrorNotification(true);
        setError(formErrors);
      }
    }
  }, [episodeName, episodeDescription]);

  const getQuestionDetails = () => {
    let data = {
      url: "episodeGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setEpisodeDescription(res?.data?.data[0].episodeDescription);
        setEpisodeName(res?.data?.data[0].episodeName);
        setinputEpisodeList(res?.data?.data[0].videos);
      } else {
        common.notify("E", res?.data?.message);
        navigate("/episode");
      }
    });
  };

  const getVideoList = () => {
    let data = {
      url: "vedioSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoList(res?.data?.data);
      }
    });
  };


  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  const handleEpisodeInputChange = (value, index, fieldName) => {
    const list = [...inputEpisodeList];
    if (fieldName === "displayOrder") value = Number(value);
    list[index][fieldName] = value;
    setinputEpisodeList(list);
  };


  const handleEpisodeAddClick = () => {
    let lastQuestion = inputEpisodeList[inputEpisodeList.length - 1];
    let response = handleEditEpisodeSubmit(lastQuestion);
    if (!response.isFormValid) {
      setShowErrorNotification(true);
      setError(response.formErrors);
    } else {
      setAccordion(true);
      setShowErrorNotification(false);
      setinputEpisodeList([
        ...inputEpisodeList,
        {
          videoId: "",
          displayOrder: 0,
        },
      ]);
    }
  };

  const handleEpisodeRemoveClick = (index) => {
    const list = [...inputEpisodeList];
    list.splice(index, 1);
    setinputEpisodeList(list);
  };

  const handleCurrentEpisodeRemoveClick = (index) => {
    const list = [...inputEpisodeList];
    list.splice(inputEpisodeList.length - 1, 1);
    setinputEpisodeList(list);
    setShowErrorNotification(false);
    setError([]);
  };

  const handleSubmit = (e) => {
    let lastQuestion = inputEpisodeList[inputEpisodeList.length - 1];
    setIsFormSubmitted(true);
    let response = handleEditEpisodeSubmit(lastQuestion);
    if (!response.isFormValid) {
      setShowErrorNotification(true);
      setError(response.formErrors);
    } else {
      let formErrors = {};
      if (episodeName !== "" && episodeDescription !== "") {
        setShowEpisodeErrorNotification(false);
        setShowErrorNotification(false);
        setError([]);
        let res = handleEditFormSubmit(inputEpisodeList);
        if (res.isFormValid) {
          setShowEpisodeErrorNotification(false);
          let payloadEpisode = JSON.parse(JSON.stringify(inputEpisodeList));
          payloadEpisode.map((episode) => {
            delete episode.videoTitle;
            delete episode._id;
            
            return true;

        });
        const payload = {
          episodeName: episodeName,
          episodeDescription: episodeDescription,
          videos: payloadEpisode,
          releaseDate: "2022-09-06T18:34:19.767+05:30",
        };
          let data = {
            url: "episodeUpdate",
            method: "POST",
            query: `${id}`,
            body: payload,
          };
          api
            .call(data, async (res) => {
              if (res.data.status === "success") {
                navigate("/episode");
                common.notify("S", res.data.msg);
              } else {
                window.scrollTo(0, 0);
                common.notify("E", res.data.message);
              }
            })
            .catch((err) => {
              window.scrollTo(0, 0);
              common.notify("E", err);
            });
        } else {
          setShowEpisodeErrorNotification(true);
          setShowErrorNotification(true);
          setError(res.formErrors);
        }
      } else {
        if (episodeName === "") {
          formErrors.episodeName = "Please enter title";
        }
        if (episodeDescription === "") {
          formErrors.episodeDescription = "Please enter description";
        }

        setShowEpisodeErrorNotification(true);
        setError(formErrors);
      }
    }
  };



  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="fw-medium mb-4">Edit Episodes & group</h5>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 rounded h-100">
              <div class="p-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Title
                        <i className="star">*</i>
                      </label>
                      <input
                        type="text"
                        id="input1"
                        class="form-control form-control-lg border-0"
                        placeholder="User name"
                        name="episodeName"
                        value={episodeName || ""}
                        onChange={(e) => setEpisodeName(e.target.value)}
                      />
                    </div>
                    <p className="error-field">{error.episodeName}</p>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label for="input2" class="form-label">
                        Description
                        <i className="star">*</i>
                      </label>
                      <textarea
                        class="form-control form-control-lg border-0"
                        id="exampleFormControlTextarea1"
                        rows="1"
                        placeholder="Description"
                        name="episodeDescription"
                        value={episodeDescription || ""}
                        onChange={(e) => setEpisodeDescription(e.target.value)}
                      ></textarea>
                    </div>
                    <p className="error-field">{error.episodeDescription}</p>
                  </div>
                  {showErrorNotification ? (
                    <div className="d-table-caption">
                      {error.requiredFields ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.requiredFields}{" "}
                        </span>
                      ) : null}
                      {error.duplicateEpisodes ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.duplicateEpisodes}{" "}
                        </span>
                      ) : null}
                      {error.displayOrder ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.displayOrder}{" "}
                        </span>
                      ) : null}
                      
                    </div>
                  ) : null}

                  <div class="col-sm-12">
                    {accordion
                      ? inputEpisodeList.map((item, i) =>
                          inputEpisodeList.length !== i + 1 ? (
                            <div class="bg-muted-50 rounded p-3 mb-3">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="accordion" id="accordionExample">
                                    <div class="accordion-item border-0">
                                      <h2
                                        class="accordion-header d-flex"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button collapsed px-0 pb-3"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          <span class="col-6 pe-3">
                                            Video: {item.videoTitle || ""}
                                          </span>
                                          <span class="ms-2">
                                            Display Order:{" "}
                                            {item.displayOrder || ""}
                                          </span>
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-muted text-danger d-flex p-2 align-self-center align-items-center btn-trash"
                                          onClick={(e) => {
                                            if (
                                              window.confirm(
                                                "Are you sure you wish to delete this item?"
                                              )
                                            )
                                              handleEpisodeRemoveClick(i);
                                          }}
                                        >
                                          <iconify-icon
                                            icon="mdi:trash"
                                            class="fa-2x align-self-center"
                                          ></iconify-icon>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body px-0">
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <div class="mb-md-0 mb-3">
                                                <label
                                                  for="input2"
                                                  class="form-label"
                                                >
                                                  Video
                                                </label>
                                                <select
                                                  class="form-select form-select-lg"
                                                  aria-label="Default select example"
                                                  value={item.videoId || ""}
                                                  name="videoId"
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e.target.value,
                                                      i,
                                                      "videoId"
                                                    )
                                                  }
                                                >
                                                  {videoList.length > 0
                                                    ? videoList.map((item) => (
                                                        <option
                                                          value={item.value}
                                                        >
                                                          {item.label}
                                                        </option>
                                                      ))
                                                    : null}
                                                </select>
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                              <div class="mb-md-0 mb-3">
                                                <label
                                                  for="exampleFormControlInput1"
                                                  class="form-label"
                                                >
                                                  Display Order
                                                </label>
                                                <input
                                                  type="text"
                                                  id="input1"
                                                  class="form-control form-control-lg border-0"
                                                  placeholder="Enter display order"
                                                  value={
                                                    item.displayOrder || ""
                                                  }
                                                  min="1"
                                                  onKeyPress={(e) =>
                                                    numberValidation(e)
                                                  }
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e.target.value,
                                                      i,
                                                      "displayOrder"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )
                      : null}
                    {inputEpisodeList.map((x, i, inputEpisodeList) => {
                      if (i + 1 === inputEpisodeList.length) {
                        return (
                          <div class="bg-muted-50 rounded p-3">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-md-0 mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Video
                                    <i className="star">*</i>
                                  </label>

                                  <select
                                    class="form-select form-select-lg"
                                    aria-label="Default select example"
                                    value={x.videoId || ""}
                                    name="videoId"
                                    onChange={(e) =>
                                      handleEpisodeInputChange(
                                        e.target.value,
                                        i,
                                        "videoId"
                                      )
                                    }
                                  >
                                    <option selected="">Select video</option>

                                    {videoList.length > 0
                                      ? videoList.map((item) => (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        ))
                                      : null}
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="mb-md-0 mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Display Order
                                    <i className="star">*</i>
                                  </label>
                                  <input
                                    type="text"
                                    id="input1"
                                    class="form-control form-control-lg border-0"
                                    placeholder="Enter display order"
                                    value={x.displayOrder || ""}
                                    min="1"
                                    onKeyPress={(e) => numberValidation(e)}
                                    onChange={(e) =>
                                      handleEpisodeInputChange(
                                        e.target.value,
                                        i,
                                        "displayOrder"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return true;
                    })}

                    <div class="d-flex">
                      <button
                        type="button"
                        class="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium me-3"
                        onClick={handleEpisodeAddClick}
                      >
                        <iconify-icon
                          icon="material-symbols:add-box-outline"
                          class="me-2 fa-lg"
                        ></iconify-icon>
                        Add Video
                      </button>

                      {inputEpisodeList.length !== 1 ?
                      <button
                        type="button"
                        class="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium"
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          )
                            handleCurrentEpisodeRemoveClick();
                        }}
                      >
                        <iconify-icon
                          icon="mingcute:delete-2-line"
                          class="me-2 fa-lg"
                        ></iconify-icon>
                        Delete Current Video
                      </button>
                      :null}
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 d-flex">
                    <button
                      class="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                    >
                      Back
                    </button>
                    <button
                      class="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                    <ModalPopup page="episode"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EpisodeEdit;
