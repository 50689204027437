import { api, common, services } from "../../helpers";

const fetchRefreshToken = (onSuccess) => {
  const refreshToken = localStorage.getItem("refresh-token");

  let payload = {
    refreshToken: refreshToken,
  };

  let data = {
    url: "authRefreshToken",
    method: "POST",
    body: payload,
  };

  api
    .call(data, (res) => {
      if (res.status === 200) {
        const tokens = res.data?.data?.tokens;
        const accessToken = tokens.access?.token;
        const refreshToken = tokens.refresh?.token;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refresh-token", refreshToken);
        onSuccess();
      }
      else{
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((err) => {
      common.notify("E", err.response.data.message);
      localStorage.clear();
      window.location.href = "/";
    });
};

export default fetchRefreshToken;