import { useState } from "react";
import validator from "validator";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleFormSubmit = (fields) => {
    return validateForm(fields);
  };
  const handleEditFormSubmit = (fields) => { 
    return editvalidateForm(fields);
  };
  const handleEpisodeSubmit = (fields) => {
    return validateEpisodeData(fields);
  };

  const handleEditEpisodeSubmit = (fields) => {
    return validateEditEpisodeData(fields);
  };


  const validateEditEpisodeData = (data) => {
    let isFormValid = true;
    const formErrors = [{}];
   
    if (validator.isEmpty( data.videoId)) {
      formErrors.requiredFields = "Please fill all required values";
      isFormValid = false;
    }

    if (data.displayOrder === 0) {
      formErrors.requiredFields = "Please fill all required values";
      isFormValid = false;
    }

    setErrors(formErrors);
    let output = {
      isFormValid: isFormValid,
      formErrors: formErrors,
    };

    return output;
  };
  

  const validateEpisodeData = (data) => {
    let isFormValid = true;
    const formErrors = [{}];
   
    if (data.displayOrder === 0) {
      formErrors.requiredFields = "Please fill all required values";
      isFormValid = false;
    }

    if (data.videoId.length <= 0 ) {
      formErrors.requiredFields = "Please fill all required values";
      isFormValid = false;
  }

    setErrors(formErrors);
    let output = {
      isFormValid: isFormValid,
      formErrors: formErrors,
    };

    return output;
  };

  const editvalidateForm = (data)=> {
    let isFormValid = true;
    const formErrors = [{}];
    let inputEpisodeList = data;
    const videounique = new Set();
    const displayorderunique = new Set();


    inputEpisodeList.forEach(obj => {

      if (!obj.videoId) {
          formErrors.requiredFields = "Please fill all required 212";
          isFormValid = false;
      }

      if (!obj.displayOrder) {
        formErrors.requiredFields = "Please fill all required values";
        isFormValid = false;
    }

  });
    const DuplicateDisplayOrder = inputEpisodeList.some(
      (element) => displayorderunique.size === displayorderunique.add(element.displayOrder).size
    );
  
    if (DuplicateDisplayOrder) {
 
      formErrors.displayOrder =
        "Some Episode are having duplicate display order";
      isFormValid = false;
    }

    
    const DuplicateVideos = inputEpisodeList.some(
      (element) => videounique.size === videounique.add(element.videoId).size
    );

    if (DuplicateVideos) {
      formErrors.duplicateEpisodes = " Some Episode are having duplicate vedios";
      isFormValid = false;
    }

    setErrors(formErrors);
    let output = {
      isFormValid: isFormValid,
      formErrors: formErrors,
    };

    return output;
  }

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = [{}];
    let inputEpisodeList = data;
    const videounique = new Set();
    const displayorderunique = new Set();
    inputEpisodeList.forEach(obj => {
      if (obj.videoId.length <= 0) {
          formErrors.requiredFields = "Please fill all required 212";
          isFormValid = false;
      }
      if (!obj.displayOrder) {
        formErrors.requiredFields = "Please fill all required values";
        isFormValid = false;
    }
  });
    const DuplicateDisplayOrder = inputEpisodeList.some(
      (element) => displayorderunique.size === displayorderunique.add(element.displayOrder).size
    );
  
    if (DuplicateDisplayOrder) {
 
      formErrors.displayOrder =
        "Some Episode are having duplicate display order";
      isFormValid = false;
    }
    const DuplicateQuestion = inputEpisodeList.some(
      (element) => videounique.size === videounique.add(element.videoId.value).size
    );
    if (DuplicateQuestion) {
      formErrors.duplicateEpisode = "Some Episode are having duplicate videos";
      isFormValid = false;
    }

    setErrors(formErrors);
    let output = {
      isFormValid: isFormValid,
      formErrors: formErrors,
    };

    return output;
  };

  return {
    errors,
    handleEpisodeSubmit,
    handleFormSubmit,
    handleEditEpisodeSubmit,
    handleEditFormSubmit
  };
};
