import { useState } from "react";
import { common } from "./common";

export const FormValidation = (data) => {
  const [fields, setFields] = useState(() => common.initFields(data));
  const [errors, setErrors] = useState(() => common.initErrors(data));
  const [isField, setIsField] = useState("");

  // set
  const setValue = (name, val) => {
    setFields({ ...fields, [name]: val });
    setIsField(name);
  };

  const setMultiValue = (formFields) => {
    let result = { ...fields };
    for (const [key, item] of Object.entries(formFields)) {
      result[key] = item;
    }
    setFields(result);
  };

  const handleReset = (resetList = []) => {
    //setIsSubmit(false);
    setErrors({});
    if (resetList.includes("values")) {
      setFields(common.initFields(data));
    }
  };

  return {
    fields,
    errors,
    isField,
    setValue,
    setMultiValue,
    handleReset,
  };
};
