import { useNavigate } from "react-router-dom";
function ModalPopup(props){
  const navigate = useNavigate();

  return(
    <div className="modal fade" id="backAlert" tabindex="-1" aria-labelledby="backAlertLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content bg-transparent">
        <div className="modal-body rounded-1 p-4">
          <h5 className="fw-normal text-center mb-0">Warning: Navigating away from this page may result in the loss of unsaved data.</h5>
          <h5 className="fw-normal text-center mb-4"> Are you sure you want to leave this page?</h5>
          <div className="modal-footer d-flex justify-content-center flex-nowrap border-0 py-0 mx-4">
            <button type="button" className="btn btn-primary text-white py-2 w-25 m-0 me-3" data-bs-dismiss="modal" onClick={() => navigate(`/${props.page}`)}>Ok</button>
            <button type="button" className="btn btn-dark text-white py-2 w-25 m-0" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}


export default ModalPopup;