import React, { useEffect, useState, useRef, useCallback } from "react";
import PaginationElements from "../../elements/pagination";
import { api, common } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { AiOutlineSortDescending, AiOutlineSortAscending } from "react-icons/ai";
import Table from "../../elements/Table";

export default function VideoList(props) {
  //inti
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [itemSize, setItemSize] = useState(10);
  const [search, setSearch] = useState("");
  const [cancerType, setCancerType] = useState("");
  const [cancerList, setCancerList] = useState([]);
  const [ageGroupList, setAgeGroupList] = useState([]);
  const [ageGroup, setAgeGroup] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [raceType, setRaceType] = useState("");
  const [genderList, setGenderList] = useState([]);
  const [genderType, setGenderType] = useState([]);
  const [pagingCounter, setPagingCounter] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortList, setSortList] = useState("asc");
  const [enableCheckbox, setEnableCheckbox] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [blockID, setBlockID] = useState([]);

  useEffect(() => {
    setEnableCheckbox(true);
  }, []);

  let init = useRef(true);

  const handleFilterClick = (event) => {
    init.current = false;
    getVideoList();
  };

  const handleFilterClear = (event) => {
    init.current = true;
    setCancerType("");
    setGenderType("");
    setAgeGroup("");
    setRaceType("");
    setStatus("");
    setCurrentPage(1);
    getVideoList();
    setSortList("asc");
  };

  useEffect(() => {
    if (!localStorage.getItem('activePage')?.length) {
      getVideoList();
    }
    const interval = setInterval(() => {
      getVideoList(true, true);
    }, 10000);

    return () => clearInterval(interval);
  }, [currentPage, ageGroup, genderType, cancerType, raceType, status, sortList, search]);

  useEffect(() => {
    if (!localStorage.getItem('activePage')?.length) {
      getVideoList();
    }
  }, [search, sortList]);

  useEffect(() => {
    if (localStorage.getItem('activePage')
    ) {
      setCurrentPage(Number(localStorage.getItem('activePage')));
      if (Number(localStorage.getItem('activePage')) === 1) {
        getVideoList();
      }
      localStorage.removeItem('activePage');
    } else {
      setCurrentPage(1);
    }
  }, [])
  const tableHeader = [
    " ",
    "#",
    "Video Index",
    "Video Title",
    "Cancer Type",
    "Priority",
    "Age Group",
    "Gender",
    "Ethnicity",
    "Is Blocked",
    "Transcoding Status",

    " ",
  ];
  const tableColumn = [
    "videoIndex",
    "videoTitle",
    "cancerType.label",
    "priority",
    "ageGroup.label",
    "gender.label",
    "race.label",
    "isBlocked",
    "transcodingStatus",
  ];

  useEffect(() => {
    getCancerList();
    getGenderList();
    getAgegroupList();
    getRaceList();
  }, []);

  //handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectAll(false);
  };

  //API
  const getVideoList = (loader, interval) => {
    if (!loader) {
      setVideoList([])
      setLoading(true);
    }
    let urlAgeGroup = "",
      urlGender = "",
      urlCancerType = "",
      urlRaceType = "",
      urlStatusType = "";
    if (!init.current) {
      urlAgeGroup = Boolean(ageGroup.length) ? `&ageGroup=${ageGroup}` : "";
      urlGender = Boolean(genderType.length) ? `&gender=${genderType}` : "";

      urlCancerType = Boolean(cancerType.length)
        ? `&cancerType=${cancerType}`
        : "";
      urlRaceType = Boolean(raceType.length) ? `&race=${raceType}` : "";
      urlStatusType = Boolean(status.length) ? `&isBlocked=${status}` : "";
    }
    let data = {
      url: "videoList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=videoIndex&sortDir=${sortList}&search=${search}${urlAgeGroup}${urlGender}${urlCancerType}${urlRaceType}${urlStatusType}`,
    };
    api.call(data, (res) => {
      if (res.status === 200 && !interval) {
        setVideoList(res.data.data.results);
        setTotalRecord(res.data.data.totalResults);
        setItemSize(res.data.data.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        if (res?.data?.data?.results.length === 0) setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  const deleteItem = function (id) {
    //console.log("selete clicked", id);
    let data = {
      url: "videoDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        init.current = false;
        getVideoList();
      }
    });
  };

  //block and unblock video 
  const handleShowButtons = useCallback((anyCheckboxSelected, checkedIds) => {
    { anyCheckboxSelected === true ? setShowButton(true) : setShowButton(false) }
    setBlockID(checkedIds);
  }, []);

  const handleCheckboxChange = (event) => {
    setSelectAll(event.target.checked);
  };

  const blockVideos = () => {
    let data = {
      url: "videoBatchBlock",
      method: "POST",
      body: { videoIds: blockID ? blockID : [] },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        init.current = false
        setSelectAll(false);
        getVideoList();
      }
    });
  }

  const unblockVideos = () => {
    let data = {
      url: "videoBatchUnBlock",
      method: "POST",
      body: { videoIds: blockID ? blockID : [] },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        init.current = false;
        setSelectAll(false);
        getVideoList();
      }
    });
  }
  //API
  const getCancerList = () => {
    let data = {
      url: "cancerSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCancerList(res?.data?.data);
      }
    });
  };

  const getGenderList = () => {
    let data = {
      url: "genderSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setGenderList(res?.data?.data);
      }
    });
  };

  const getAgegroupList = () => {
    let data = {
      url: "ageGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setAgeGroupList(res?.data?.data);
      }
    });
  };

  const getRaceList = () => {
    let data = {
      url: "raceSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setRaceList(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-4">
          <div className="col-sm-8 d-flex justify-content-between">
            <div>
              <h5 className="fw-medium mb-0 align-self-center">Videos</h5>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex justify-content-end align-self-center">
              <div className="input-group input-group-sm w-775 me-3">
                <span
                  className="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i className="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
                onClick={() => navigate(`/videos-add`)}
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Video
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded-1">
              <div className="table-responsive">
                <div className="d-flex align-self-center p-3 text-nowrap">
                  {showButton ? (<div class="row d-flex align-items-center w-50">
                    <div class="col-2xl-1 col-md-3 col-6">
                      <div class="d-flex justify-content-start align-self-center px-3">
                        <div class="form-check d-flex align-items-center">
                          <input
                            className="form-check-input p-2 bg-muted-50 border border-white me-2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            checked={selectAll}
                            onChange={handleCheckboxChange}
                          />                                <label class="form-check-label xs-small" for="flexCheckDefault">
                            Select All
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-2xl-1 col-xl-3 col-lg-4 col-md-4 col-6">
                      <div class="d-flex justify-content-between align-items-center">
                        <button class="btn btn-muted py-0 px-2 fs-5  d-flex align-items-center" type="button" data-bs-toggle="modal" data-bs-target="#BlockAlert" >
                          <iconify-icon icon="bx:lock-alt" class="me-2" />
                          Block
                        </button>
                        <i class="fal fa-grip-lines-vertical px-2"></i>
                        <button class="btn btn-muted py-0 px-2 fs-5  d-flex align-items-center" type="button" data-bs-toggle="modal" data-bs-target="#unBlockAlert">
                          <iconify-icon icon="ion:lock-open-outline" class="me-2" />
                          Unblock
                        </button>
                      </div>
                    </div>
                  </div>) : (<>
                    <button className="btn p-0 d-flex flex-column align-self-center"
                      type="button" onClick={() => {
                        const newSortList = sortList === "asc" ? "desc" : "asc";
                        setSortList(newSortList);
                      }}>
                      <h5 className="xs-small m-0">Video Index {sortList === "asc" ? <AiOutlineSortDescending /> :
                        <AiOutlineSortAscending />}</h5>
                    </button>
                    <select
                      className="form-select form-select-sm w-auto bg-transparent text-white border-0 xs-small"
                      aria-label="Default select example"
                      name="cancerType"
                      value={cancerType || ""}
                      onChange={(e) => setCancerType(e.target.value)}
                    >
                      <option value="">Select Cancer type</option>
                      {cancerList.length > 0
                        ? cancerList.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                        : null}
                    </select>
                    <select
                      className="form-select form-select-sm w-auto bg-transparent text-white border-0 xs-small"
                      aria-label="Default select example"
                      defaultValue={""}
                      value={ageGroup || ""}
                      onChange={(e) => setAgeGroup(e.target.value)}
                    >
                      <option value="">Select Age Group</option>
                      {ageGroupList.length > 0
                        ? ageGroupList.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                        : null}
                    </select>
                    <select
                      className="form-select form-select-sm w-auto bg-transparent text-white border-0 xs-small"
                      aria-label="Default select example"
                      defaultValue={""}
                      value={genderType || ""}
                      onChange={(e) => setGenderType(e.target.value)}
                    >
                      <option value="">Select Gender type</option>
                      {genderList.length > 0
                        ? genderList.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                        : null}
                    </select>
                    <select
                      className="form-select form-select-sm w-auto bg-transparent text-white border-0 xs-small"
                      aria-label="Default select example"
                      value={raceType || ""}
                      defaultValue={""}
                      onChange={(e) => setRaceType(e.target.value)}
                      name="race"
                    >
                      <option value="">Select Ethnicity type</option>
                      {raceList.length > 0
                        ? raceList.map((item) => (
                          <option value={item.value}>{item.label}</option>
                        ))
                        : null}
                    </select>
                    <select
                      className="form-select form-select-sm w-auto bg-transparent text-white border-0 xs-small"
                      aria-label="Default select example"
                      defaultValue={""}
                      value={status || ""}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Is Blocked</option>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                    <div>
                      <button
                        className="btn btn-muted py-0 px-2 fs-5"
                        type="button"
                        onClick={handleFilterClick}
                      >
                        <i className="fal fa-filter me-1"></i>
                        Filter
                      </button>
                    </div>

                    <div className="ms-2">
                      <button
                        className="btn btn-muted py-0 px-2 fs-5 "
                        type="button"
                        onClick={handleFilterClear}
                      >
                        Clear
                      </button>
                    </div>

                  </>
                  )}
                </div>
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={videoList}
                  type={"videos"}
                  onDelete={deleteItem}
                  activePage={currentPage}
                  loading={loading}
                  enableCheckbox={enableCheckbox}
                  showButtons={handleShowButtons}
                  selectAll={selectAll}
                />
              </div>
              {loading ? <></> :
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    {totalRecord > 0 ?
                      <p className="mb-0 align-self-center xs-small">
                        Showing {pagingCounter} to{" "}
                        {pagingCounter > 0
                          ? pagingCounter + (videoList.length - 1)
                          : pagingCounter}{" "}
                        of {totalRecord} entries
                      </p> : null}
                    <nav aria-label="Page navigation example">
                      {totalRecord > itemSize && (
                        <PaginationElements
                          activePage={currentPage}
                          TotalRecord={totalRecord}
                          itemSize={itemSize}
                          handlePageChange={handlePageChange}
                        />
                      )}
                    </nav>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="BlockAlert" tabindex="-1" aria-labelledby="backAlertLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-transparent">
            <div className="modal-body rounded-1 p-4">
              <h5 className="fw-normal text-center mb-0">Warning: Once these videos are blocked, they will become inaccessible to users and the content will no longer be available on Mobile platform.</h5>
              <h5 className="fw-normal text-center mb-4"> Are you sure you wish to block these selected videos?</h5>
              <div className="modal-footer d-flex justify-content-center flex-nowrap border-0 py-0 mx-4">
                <button type="button" className="btn btn-primary text-white py-2 w-25 m-0 me-3" data-bs-dismiss="modal" onClick={blockVideos}>Block Videos</button>
                <button type="button" className="btn btn-dark text-white py-2 w-25 m-0" data-bs-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="unBlockAlert" tabindex="-1" aria-labelledby="backAlertLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-transparent">
            <div className="modal-body rounded-1 p-4">
              <h5 className="fw-normal text-center mb-0">Warning: Once these videos are unblocked, they will become accessible to users, and the content will be restored on Mobile platform.</h5>
              <h5 className="fw-normal text-center mb-4"> Are you sure you wish to unblock these selected videos?</h5>
              <div className="modal-footer d-flex justify-content-center flex-nowrap border-0 py-0 mx-4">
                <button type="button" className="btn btn-primary text-white py-2 w-25 m-0 me-3" data-bs-dismiss="modal" onClick={unblockVideos}>UnBlock Videos</button>
                <button type="button" className="btn btn-dark text-white py-2 w-25 m-0" data-bs-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
