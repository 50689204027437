import React, { useEffect, useState } from "react";
import PaginationElements from "../../elements/pagination";
import { api, common } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Table from "../../elements/Table";

export default function EpisodeList(props) {
  //inti
  const navigate = useNavigate();
  const [episodeList, setEpisodeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [itemSize, setItemSize] = useState(10);
  const [search, setSearch] = useState("");
  const [pagingCounter, setPagingCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEpisodeList();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getEpisodeList();
  }, [search]);

  const tableHeader = [
    "#",
    "Episode Name",
    "Episode Description",
    "Total videos",
    "Status",
    "Actions",
    " ",
  ];
  const tableColumn = [
    "episodeName",
    "episodeDescription",
    "totalVideos",
    "status",
  ];


  //handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //API
  const getEpisodeList = () => {
    setLoading(true);
 
    let data = {
      url: "episodeList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=&createdAt=&sortDir=asc&search=${search}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setEpisodeList(res?.data?.data?.results);
        setTotalRecord(res?.data?.data?.totalResults);
        setItemSize(res?.data?.data?.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        if (res?.data?.data?.results === 0) setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  //API
  const deleteItem = function (id) {
    let data = {
      url: "episodeDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getEpisodeList();
      }
    });
  };

  const onToggleClick = (item) => {
    console.log(item);
    if (item) {
      const id = item?._id;
      let data = {
        url: "episodeStatus",
        method: "POST",
        query: `/${id}`,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          console.log(res, "res...");
          getEpisodeList();
        }
      });
    }
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-4">
          <div className="col-sm-6 d-flex">
            <h5 className="fw-medium mb-0 align-self-center">
              Episodes & group
            </h5>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-end align-self-center">
              <div className="input-group input-group-sm w-50 me-3">
                <span
                  className="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i className="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <button
                type="button"
                class="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
                onClick={() => navigate(`/episode-add`)}
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Episodes & group
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded-1">
              <div className="d-flex justify-content-end align-self-center p-3"></div>
              <div class="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={episodeList}
                  type={"episode"}
                  onToggleClick={onToggleClick}
                  onDelete={deleteItem}
                  activePage={currentPage}
                  viewActive={false}
                  toggleActive={true}
                  toggleName="status"
                  loading={loading}
                />
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                {totalRecord>0?
                  <p className="mb-0 align-self-center xs-small">
                    Showing {pagingCounter} to{" "}
                    {pagingCounter > 0
                      ? pagingCounter + (episodeList.length - 1)
                      : pagingCounter}{" "}
                    of {totalRecord} entries
                  </p>:null}
                  <nav aria-label="Page navigation example">
                    {totalRecord > itemSize && (
                      <PaginationElements
                        activePage={currentPage}
                        TotalRecord={totalRecord}
                        itemSize={itemSize}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
