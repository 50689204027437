import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, FormValidation } from "../../helpers";
import { ValidateForm } from "./tagsValidation";
import ModalPopup from '../../elements/Modal';

const TagEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  // state
  const [isSubmit, setIsSubmit] = useState(false);
  const { fields, setMultiValue, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    console.log("calling==....>");
    getTagDetails();
  }, []);

  const getTagDetails = () => {
    console.log("calling==....>");

    let data = {
      url: "tagsGet",
      method: "GET",
      query: `${id}`,
    };

    api.call(data, (res) => {
      console.log("res==>>", res);

      if (res.status === 200) {
        console.log("res==>>", res);
        setMultiValue({
          tagName: res?.data?.data?.tagName,
          description: res?.data?.data?.description,
        });
      }
    });
  };

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      tagUpdate();
    }
  };

  const tagUpdate = async () => {
    let payload = {
      tagName: fields.tagName,
      description: fields.description,
    };
    let data = {
      url: "tagsUpdate",
      method: "POST",
      query: `${id}`,
      body: payload,
    };
    api
      .call(data, async (res) => {
        debugger;
        if (res.data.status === "success") {
          navigate("/tags");
          common.notify("S", res?.data?.msg);
        } else {
          common.notify("E", res?.data?.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Tag </h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tag Name <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Tag name"
                            name="tagName"
                            value={fields.tagName}
                            onChange={(e) => {
                              setValue("tagName", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-field">{errors.tagName}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label for="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>
                          <textarea
                            className="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Description"
                            name="description"
                            value={fields.description}
                            style={{ whiteSpace: "pre-wrap" }}
                            onChange={(e) => {
                              setValue("description", e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <p className="error-field">{errors.description}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      {" "}
                      Update
                    </button>
                    <ModalPopup page="tags"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagEdit;
