import React from "react";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';

export default function MyChart({ graphData, color, label }) {
  const data = {
    labels: graphData.map(dataPoint => dataPoint.month),
    datasets: [
      {
        label: label,
        data: graphData.map(dataPoint => dataPoint.count),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: color,
        borderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: "rgba(75,192,192,1)"
      },
    ]
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.parsed.y}`,
        }
      }
    }
  };

  return (
    <div className="graph">
      <Line data={data} options={options} />
    </div>
  );
}
