import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, FormValidation, config } from "../../helpers";
import AWS from "../../helpers/aws";
import { ValidateForm } from "./videoValidation";
import Select from "react-select";
import _ from "lodash";
import ModalPopup from '../../elements/Modal';

const VideoEdit = () => {
  // init
  const navigate = useNavigate();
  let { id } = useParams();
  let isImgUpload = "true",
    isVideoUpload = "true";
  // state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cancerTypeList, setCancerTypeList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagGroupList, setTagGroupList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [ageGroupList, setAgegroupList] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [newVideoSource, setNewVideoSource] = useState([]);
  const [newImageSource, setNewImageSource] = useState([]);
  const [dragImageActive, setDragImageActive] = useState([]);
  const [videoTokenDetails, setVideoTokenDetails] = useState([]);
  const [imageTokenDetails, setImageTokenDetails] = useState([]);
  const [newDuration, setNewDuration] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const { fields, setMultiValue, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    setValue({ displayOrder: 0 });
    getCancerList();
    getGenderList();
    getAgegroupList();
    getTagGroupList();
    getRaceList();
    getCountryList();
    getVideoDetails();
    getTagList();
  }, []);

  const removeLabel = (listExtract) => {
    return listExtract.map((item) => item.value);
  };

  function handleTagGroupSelect(data) {
    setValue("tagGroup", data);
    let result = data.map(function getItem(item) {
      return [item.value].join(" ");
    });
    if (result) getTagList(result);
    else setValue("tag", []);
  }

  const getVideoDetails = () => {
    let data = {
      url: "videoGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res?.data?.data.tagGroup.map(function getItem(item) {
          return [item.value].join(" ");
        });
        // console.log("jfjdjfk-->", result);
        if (result) getTagList(result);
        else setValue("tag", []);
        setMultiValue({
          cancerType: res?.data?.data?.cancerType,
          country: res?.data?.data?.country,
          videoTitle: res?.data?.data?.videoTitle,
          videoDescription: res?.data?.data?.videoDescription,
          videoType: res?.data?.data?.videoType,
          duration: res?.data?.data?.duration,
          resolution: res?.data?.data?.resolution,
          isEpisode: res?.data?.data?.isEpisode.toString(),
          race: res?.data?.data?.race,
          ageGroup: res?.data?.data?.ageGroup,
          gender: res?.data?.data?.gender,
          tags: res?.data?.data?.otherTags,
          tagGroup: res?.data?.data?.tagGroup,
          priority: res?.data?.data?.priority,
          videoIndex: res?.data?.data?.videoIndex,
          videoSource: res?.data?.data?.videoSource,
          videoThumbnail: res?.data?.data?.videoThumbnail,
          videoSourceName: res?.data?.data?.videoSource,
          imageSourceName: res?.data?.data?.videoThumbnail,
        });
      }
    });
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  // handle Image drag events
  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let files = [];
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      files = e.dataTransfer.files[0];
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setNewDuration({
          duration: video.duration,
          height: video.videoHeight,
        });
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    }
  };

  const handleImageDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFileValidation(e.dataTransfer.files[0], "image");
    }
  };

  const handleVideoFileChange = function (e) {
    e.preventDefault();
    var files = [];
    if (e.target.files && e.target.files[0]) {
      files = e.target.files[0];
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        files = e.dataTransfer.files[0];
      }
    }
    var fileExt = files.name.split(".").pop();
    var notSupportedFileFormats = config.not_supported_video_formats;
    let filetype = common.getFileType(files);
    if (filetype === "video" && !notSupportedFileFormats.includes(fileExt)) {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setNewDuration({
          duration: video.duration,
          height: video.videoHeight,
        });
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    } else {
      window.scrollTo(0, 0);
      common.notify("E", "Invalid file format");
    }
  };

  const handleImageFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      checkFileValidation(e.target.files[0], "image", "");
    }
  };

  //Normal Creat

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);

      videoGalleryCreate();
    }
  };

  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    if (type === "image") {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "image",
        duration: "",
      };
    } else {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "video",
        duration: String(videoDuration),
      };
    }
    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        if (type === "image") {
          setImageTokenDetails({
            credentials: res?.data?.data?.token?.Credentials,
            fileName: res?.data?.data?.fileName,
          });
          setNewImageSource(file);
          setValue("imageSourceName", file.name);
          isImgUpload = "false";
        } else {
          setVideoTokenDetails({
            credentials: res?.data?.data?.token?.Credentials,
            fileName: res?.data?.data?.fileName,
          });
          setNewVideoSource(file);
          setValue("videoSourceName", file.name);
          isVideoUpload = "false";
        }
      } else {
        common.notify("E", type + " " + res.data.message);
      }
    });
  };

  const videoGalleryCreate = async () => {
    let body = {
      videoTitle: fields.videoTitle,
      videoDescription: fields.videoDescription,
      videoType: fields.videoType,
      duration: !_.isEmpty(newDuration)
        ? String(newDuration.duration)
        : fields.duration,
      resolution: !_.isEmpty(newDuration)
        ? String(newDuration.height)
        : fields.resolution,
      isEpisode: fields.isEpisode,
      cancerType: removeLabel(fields?.cancerType),
      country: removeLabel(fields?.country),
      race: removeLabel(fields?.race),
      ageGroup: removeLabel(fields?.ageGroup),
      gender: removeLabel(fields?.gender),
      otherTags: fields.tags?removeLabel(fields?.tags):[],
      tagGroup:fields.tagGroup? removeLabel(fields?.tagGroup):[],
      priority: fields.priority,
      videoIndex: fields.videoIndex,
    };

    setLoading(true);
    let data = {
      url: "videoUpdate",
      method: "POST",
      query: `${id}`,
      body: body,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        if (!_.isEmpty(imageTokenDetails) || !_.isEmpty(videoTokenDetails)) {
          if (!_.isEmpty(imageTokenDetails) && !_.isEmpty(videoTokenDetails)) {
            uploadVideoFiles(newVideoSource, "video", id);
            uploadVideoFiles(newImageSource, "image", id);
          } else {
            if (!_.isEmpty(imageTokenDetails)) {
              uploadVideoFiles(newImageSource, "image", id);
            }

            if (!_.isEmpty(videoTokenDetails)) {
              uploadVideoFiles(newVideoSource, "video", id);
            }
          }
        } else {
          common.notify("S", res.data.msg);
          setIsSubmit(false);
          setLoading(false);
          setIsSubmit(false);

          navigate("/videos");
        }
      } else {
        common.notify("E", res.data.message);
        setLoading(false);
        setIsSubmit(false);
      }
    });
  };

  const uploadVideoFiles = async (file, type, videoId) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "image") {
      fileName = imageTokenDetails.fileName;
      credentials = imageTokenDetails.credentials;
    } else {
      fileName = videoTokenDetails.fileName;
      credentials = videoTokenDetails.credentials;
    }

    const params = {
      Bucket: type === "image" ? config.s3_image_path : config.s3_video_path,
      Key:
        type === "image"
          ? config.s3_video_thumpnail_folder + fileName
          : fileName,
      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };
    const BUCKET = AWS.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );
    BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        if (progressPercentage < 100) {
          setProgress(progressPercentage);
          setValue("progressPercentage", progressPercentage);
        } else if (progressPercentage === 100) {
          setProgress(0);
        }
      })
      .send(function (err, data) {
        if (err) {
          setLoading(false);
          common.notify("E", err);
          setIsSubmit(false);
        } else {
          if (type === "image") isImgUpload = "true";
          else isVideoUpload = "true";

          if (isImgUpload === "true" && isVideoUpload === "true") {
            updateVideoSource(videoId);
          }
        }
      });
  };

  const updateInputsessionCost = (evt) => {
    const value = evt.target.value;

    if (value === '' || (/^\d+(\.\d{0,2})?$/.test(value) && parseFloat(value) >= 1 && parseFloat(value) <= 1000)) {
      setValue('priority',value);
    }
    if (value === '' || parseFloat(value) > 1000) {
      return;
    }
  };
  
  const updateVideoSource = (updateId) => {
    let videoSrc = "",
      imageSrc = "";
    if (!_.isEmpty(videoTokenDetails) && isVideoUpload === "true") {
      videoSrc = videoTokenDetails.fileName;
    } else {
      videoSrc = fields.videoSourceName;
    }

    if (!_.isEmpty(imageTokenDetails) && isImgUpload === "true") {
      imageSrc = imageTokenDetails.fileName;
    } else {
      imageSrc = fields.imageSourceName;
    }
    let videoSourcePayload = {
      videoSource: videoSrc,
      videoThumbnail: imageSrc,
    };
    let data = {
      url: "videoSource",
      method: "POST",
      query: `/${updateId}`,
      body: videoSourcePayload,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        setLoading(false);
        navigate("/videos");
        setIsSubmit(false);
        common.notify("S", "Added successfully");
      } else {
        common.notify("E", res.data.message);
        setLoading(false);
      }
    });
  };
  const getCancerList = () => {
    let data = {
      url: "cancerSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let cancerType = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...cancerType];

        setCancerTypeList(options);
      }
    });
  };

  const getGenderList = () => {
    let data = {
      url: "genderSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let genderType = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...genderType];

        setGenderList(options);
      }
    });
  };

  const getAgegroupList = () => {
    let data = {
      url: "ageGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let ageGroup = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...ageGroup];
        setAgegroupList(options);
      }
    });
  };

  const getRaceList = () => {
    let data = {
      url: "raceSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let race = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...race];
        setRaceList(options);
      }
    });
  };

  const getTagGroupList = () => {
    let data = {
      url: "tagGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tagGroup = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...tagGroup];
        setTagGroupList(options);
      }
    });
  };

  const getTagList = (tagGroup) => {
    setTagsList([]);
    let data = {
      url: "tagList",
      method: "POST",
      body: { tagGroup: tagGroup },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tags = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...tags];
        setTagsList(options);
      }
    });
  };

  const getCountryList = () => {
    let data = {
      url: "countrySelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCountryList(res.data.data);
      }
    });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Video</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              {/* <div  className="p-3 border-bottom">
                                <h6 className="text-white mb-0 fw-medium fs-6">Edit Video</h6>
                            </div> */}
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Title <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video title"
                            value={fields.videoTitle}
                            onChange={(e) =>
                              setValue("videoTitle", e.target.value)
                            }
                          />
                        </div>
                        <p className="error-field">{errors.videoTitle}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tag Group <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={tagGroupList}
                            value={fields.tagGroup}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find(
                                (tagGroup) => tagGroup.value === "all"
                              )
                                ? handleTagGroupSelect(
                                    tagGroupList.slice(1),
                                    "tagGroup"
                                  )
                                : handleTagGroupSelect(selected, "tagGroup")
                            }
                            isSearchable={true}
                            isMulti
                          />
                          <p className="error-field">{errors.tagGroup}</p>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tag <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={tagsList}
                            value={fields.tags}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((tags) => tags.value === "all")
                                ? setValue("tags", tagsList.slice(1))
                                : setValue("tags", selected)
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.tags}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Ethnicity <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={raceList}
                            value={fields.race}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((race) => race.value === "all")
                                ? setValue("race", raceList.slice(1))
                                : setValue("race", selected)
                            }
                            //onChange={(event) => handleSelect(event, "race")}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.race}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Episode Video <i className="star">*</i>
                          </label>
                          <select
                            class="form-select form-select-lg"
                            value={fields.isEpisode}
                            aria-label="Default select example"
                            onChange={(e) =>
                              setValue("isEpisode", e.target.value)
                            }
                          >
                            <option value="">Select Episode Type</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <p className="error-field">{errors.isEpisode}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Priority <i className="star">*</i>
                          </label>
                          <input
                          type="text"
                          inputMode="numeric"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video priority"
                            value = {fields.priority}
                            onChange={updateInputsessionCost}
                          />
                        </div>
                        <p className="error-field">{errors.priority}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Video Index <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video index"
                            value={fields.videoIndex}
                            maxLength={20}
                            onChange={(e) =>
                              setValue("videoIndex", e.target.value)
                            }
                          />
                        </div>
                        <p className="error-field">{errors.videoIndex}</p>
                      </div>
                      <div className="col-sm-12" disabled={isSubmit}>
                        <div
                          className="mb-3"
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        >
                          <label htmlFor="input1" className="form-label">
                            Upload Video <i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area text-center">
                              <div className="text-secondary xs-small">
                                ( Supported video format - *.mp4 )
                              </div>
                              <div className="my-2">
                                <button
                                  tupe="button"
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button"
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    class="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Video
                                </button>
                                <span
                                  className={
                                    fields.videoSourceName
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"
                                  }
                                >
                                  {fields?.videoSourceName
                                    ? fields.videoSourceName
                                    : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input"
                                  type="file"
                                  onChange={handleVideoFileChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="error-field">{errors.videoSourceName}</p>
                        {progress > 0 ? (
                          <ProgressBar className="mt-2">
                            <ProgressBar
                              striped
                              animated
                              variant="success"
                              now={progress}
                              label={`${progress}%`}
                              key={3}
                            />
                          </ProgressBar>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Cancer Type <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={cancerTypeList}
                            // onChange={(e) => setValue("cancerType", e)}
                            value={fields.cancerType}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((cancer) => cancer.value === "all")
                                ? setValue(
                                    "cancerType",
                                    cancerTypeList.slice(1)
                                  )
                                : setValue("cancerType", selected)
                            }
                            //onChange={(event) => handleSelect(event, "cancerType")}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.cancerType}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3" disabled={true}>
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Country <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={countryList}
                            // onChange={(event) => handleSelect(event, "country")}
                            onChange={(e) => setValue("country", e)}
                            isSearchable={true}
                            value={fields.country}
                            defaultValue={countryList[0]}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.country}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Gender <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={genderList}
                            value={fields.gender}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((gender) => gender.value === "all")
                                ? setValue("gender", genderList.slice(1))
                                : setValue("gender", selected)
                            }
                            //onChange={(event) => handleSelect(event, "gender")}
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.gender}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Age Group <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={ageGroupList}
                            value={fields.ageGroup}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find(
                                (ageGroup) => ageGroup.value === "all"
                              )
                                ? setValue("ageGroup", ageGroupList.slice(1))
                                : setValue("ageGroup", selected)
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.ageGroup}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label htmlFor="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>
                          <textarea
                            className="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            style={{ whiteSpace: "pre-wrap" }}
                            rows="5"
                            placeholder="Video description"
                            onChange={(e) =>
                              setValue("videoDescription", e.target.value)
                            }
                            value={fields.videoDescription}
                          ></textarea>
                          <p className="error-field">
                            {errors.videoDescription}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12" disabled={isSubmit}>
                        <div
                          className="mb-3"
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                          onDrop={handleImageDrop}
                        >
                          <label htmlFor="input1" className="form-label">
                            Upload Thumbnail <i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area text-center ">
                              <div className="text-secondary xs-small">
                                ( Supported image formats - *.jpg, *.jpeg, *.png
                                )
                              </div>
                              <div className="my-2">
                                <button
                                  tupe="button"
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button "
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    class="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    fields.imageSourceName
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"
                                  }
                                >
                                  {fields?.imageSourceName
                                    ? fields.imageSourceName
                                    : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input"
                                  type="file"
                                  onChange={(e) => handleImageFileChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="error-field">{errors.imageSourceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onclick="window.location.href='videos-list.html'"
                      onClick={onSubmit}
                      disabled={isSubmit}
                    >
                      {isSubmit ? "PROCESSING..." : "UPDATE"}
                    </button>
                    <ModalPopup page="videos"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoEdit;
