import React, { useEffect, useState, useRef } from "react";
import { api, common } from "../../helpers";
import { ThreeDots } from "react-loader-spinner";
import { FiEdit, FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import PaginationElements from "../../elements/pagination";

export default function MobileDashboardList(props) {
  //inti
  const [glossaryList, setGlossaryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemSize, setItemSize] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pagingCounter, setPagingCounter] = useState(0);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const tableHeader = [
    "#",
    "Description",
    "Redirect To",
    "Status",
    "Image",

    " ",
  ];
  const init = useRef(true);
  useEffect(() => {
    getGlossaryList();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('activePage')?.length) {
      getGlossaryList();
    }
  }, [search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getGlossaryList = () => {
    setLoading(true);

    let data = {
      url: "mobileDashboardList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=createdAt&sortDir=desc&search=${search}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setGlossaryList(res.data.data.results);
        setTotalRecord(res?.data?.data?.totalResults);
        setItemSize(res?.data?.data?.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        if (res?.data?.data?.results.length === 0)
          setCurrentPage(1);
          setLoading(false);
      }
    });
  };

  const deleteParagraph = (id) => {

    let data = {
      url: "mobileDashboardDelete",
      method: "DELETE",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.data.status === "success") {
        getGlossaryList();
        common.notify("S", res?.data?.msg || res?.data?.message);
      } else {
        common.notify("E", res?.data?.message || res?.data?.msg);
      }
    });
  };

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-4">
          <div class="col-sm-6 d-flex">
            <h5 class="fw-medium mb-0 align-self-center">Mobile Dashboard - Portal</h5>
          </div>
          <div class="col-sm-6">
            <div class="d-flex justify-content-end align-self-center">
              <div class="input-group input-group-sm w-50 me-3">
                <span
                  class="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i class="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  class="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Link
                to="/mobile-dashboard/add"
                class="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Mobile Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row">
          <div class="col-12">
            <div class="bg-muted-50 rounded-1 h-100">
              <div class="table-responsive">
                <>
                  <table className="table mb-0">
                    <thead className="bg-muted-50">
                      <tr className="text-nowrap">
                        {tableHeader.map((item, index) =>
                          index === 0 ? (
                            <th style={{ width: "40px" }}>{item}</th>
                          ) : index === tableHeader.length - 1 ? (
                            <th scope="col"></th>
                          ) : (
                            <th scope="col">{item}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {glossaryList?.length ? (
                          glossaryList?.map((item, index) => (
                            <tr className="align-middle" key={index}>
                              <th className="xs-small">
                                {(index + 1)}
                              </th>
                              <td className="xs-small">
                                <p
                                  className="xs-small mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description.replace(/<[^>]+>/g, ''),
                                  }}
                                ></p>
                              </td>
                              <td>
                                <p
                                  className="xs-small mb-0"
                                >{item.redirectTo ? item.redirectTo : "-"}</p>
                              </td>
                              <td>
                                <p
                                  className="xs-small mb-0 textnowrap"
                                >{item.status ? "Active" : "In Active"}</p>
                              </td>
                              <td>
                                {item.imageUrlLink ?
                                  <img src={item.imageUrlLink} alt=""
                                    height="30px"
                                    width="30px" /> : "-"}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn btn-link p-0 me-2 d-flex"
                                    onClick={() => {
                                      console.log(item)
                                      navigate(`/mobile-dashboard/edit/${item?._id}`)
                                    }}
                                  >
                                    <FiEdit />
                                  </button>
                                  <button
                                    className="btn btn-link p-0 me-2 d-flex"
                                    onClick={() =>
                                      navigate(`/mobile-dashboard/view/${item?._id}`)
                                    }
                                  >
                                    <FiEye />
                                  </button>
                                  <button
                                    className="btn btn-link text-danger p-0 me-2 d-flex"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#DeleteAlertModal"
                                    onClick={(e) => {
                                      deleteParagraph(item?._id);
                                    }}
                                  >
                                    <iconify-icon
                                      icon="fluent:delete-24-filled"
                                      className="text-danger align-self-center"
                                    ></iconify-icon>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                          {loading ? (
                            <td className="text-center" colSpan={tableHeader?.length}>
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#83BB41"
                                ariaLabel="Loading Copmanies list"
                                wrapperStyle={{ position: "fixed", left: "50%" }}
                                wrapperClassName=""
                                visible={true}
                              />
                            </td>
                          ) : (
                            <td className="text-center" colSpan={tableHeader?.length}>
                              No Data Found!
                            </td>
                          )}
                        </tr>
                        )}
                      </>
                    </tbody>
                  </table>

                </>
              </div>
              <hr />
              <div class="d-flex justify-content-between mt-3 mb-5 mx-3">
                {totalRecord > 0 ?
                  <p class="mb-0 align-self-center xs-small">
                    Showing {pagingCounter} to{" "}
                    {pagingCounter > 0
                      ? pagingCounter + (glossaryList.length - 1)
                      : pagingCounter}{" "}
                    of {totalRecord} entries
                  </p> : null}
                <div className="d-flex flex-row-reverse">
                  {totalRecord > itemSize && (
                    <PaginationElements
                      activePage={currentPage}
                      TotalRecord={totalRecord}
                      itemSize={itemSize}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
