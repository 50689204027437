import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import { Link } from "react-router-dom";

const MobileDashboardShow = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [videoDetails, setVideoDetails] = useState([]);
  console.log(videoDetails, "videoDetails");
  // init

  useEffect(() => {
    getVideoDetails();
  }, []);

  //API
  const getVideoDetails = () => {
    let data = {
      url: "mobileDashboard",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoDetails(res?.data?.data);
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/mobile-dashboard/edit/${id}`);
  };

  const toggleStatus = function (id) {
    let data = {
      url: "mobileDashboardStatus",
      method: "PUT",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getVideoDetails();
      }
    });
  };
  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-3">
          <div className="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">Mobile Dashboard - Portal</li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3 align-items-center">
            <Link
              to="/mobile-dashboard-list"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>

          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 p-4 rounded-1 h-100">
              <div className="d-flex justify-content-end mb-3">
                Is Active
                <div class="form-check form-switch d-flex align-items-center ms-2">
                  <input
                    class="form-check-input py-2"
                    type="checkbox"
                    role="switch"
                    name="status"
                    id="flexSwitchCheckChecked"
                    checked={videoDetails.length > 0 && videoDetails[0].status}
                    onChange={() => {
                      const updatedVideoDetails = videoDetails.map((item) => {
                        if (item._id === id) {
                          return {
                            ...item,
                            status: !item.status,
                          };
                        }
                        return item;
                      });

                      setVideoDetails(updatedVideoDetails);

                      const message = videoDetails.length > 0 && videoDetails[0].status
                        ? "Are you sure you wish to deactivate this paragraph?"
                        : "Are you sure you wish to activate this paragraph?";

                      if (window.confirm(message)) toggleStatus(id, !videoDetails[0].status);
                    }}
                  />
                </div>
              </div>
              <div class="container-fluid px-4 mb-4 overflow-auto flex-fill mt-3">
                <div class="row h-100">
                  <div class="col-12 p-0">

                    <div class="p-3">
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="d-flex flex-column">
                            <p class="text-white">Image</p>
                            <div>
                              {videoDetails.length
                                ? videoDetails.map((list) => (
                                  <>
                                    {list.imageUrlLink ?
                                      <img key={list._id} src={list.imageUrlLink} alt="image" /> : "-"}
                                  </>
                                ))
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-5 col-6 mt-5">
                          <div class="d-flex flex-column">
                            <h5 class="fw-normal">Description</h5>
                            <small dangerouslySetInnerHTML={{
                              __html: videoDetails[0]?.description,
                            }} />
                          </div>
                        </div>
                        <div class="col-lg-7 col-6 mt-5">
                          <div class="d-flex flex-column">
                            <h5 class="fw-normal">Redirect To</h5>
                            <small>{videoDetails.length > 0
                              ? videoDetails.map((list) => (
                                <>
                                  {list.redirectTo ?
                                    <li key={list._id}>{list.redirectTo}</li> : "-"}</>
                              ))
                              : "-"}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDashboardShow;
