import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const VideoDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [videoDetails, setVideoDetails] = useState([]);
  // init

  useEffect(() => {
    getVideoDetails();
  }, []);

  //API
  const getVideoDetails = () => {
    let data = {
      url: "videoGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoDetails(res?.data?.data);
      }
    });
  };

  const notifyUser = async () => {
    let data = {
      url: "notifyUser",
      method: "POST",
      query: `${id}`,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          common.notify("S", res?.data?.msg || res?.data?.message);
        } else {
          common.notify("E", res?.data?.message || res?.data?.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const editDetails = async (e) => {
    navigate(`/videos/edit/${id}`);
  };

  const statusChange = function (id) {
    let data = {
      url: "videoUnblock",
      method: "PUT",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getVideoDetails();
      }
    });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-3">
          <div className="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">Video Details</li>
                <li className="breadcrumb-item active" aria-current="page">
                  {videoDetails.videoTitle}
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3 align-items-center">
            <Link
              to="/videos"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>
            {videoDetails?.isBlocked === false &&
              videoDetails?.transcodingStatus === "COMPLETE" && (
                <button
                  className="btn btn-muted btn-lg text-white fw-semibold text-nowrap ms-3"
                  onClick={() => {
                    let message =
                      "Are you sure want to send push notification to users?";
                    if (window.confirm(message)) notifyUser();
                  }}
                >
                  Notify User
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 p-4 rounded-1 h-100">
              <div className="d-flex justify-content-end mb-3">
                Is Blocked
                <div class="form-check form-switch d-flex align-items-center ms-2">
                  <input
                    class="form-check-input py-2"
                    type="checkbox"
                    role="switch"
                    name="status"
                    id="flexSwitchCheckChecked"
                    checked={videoDetails.isBlocked}
                    onChange={(e) => {
                      let message = videoDetails.isBlocked
                        ? "Are you sure you wish to unblock this video?"
                        : "Are you sure you wish to block this video?";
                      if (window.confirm(message)) statusChange(id);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4 mb-3">
                  <div style={{ height: "150px", minHeight: "170px" }}>
                    <ReactPlayer
                      url={videoDetails.videoLink}
                      light={videoDetails?.thumbnailLink}
                      playing
                      controls={true}
                      className="react-player"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                {/* <div  className="col-lg-4">
                                    <div  className="video-img">
                                         <ReactPlayer url={videoDetails.videoLink}
                                         className="video-play d-flex justify-content-center align-items-center w-100 "
                                            light={videoDetails?.thumbnailLink}
                                            playing 
                                            controls={true}/> 
                                    </div>
                                </div> */}
                <div className="col-lg-8 mb-3">
                  <h5 className="fw-medium">Description</h5>
                  <div
                    className="fs-6 mb-0 fw-normal description-scroll"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {videoDetails.videoDescription}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Cancer Type</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.cancerType
                      ? videoDetails.cancerType.map((list) => (
                          <li>{list.label}</li>
                        ))
                      : null}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Priority</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails?.priority}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Tag Group</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.tagGroup
                      ? videoDetails.tagGroup.map((list) => (
                          <li>{list.label}</li>
                        ))
                      : null}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Tag</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.otherTags
                      ? videoDetails.otherTags.map((list) => (
                          <li>{list.label}</li>
                        ))
                      : null}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Country</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.country
                      ? videoDetails.country.map((list) => (
                          <li>{list.label}</li>
                        ))
                      : null}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Ethnicity</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.race
                      ? videoDetails.race.map((list) => <li>{list.label}</li>)
                      : null}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Gender</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.gender
                      ? videoDetails.gender.map((list) => <li>{list.label}</li>)
                      : null}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Age Group</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails.ageGroup
                      ? videoDetails.ageGroup.map((list) => (
                          <li>{list.label}</li>
                        ))
                      : null}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Video Status</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails?.videoStatus}
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Episode Video</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails?.isEpisode === true ? "True" : "False"}
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                  <h5 className="fw-medium">Video Index</h5>
                  <p className="fs-6 mb-0 fw-normal">
                    {videoDetails?.videoIndex}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoDetails;
