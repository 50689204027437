import { FiEdit, FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const TableElement = ({
  tableHeader,
  tableData,
  type,
  tableColumn,
  onDelete,
  activePage,
  loading,
  toggleColumn,
  toggleActive,
  onToggleClick,
  toggleName,
  deleteActive = true,
  editActive = true,
  viewActive = true,
  enableCheckbox,
  showButtons,
  selectAll,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState("");
  const [showButton, setShowButton] = useState(Array(tableData.length).fill(false)); // Initialize showButton as an array of false values

  const arrayColumn = (column) => {
    const regex = /[.]/g;
    const found = column.match(regex);
    return Boolean(found);
  };

  const splitArray = (column, fieldName) => {
    return column[fieldName.split(".")[0]]
      ?.map((item) => item[fieldName.split(".")[1]])
      .toString();
  };

  const deleteitem = () => {
    onDelete(itemToDelete);
  };

  useEffect(() => {
    const checkedIds = tableData
      .filter((item, index) => showButton[index])
      .map((item) => item._id);

    const anyCheckboxSelected = checkedIds.length > 0;
    if (enableCheckbox === true) {
      showButtons(anyCheckboxSelected, checkedIds);
    }
  }, [showButton, tableData, showButtons]);


  const handleCheckboxChange = (index) => {
    const updatedShowButton = [...showButton];
    updatedShowButton[index] = !updatedShowButton[index];
    setShowButton(updatedShowButton);

    const checkedIds = tableData
      .filter((item, idx) => updatedShowButton[idx])
      .map((item) => item._id);

    const anyCheckboxSelected = checkedIds.length > 0;

    showButtons(anyCheckboxSelected, checkedIds);
  };

  useEffect(() => {
    const updatedShowButton = Array(tableData.length).fill(selectAll);
    setShowButton(updatedShowButton);
  }, [selectAll, tableData]);

  return (
    <>
      <table className="table mb-0">
        <thead className="bg-muted-50">
          <tr className="text-nowrap">
            {tableHeader.map((item, index) =>
              index === 0 ? (
                <th style={{ width: "40px" }}>{item}</th>
              ) : index === tableHeader.length - 1 ? (
                <th scope="col"></th>
              ) : (
                <th scope="col">{item}</th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {
            <>
              {" "}
              {tableData?.length ? (
                tableData.map((item, index) => (
                  <tr className="align-middle">
                    {enableCheckbox && (<th scope="row">
                      <input
                        className="form-check-input text-white p-2 bg-muted-50 border border-white"
                        type="checkbox"
                        value=""
                        id={`flexCheckDefault${index}`}
                        checked={showButton[index] || false} // Check the showButton state for the specific checkbox
                        onChange={() => handleCheckboxChange(index)} // Handle checkbox change
                      />
                    </th>)}

                    <th className="xs-small">
                      {(activePage - 1) * 10 + (index + 1)}
                    </th>
                    {tableColumn.length &&
                      tableColumn.map((columns) => (
                        <td className="xs-small">
                          {(() => {
                            if (item[columns] === false && type === "videos") {
                              return <p className="mb-0">No</p>;
                            } else if (
                              item[columns] === false &&
                              type !== "videos"
                            ) {
                              return <p className="mb-0">Inactive</p>;
                            } else if (
                              item[columns] === true &&
                              type === "videos"
                            ) {
                              return <p className="mb-0">Yes</p>;
                            } else if (
                              item[columns] === true &&
                              type !== "videos"
                            ) {
                              return <p className="mb-0">Active</p>;
                            } else if (arrayColumn(columns)) {
                              return (
                                <p className="xs-small mb-0">
                                  {splitArray(item, columns)}
                                </p>
                              );
                            } else if (
                              item[columns] &&
                              typeof item[columns] === "string" &&
                              item[columns].includes("http") &&
                              type !== "advertisements"
                            ) {
                              return (
                                <img
                                  src={item[columns]}
                                  alt=""
                                  height="30px"
                                  width="30px"
                                />
                              );
                            } else if (
                              item[columns] &&
                              typeof item[columns] === "string" &&
                              item[columns].includes("http") &&
                              type === "advertisements"
                            ) {
                              return <img src={item[columns]} alt="" />;
                            } else if (
                              item[columns] &&
                              typeof item[columns] === "string" &&
                              item[columns].includes("NOT_INITIATED")
                            ) {
                              return (
                                <p className="not_initiated mb-0">
                                  NOT_INITIATED
                                </p>
                              );
                            } else if (
                              item[columns] &&
                              typeof item[columns] === "string" &&
                              item[columns].includes("INPROGRESS")
                            ) {
                              return (
                                <p className="not_initiated mb-0">INPROGRESS</p>
                              );
                            } else if (
                              item[columns] &&
                              typeof item[columns] === "string" &&
                              item[columns].includes("COMPLETE")
                            ) {
                              return (
                                <p className="video_complete mb-0">COMPLETE</p>
                              );
                            } else if (!item[columns]) {
                              return <p className="mb-0">-</p>;
                            }
                            else {
                              return (
                                <p className="xs-small mb-0">{item[columns]}</p>
                              );
                            }
                          })()}
                        </td>
                      ))}
                    {toggleColumn ? (
                      <td>
                        {" "}
                        {toggleActive ? (
                          <div style={{ marginRight: "10px" }}>
                            <label className="switch-toggle">
                              <input
                                id="flexSwitchCheckChecked"
                                checked={item[toggleName]}
                                onChange={(e) => {
                                  let message =
                                    item[toggleName] == false
                                      ? "Are you sure you wish to active this user?"
                                      : "Are you sure you wish to inactive this user?";
                                  var answer = window.confirm(message);
                                  if (answer) {
                                    onToggleClick(item);
                                  }
                                }}
                                type="checkbox"
                              />
                              <span
                                className={
                                  item[toggleName]
                                    ? "slider-toggle round-toggle"
                                    : "slider-toggle-off round-toggle"
                                }
                              ></span>
                            </label>
                          </div>
                        ) : null}
                      </td>
                    ) : null}
                    {tableHeader[tableHeader.length - 1] === " " ? (
                      <td>
                        <div className="d-flex">
                          {editActive ? (
                            <button
                              className="btn btn-link p-0 me-2 d-flex"
                              onClick={() =>
                                navigate(`/${type}/edit/${item._id}`)
                              }
                            >
                              <FiEdit />
                            </button>
                          ) : null}
                          {toggleActive && location.pathname !== "/users" ? (
                            <div style={{ marginRight: "10px" }}>
                              <label className="switch-toggle">
                                <input
                                  id="flexSwitchCheckChecked"
                                  checked={item[toggleName]}
                                  onChange={(e) => {
                                    let message =
                                      item[toggleName] == false
                                        ? "Are you sure you wish to active this user?"
                                        : "Are you sure you wish to inactive this user?";
                                    var answer = window.confirm(message);
                                    if (answer) {
                                      onToggleClick(item);
                                    }
                                  }}
                                  type="checkbox"
                                />
                                <span
                                  className={
                                    item[toggleName]
                                      ? "slider-toggle round-toggle"
                                      : "slider-toggle-off round-toggle"
                                  }
                                ></span>
                              </label>
                            </div>
                          ) : // <div className="form-check btn-link form-switch d-flex align-items-center ms-2">
                            //   <input
                            //     className="form-check-input py-2"
                            //     type="checkbox"
                            //     role="switch"
                            //     name="status"
                            //     id="flexSwitchCheckChecked"
                            //     checked={item[toggleName]}
                            //     onChange={(e) => {
                            //       let message =
                            //         item[toggleName] == false
                            //           ? "Are you sure you wish to active this user?"
                            //           : "Are you sure you wish to inactive this user?";
                            //       var answer = window.confirm(message);
                            //       if (answer) {
                            //         onToggleClick(item);
                            //       }
                            //     }}
                            //   />
                            // </div>
                            null}
                          {viewActive ? (
                            <button
                              className="btn btn-link p-0 me-2 d-flex"
                              onClick={() => {
                                localStorage.setItem('activePage', activePage);
                                navigate(`/${type}/view/${item._id}`)
                              }

                              }
                            >
                              <FiEye />
                            </button>
                          ) : null}
                          {deleteActive ? (
                            <button
                              className="btn btn-link text-danger p-0 me-2 d-flex"
                              data-bs-toggle="modal"
                              data-bs-target="#DeleteAlertModal"
                              onClick={(e) => {
                                setItemToDelete(item._id);
                              }}
                            >
                              <iconify-icon
                                icon="fluent:delete-24-filled"
                                className="text-danger align-self-center"
                              ></iconify-icon>
                            </button>
                          ) : null}
                        </div>
                      </td>
                    ) : tableHeader[tableHeader.length - 1] === "Action" ? (
                      <td>
                        <div className="d-flex">
                          <button
                            className="btn btn-link p-0 me-2 d-flex"
                            onClick={() =>
                              navigate(`/${type}/edit/${item._id}`)
                            }
                          >
                            <FiEdit />
                          </button>
                          <button
                            className="btn btn-link p-0 me-2 d-flex"
                            onClick={() =>
                              navigate(`/${type}/view/${item._id}`)
                            }
                          >
                            <FiEye />
                          </button>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  {loading ? (
                    <td className="text-center" colSpan={tableHeader.length}>
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#83BB41"
                        ariaLabel="Loading Copmanies list"
                        wrapperStyle={{ position: "fixed", left: "50%" }}
                        wrapperClassName=""
                        visible={true}
                      />
                    </td>
                  ) : (
                    <td className="text-center" colSpan={tableHeader.length}>
                      No Data Found!
                    </td>
                  )}
                </tr>
              )}
            </>
          }
        </tbody>
      </table>
      <div
        className="modal fade"
        id="DeleteAlertModal"
        tabindex="-1"
        aria-labelledby="DeleteAlertModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-transparent">
            <div className="modal-body rounded-1 p-4">
              <div className="text-center">
                <iconify-icon
                  icon="mingcute:alert-fill"
                  className="text-danger mb-3"
                  width="46"
                ></iconify-icon>
              </div>
              <h5 className="fw-normal text-center mb-0">
                If you delete this item, it will have an impact on other areas
                where it is linked.
              </h5>
              <h5 className="fw-normal text-center mb-4">
                {" "}
                Are you sure you want to proceed with the deletion?
              </h5>
              <div className="modal-footer d-flex justify-content-center flex-nowrap border-0 py-0 mx-4">
                <button
                  type="button"
                  className="btn btn-primary text-white py-2 w-25 m-0 me-3"
                  onClick={deleteitem}
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-dark text-white py-2 w-25 m-0"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableElement;
