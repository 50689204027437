import React, { useState, useEffect } from "react";
import { api, common } from "../../helpers";
import Select from "react-select";

const VideoRecommendation = () => {
  const [videoRecommendationList, setVideoRecommendationList] = useState([]);
  const [editVideoRecommendation, setEditVideoRecommendation] = useState(true);
  const [tagsList, setTagsList] = useState([]);
  const [tagGroupList, setTagGroupList] = useState([]);
  const [selectedTagGroups, setSelectedTagGroups] = useState([]);
  useEffect(() => {
    getVideoRecommendationList();
    getTagList();
    getTagGroupList();
  }, []);

  const getVideoRecommendationList = () => {
    let data = {
      url: "videoRecommendation",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoRecommendationList(res?.data?.data);
      }
    });
  };

  const getTagGroupList = () => {
    let data = {
      url: "tagGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tagGroup = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...tagGroup];
        setTagGroupList(options);
      }
    });
  };

  const getTagList = () => {
    setTagsList([]);
    let data = {
      url: "tagList",
      method: "POST",
      body: {
        tagGroup: videoRecommendationList.tagGroup
          ? videoRecommendationList.tagGroup
          : [],
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tag = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...tag];
        setTagsList(options);
      }
    });
  };

  const handleStatusChange = (e, item) => {
    const payload = {
      _id: item._id,
      status: !item.status,
      isRecentlyAdded: item.isRecentlyAdded,
      recentlyAddedDisplayPeriod: item.recentlyAddedDisplayPeriod,
      isExcludeWatched: item.isExcludeWatched,
      recentlyWatchedExcludePeriod: item.recentlyWatchedExcludePeriod,
      videoType: item.videoType
    };
    let data = {
      url: "saveVideoRecommendation",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          common.notify("S", res?.data?.msg);
          getVideoRecommendationList();
        } else {
          window.scrollTo(0, 0);
          common.notify("E", res?.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };
  const recentWatched = (e, item, checkName) => {
    const payload = {
      _id: item._id,
      status: item.status,
      isRecentlyAdded:
        checkName === "addedRecetly"
          ? !item.isRecentlyAdded
          : item.isRecentlyAdded,
      recentlyAddedDisplayPeriod: item.recentlyAddedDisplayPeriod,
      isExcludeWatched:
        checkName === "watchRecently"
          ? !item.isExcludeWatched
          : item.isExcludeWatched,
      recentlyWatchedExcludePeriod: item.recentlyWatchedExcludePeriod,
      videoType: item.videoType,
    };

    let data = {
      url: "saveVideoRecommendation",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          common.notify("S", res?.data?.msg);
          getVideoRecommendationList();
        } else {
          window.scrollTo(0, 0);
          common.notify("E", res?.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const removeLabel = (listExtract) => {
    return listExtract.map((item) => item.value);
  };
  const handleSelect = (index, item) => {
    const payload = {
      _id: item._id,
      status: item.status,
      isRecentlyAdded: item.isRecentlyAdded,
      recentlyAddedDisplayPeriod:
        videoRecommendationList[index].recentlyAddedDisplayPeriod,
      isExcludeWatched: item.isExcludeWatched,
      recentlyWatchedExcludePeriod:
        videoRecommendationList[index].recentlyWatchedExcludePeriod,
      tags: removeLabel(videoRecommendationList[index].tags),
      tagGroup: removeLabel(videoRecommendationList[index].tagGroup),
      videoType: item.videoType
    };
    let data = {
      url: "saveVideoRecommendation",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          common.notify("S", res?.data?.msg);
          getVideoRecommendationList();
        } else {
          window.scrollTo(0, 0);
          common.notify("E", res?.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const editDetails = () => {
    if (editVideoRecommendation) {
      setEditVideoRecommendation(false);
    } else {
      setEditVideoRecommendation(true);
    }
  };

  useEffect(() => {
    if (selectedTagGroups) {
      setTagsList([]);
      let data = {
        url: "tagList",
        method: "POST",
        body: {
          tagGroup: selectedTagGroups ? removeLabel(selectedTagGroups) : [],
        },
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          let tag = res?.data?.data;
          const options = [{ label: "All", value: "all" }, ...tag];
          setTagsList(options);
        }
      });
    }
  }, [selectedTagGroups]);

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row">
          <div class="col-lg-6">
            <h5 class="fw-medium mb-4">Video Recommendations</h5>
          </div>
          <div class="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={() => {
                let message = "Are you sure you wish edit video recommendation";
                let viewmessage =
                  "Are you sure you wish view video recommendation";
                var displayPeriodconfirmation = window.confirm(
                  editVideoRecommendation ? message : viewmessage
                );
                if (displayPeriodconfirmation) {
                  editDetails();
                }
              }}
            >
              {editVideoRecommendation ? "Edit" : "View"}
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 overflow-auto">
        <div class="row">
          <div class="col-lg-8 col-xxl-6">
            <ul class="list-group mb-4">
              {videoRecommendationList.length > 0
                ? videoRecommendationList.map((item, index) => (
                    <li class="list-group-item text-white border-bottom pt-3">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="h5 fw-medium">{item.title}</div>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input py-2"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={item.status}
                            value={item.status}
                            disabled={editVideoRecommendation}
                            onChange={(e) => {
                              let message =
                                "Are you sure you wish to change the video recommendation?";
                              var displayPeriodconfirmation = window.confirm(
                                message
                              );
                              if (displayPeriodconfirmation) {
                                handleStatusChange(e, item, index, false);
                              }
                            }}
                          />
                        </div>
                      </div>
                      {item.status && item.isShowRecentlyAdded ? (
                        <div class="d-flex py-2 justify-content-between align-items-center">
                          <p class="fs-6 fw-normal mb-0 px-1">
                            {" "}
                            Include Recently Added Videos
                          </p>
                          <div class="form-check form-switch ms-2">
                            <input
                              class="form-check-input py-2"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              disabled={editVideoRecommendation}
                              checked={item?.isRecentlyAdded}
                              onChange={(e) => {
                                let message =
                                  "Are you sure you wish to change the Recently added videos?";
                                var displayPeriodconfirmation = window.confirm(
                                  message
                                );
                                if (displayPeriodconfirmation) {
                                  recentWatched(e, item, "addedRecetly");
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      {item.isRecentlyAdded &&
                      item.status &&
                      item.isShowRecentlyAdded ? (
                        <div class="d-flex py-2 justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <p class="fs-6 fw-normal mb-0 px-1">
                              {" "}
                              Video added within the last number of days
                            </p>

                            <input
                              type="number"
                              class="form-control py-0 bg-black-50 ms-2 text-center"
                              style={{ width: "20%" }}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="recentlyAddedDisplayPeriod"
                              disabled={editVideoRecommendation}
                              value={item.recentlyAddedDisplayPeriod || 0}
                              onChange={(e) => {
                                let videoListItem = [
                                  ...videoRecommendationList,
                                ];
                                videoListItem[
                                  index
                                ].recentlyAddedDisplayPeriod = e.target.value;
                                setVideoRecommendationList(videoListItem);
                              }}
                            />
                          </div>
                          <div class="d-flex justify-content-end">
                            <button
                              tupe="button"
                              disabled={editVideoRecommendation}
                              onClick={() => {
                                let message =
                                  "Are you sure you wish to change the display period?";
                                var displayPeriodconfirmation = window.confirm(
                                  message
                                );
                                if (displayPeriodconfirmation) {
                                  handleSelect(index, item);
                                }
                              }}
                              className="btn btn-primary btn-sm text-white rounded-pill choose-file-button py-0 xs-small ms-2"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      ) : null}

                      {item.status && item.isExcludeRecentlyWatched ? (
                        <div class="d-flex py-2 justify-content-between align-items-center">
                          <p class="fs-6 fw-normal mb-0 px-1">
                            {" "}
                            Exclude Recently Watched Videos
                          </p>
                          <div class="form-check form-switch ms-2">
                            <input
                              class="form-check-input py-2"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              disabled={editVideoRecommendation}
                              checked={item?.isExcludeWatched}
                              onChange={(e) => {
                                let message =
                                  "Are you sure you wish to change the Exclude recently watch videos?";
                                var displayPeriodconfirmation = window.confirm(
                                  message
                                );
                                if (displayPeriodconfirmation) {
                                  recentWatched(e, item, "watchRecently");
                                }
                              }}
                            />
                          </div>
                        </div>
                      ) : null}

                      {item.isExcludeWatched &&
                      item.status &&
                      item.isExcludeRecentlyWatched ? (
                        <div class="d-flex py-2 justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <p class="fs-6 fw-normal mb-0 px-1">
                              {" "}
                              Exclude Videos Watched within the last number of
                              Days
                            </p>
                            <input
                              type="number"
                              class="form-control py-0 bg-black-50 ms-2 text-center"
                              style={{ width: "20%" }}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              name="recentlyWatchedExcludePeriod"
                              disabled={editVideoRecommendation}
                              value={item.recentlyWatchedExcludePeriod || 0}
                              onChange={(e) => {
                                let videoListItem = [
                                  ...videoRecommendationList,
                                ];
                                videoListItem[
                                  index
                                ].recentlyWatchedExcludePeriod = e.target.value;

                                setVideoRecommendationList(videoListItem);
                              }}
                            />
                          </div>
                          <div class="d-flex justify-content-end">
                            <button
                              tupe="button"
                              disabled={editVideoRecommendation}
                              onClick={() => {
                                let message =
                                  "Are you sure you wish to change the display period?";
                                var displayPeriodconfirmation = window.confirm(
                                  message
                                );
                                if (displayPeriodconfirmation) {
                                  handleSelect(index, item);
                                }
                              }}
                              className="btn btn-primary btn-sm text-white rounded-pill choose-file-button py-0 xs-small ms-2"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      ) : null}

                      {item.status && item.isShowTags ? (
                        <>
                          <div class="d-flex py-2 justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                              <p class="fs-6 fw-normal mb-0 px-1">
                                {" "}
                                Tags and Tag Group
                              </p>
                            </div>

                            <div class="d-flex justify-content-end">
                              <button
                                tupe="button"
                                disabled={editVideoRecommendation}
                                onClick={() => {
                                  let message =
                                    "Are you sure you wish to change the tag and tag Group details?";
                                  var tagConfirmation = window.confirm(message);
                                  if (tagConfirmation) {
                                    handleSelect(index, item);
                                  }
                                }}
                                className="btn btn-primary btn-sm text-white rounded-pill choose-file-button py-0 xs-small ms-2"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                          <div class="d-flex py-2 justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                              <p class="fs-6 fw-normal mb-0 px-1">Tag Group</p>
                            </div>
                            <div
                              class="d-flex justify-content-end w-50"
                              disabled={editVideoRecommendation}
                            >
                              <Select
                                class="form-select form-select-lg"
                                options={tagGroupList}
                                value={item.tagGroup ? item.tagGroup : null}
                                onChange={(selected) => {
                                  let videoListItem = [
                                    ...videoRecommendationList,
                                  ];
                                  videoListItem[index].tagGroup = selected.find(
                                    (tagGroup) => tagGroup.value === "all"
                                  )
                                    ? tagGroupList.slice(1)
                                    : selected;
                                  setSelectedTagGroups(
                                    videoListItem[index].tagGroup
                                  );
                                  setVideoRecommendationList(videoListItem);
                                }}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </div>
                          <div class="d-flex py-2 justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                              <p class="fs-6 fw-normal mb-0 px-1"> Tags</p>
                            </div>
                            <div
                              class="d-flex justify-content-end w-50"
                              disabled={editVideoRecommendation}
                            >
                              <Select
                                class="form-select form-select-lg"
                                options={tagsList}
                                value={item.tags ? item.tags : null}
                                onChange={(selected) => {
                                  let videoListItem = [
                                    ...videoRecommendationList,
                                  ];
                                  videoListItem[index].tags = selected.find(
                                    (tags) => tags.value === "all"
                                  )
                                    ? tagsList.slice(1)
                                    : selected;
                                  setVideoRecommendationList(videoListItem);
                                }}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoRecommendation;
