import React, { useState, useEffect, useRef } from "react";
import { api, common, FormValidation, config } from "../../helpers";
import ReactQuill from "react-quill";
import ModalPopup from '../../elements/Modal';
import Aws from "../../helpers/aws";
import { ProgressBar } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

const MobileDashboard = () => {
  // init
  const imageRef = useRef();
  const quillRef = useRef(null);
  const navigate = useNavigate();
  let { id } = useParams();

  // state
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [description, setDescription] = useState("");
  const [newImageSource, setNewImageSource] = useState([]);
  const [imageTokenDetails, setImageTokenDetails] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setFormErrors] = useState("");
  const [errors, setErrors] = useState([]);
  const { fields, setValue } = FormValidation([]);
  const [dragImageActive, setDragImageActive] = useState([]);
  const strippedDescription = dashboard?.description ? dashboard.description.replace(/<[^>]+>/g, '') : '';

  const editorModules = {
    toolbar: [
      ["bold", "italic"],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],

      ["link"],
    ],

    clipboard: {
      matchVisual: false,
    },
  };

  const editorFormats = [
    "bold",
    "italic",
    "list",
    "link",
  ];
  useEffect(() => {
    setValue({ displayOrder: 0 });
    adGet();
  }, []);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  const handleImageDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageFileChange(e.dataTransfer.files[0], "backgroundImage"); // Call handleImageFileChange with the dropped file
    }
  };
  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };
  const validateImage = (e, file, type) => {
    console.log(file, "file");
    const reader = new FileReader();
    let isValid = false;
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      let height = config.addadvertisement.backgroundImage.height;
      img.onload = () => {
        if (height == img.height) {
          // setDashboard(file);
          handleImageFileChange(e);
        } else {
          common.notify(
            "E",
            `Please upload the image with given format and size`
          );
        }
      };
    };
    reader.readAsDataURL(file);
    return isValid;
  };

  const handleImageFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      checkFileValidation(e.target.files[0], "image", "");
    }
  };
  let isBackgroundImageUpload = "";

  //Normal Creat
  const uploadImageFile = async (file, type) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "backgroundImage") {
      fileName = imageTokenDetails.fileName;
      credentials = imageTokenDetails.credentials;
    }
    const params = {
      Bucket: config.s3_video_Glossary_bucket,
      Key: config.s3_MobileDashboard_folder + fileName,

      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };

    const BUCKET = Aws.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );

    BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        if (progressPercentage < 100) {
          setProgress(progressPercentage);
        } else if (progressPercentage === 100) {
          setProgress(0);
        }
      })
      .send(function (err, data) {
        if (err) {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          }
          if (isBackgroundImageUpload === "true") {
            adCreate();
          } else {
            setLoading(false);
            common.notify("E", err);
            setIsSubmit(false);
          }
        } else {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          }
          if (isBackgroundImageUpload === "true") {
            adCreate();
          }
        }
      });
  };
  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };
  const validateForm = () => {
    let isFormValid = true;
    const formErrors = {};

    if (!dashboard.description || dashboard.description === "<p><br></p>") {
      formErrors.description = "Please enter a description";
      isFormValid = false;
    } else if (strippedDescription.length > 150) {
      formErrors.description = "Please enter a description within 150 characters";
      isFormValid = false;
    }

    setFormErrors(formErrors);
    return isFormValid;
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);


    if (valid) {
      setIsSubmit(true);
      await uploadImageFile(newImageSource, "backgroundImage");
      // adCreate();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const deleteImage = async () => {
    console.log('Delete image clicked');
    setDashboard((prevDashboard) => ({
      ...prevDashboard,
      imageLink: "",
    }));
    setNewImageSource("");
    setImageTokenDetails("");
  };


  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    if (type === "image") {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "image",
      };
    }
    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          if (type === "image") {
            setImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewImageSource(file);
            setValue("image", file.name);
          }
        } else {
          common.notify(res.data.message || res.data.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", type + ":  " + err);
      });
  };
  const adCreate = async () => {
    let body = {
      description: dashboard.description,
      imageLink: imageTokenDetails?.fileName && imageTokenDetails?.fileName || imageTokenDetails,
      redirectTo: dashboard?.redirectTo,
    };
    setLoading(true);
    let data = {
      url: "mobileDashboard",
      method: "PUT",
      query: `/${id}`,
      body: body,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          common.notify("S", res?.data?.msg || res?.data?.message);
          navigate("/mobile-dashboard-list");
        } else {
          common.notify("E", res?.data?.message || res?.data?.msg);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
    setLoading(false);
  };
  const adGet = async () => {
    setIsSubmit(true);
    setLoading(true);
    let data = {
      url: "mobileDashboard",
      method: "GET",
      query: `/${id}`,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          // common.notify("S", res?.data?.msg || res?.data?.message);
          setDashboard({
            description: res?.data?.data[0]?.description,
            redirectTo: res?.data?.data[0]?.redirectTo,
            status: res?.data?.data[0]?.status
          });
          setDescription(res?.data?.data[0]?.description);
          setImageTokenDetails(res?.data?.data[0]?.imageLink);
          setNewImageSource(res?.data?.data[0]?.imageLink);
          console.log(newImageSource);

        } else {
          // common.notify("E", res?.data?.message || res?.data?.msg);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
    setIsSubmit(true);
    setLoading(false);
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Mobile Dashboard - Portal</h5>
          </div>
        </div>
      </div>

      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                          <label for="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>
                          <button
                            className="btn btn-primary py-2 text-white fw-semibold"
                            type="button"
                            onClick={() => {
                              setDashboard({
                                ...dashboard,
                                description: ""
                              });
                              setFormErrors({ description: "" });
                            }}
                          >
                            Clear
                          </button>

                        </div>
                        <ReactQuill
                          ref={quillRef}
                          className="form-control form-control-lg border-0"
                          id="exampleFormControlTextarea1"
                          theme="snow"
                          modules={editorModules}
                          formats={editorFormats}
                          autoFocus="true"
                          value={dashboard?.description}
                          readOnly={strippedDescription.length >= 150}
                          onChange={(content, delta, source, editor) => {
                            console.log(content, delta.ops[0].retain, source, editor);
                            setDashboard((prevDashboard) => ({
                              ...prevDashboard,
                              description: content,
                            }));
                          }}
                        />
                      </div>
                      <p className="error-field">{error.description}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6"
                >
                  <div
                    className="mb-3"
                    onDrop={(e) =>
                      handleImageDrop(
                        e,
                        e.dataTransfer.files[0],
                        "backgroundImage"
                      )
                    }
                    onDragEnter={handleImageDrag}
                    onDragLeave={handleImageDrag}
                    onDragOver={handleImageDrag}
                  >
                    <label htmlFor="input1" className="form-label">
                      Upload Image
                    </label>
                    <div className="bg-secondary-50 p-3 rounded">
                      <div className="file-drop-area text-center ">
                        <div className="text-secondary xs-small">
                          ( Supported image formats - *.jpg, *.jpeg, *.png
                          )
                          <div className="justify-content-center align-items-center d-flex">
                            {"320 * 100"} (Pixels)
                          </div>
                        </div>
                        <div className="my-2">
                          <button
                            type="button"
                            // onClick={() => {
                            //   imageRef.current.click();
                            // }}
                            className="btn btn-primary btn-sm text-white rounded-pill choose-file-button position-relative"
                          >
                            <iconify-icon
                              icon="ic:round-upload"
                              className="text-white fa-lg me-2"
                            ></iconify-icon>
                            Select Image
                            <input
                              className="file-input"
                              type="file"
                              multiple
                              onChange={(e) => {
                                imageRef.current.click();
                                validateImage(
                                  e,
                                  e.target.files[0],
                                  "backgroundImage"
                                );
                              }}
                              ref={imageRef}
                            />
                          </button>
                          <span
                            style={{ width: "fit-content" }}
                            className={
                              newImageSource?.name || newImageSource
                                ? "file-message text-white fw-normal mt-3 d-flex align-items-center mx-auto justify-content-center bg-dark p-2 rounded-pill"
                                : "file-message text-secondary fw-normal mt-3 d-flex align-items-center mx-auto justify-content-center"
                            }
                          >
                            {
                              newImageSource?.name || newImageSource
                                ? (
                                  <>
                                    <span>{newImageSource?.name || newImageSource}</span>
                                    <FiX className="ms-2 fs-6 text-danger" onClick={deleteImage} />
                                  </>
                                )
                                : ""
                            }


                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  {fields.type != "VIDEO_PLAYER" && (
                    <p className="error-field">{errors.image}</p>
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Redirect To
                    </label>
                    <select
                      value={dashboard.redirectTo || ""}
                      className="form-select form-select-lg"
                      aria-label="Default select example"
                      onChange={(e) =>
                        setDashboard((prevDashboard) => ({
                          ...prevDashboard,
                          redirectTo: e.target.value,
                        }))
                      }
                    >
                      <option value="">Select Redirect To</option>
                      <option value="MEDICATION_REMINDER">MEDICATION REMINDER</option>
                      <option value="PROFILE">PROFILE</option>
                      <option value="NOTES">NOTES</option>
                      <option value="GLOSSARY">GLOSSARY</option>
                      <option value="MY_STORY">MY STORY</option>
                      <option value="INVITE">INVITE</option>
                      <option value="MANAGE_CAREGIVER">MANAGE CAREGIVER</option>
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                    <ModalPopup page="mobile-dashboard-list"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileDashboard;