import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, FormValidation } from "../../helpers";
import { ValidateForm } from "./genderValidation";
import ModalPopup from '../../elements/Modal';

const GenderEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  // state
  const [isSubmit, setIsSubmit] = useState(false);
  const { fields, setValue, setMultiValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      genderUpdate();
    }
  };

  const genderUpdate = async () => {
    let payload = {
      answer: fields.genderName,
      displayOrder: fields.displayOrder,
      status: fields.status,
    };
    let data = {
      url: "genderUpdate",
      method: "POST",
      query: `${id}`,

      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          navigate("/genderManagement");
          common.notify("S", res?.data?.msg);
        } else {
          common.notify("E", res?.data?.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
  };
  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  const getGenderDetails = () => {
    let data = {
      url: "genderView",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setMultiValue({
          genderName: res?.data?.data?.answer,
          displayOrder: res?.data?.data?.displayOrder,
          status: res?.data?.data?.status.toString(),
        });
      }
    });
  };

  useEffect(() => {
    getGenderDetails();
  }, []);
  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Gender</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Gender <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Gender name"
                            name="genderName"
                            value={fields.genderName}
                            onChange={(e) => {
                              setValue("genderName", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-field">{errors.genderName}</p>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Display Order <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            onKeyPress={(e) => {
                              numberValidation(e);
                            }}
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Display Order"
                            name="displayOrder"
                            value={fields.displayOrder}
                            onChange={(e) => {
                              setValue("displayOrder", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-field">{errors.displayOrder}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Status <i className="star">*</i>
                        </label>
                        <select
                          className="form-select form-select-lg"
                          aria-label="Default select example"
                          value={fields.status}
                          onChange={(e) => setValue("status", e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                      <p className="error-field">{errors.status}</p>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      {" "}
                      ADD
                    </button>
                    <ModalPopup page="genderManagement"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenderEdit;
