import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, config, FormValidation } from "../../helpers";
import Aws from "../../helpers/aws";
import { ValidateForm } from "./addCancerTypeValidation";
import Select from "react-select";
import ModalPopup from '../../elements/Modal';


const initialValues = {
  cancerTypeName: "",
  description: " ",
  gender: "",
  primaryFontColor: "",
  primaryColor: "",

  cancerTypeImage: "",
  displayOrder: 0,
  backgroundImageUrl: "",
  headerImageUrl: "",
};
let isBackgroundImageUpload = "",
  isHeaderImageUpload = "",
  isRibbonImageUpload = "";

const AddCancerType = () => {
  const navigate = useNavigate();
  const bgref = useRef();
  const ribbenRef = useRef();
  const headerRef = useRef();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dragImageActive, setDragImageActive] = useState([]);
  // const [imageTokenDetails, setImageTokenDetails] = useState([]);
  // const [newImageSource, setNewImageSource] = useState([]);
  const { fields, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);
  const [progress, setProgress] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [genderList, setGenderList] = useState([]);
  const [selectedGender,setSelectedGender]=useState("");
  //background

  const [backgroundImageTokenDetails, setBackgroundImageTokenDetails] =
    useState([]);
  const [newBackgroundImageSource, setNewBackgroundImageSource] = useState([]);

  // header
  const [headerImageTokenDetails, setHeaderImageTokenDetails] = useState([]);
  const [newHeaderImageSource, setNewHeaderImageSource] = useState([]);

  //ribbon
  const [ribbonImageTokenDetails, setRibbonImageTokenDetails] = useState([]);
  const [newRibbonImageSource, setNewRibbonImageSource] = useState([]);

  const [errtext, setErrorText] = useState({
    primaryColor: "",
    primaryFontColor: "",
  });

  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    payload = {
      fileName: file?.name,
      size: String(file?.size),
      type: "image",
    };

    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          if (type === "backgroundImage") {
            setBackgroundImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewBackgroundImageSource(file);
            setValue("backgroundImageSourceName", file.name);
          } else if (type === "headerImage") {
            setHeaderImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });

            setNewHeaderImageSource(file);
            setValue("headerImageSourceName", file.name);
          } else if (type === "ribbonImage") {
            setRibbonImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });

            setNewRibbonImageSource(file);
            setValue("ribbonImageSourceName", file.name);
          }
        } else {
          common.notify("E", res.data.message);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const handleImageDrop = function (e, file, type) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (file) {
      checkFileValidation(file, type, "");
    }
  };

  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit(fields);
    }
  }, [fields]);

  const proceedApiCall = () => {
    let valid = handleSubmit(fields);
    const headerImage = headerImageTokenDetails.fileName;
    const backgroundImage = backgroundImageTokenDetails.fileName;
    const ribbonImage = ribbonImageTokenDetails.fileName;
    const updatingObj = Object.assign(
      {},
      {
        cancerTypeName: formState.cancerTypeName,
        description: formState.description,

        primaryFontColor: formState.primaryColor,
        primaryColor: formState.primaryColor,

        displayOrder: formState.displayOrder,

        gender: formState.gender.map(function getItem(item) { return [item.value].join(" ") }),
      },
      fields.headerImageSourceName
        ? { headerImageUrl: headerImage }
        : formState.headerImageUrl
          ? { headerImageUrl: formState.headerImageUrl }
          : null,
      fields.backgroundImageSourceName
        ? { backgroundImageUrl: backgroundImage }
        : formState.backgroundImageUrl
          ? { backgroundImageUrl: formState.backgroundImageUrl }
          : null,
      fields.ribbonImageSourceName
        ? { cancerTypeImage: ribbonImage }
        : formState.cancerTypeImage
          ? { cancerTypeImage: formState.cancerTypeImage }
          : null
    );
    const modifyData = id
      ? updatingObj
      : {
        ...formState,
        gender: formState.gender.map(function getItem(item) { return [item.value].join(" ") }),
        backgroundImageUrl: backgroundImage,
        headerImageUrl: headerImage,
        cancerTypeImage: ribbonImage,
      };
    let data = id
      ? {
        url: "cancerTypeUpdate",
        method: "POST",
        body: modifyData,
        query: `/${id}`,
      }
      : {
        url: "cancerTypeAdd",
        method: "POST",
        body: modifyData,
      };
    setIsSubmit(true);
    api
      .call(data, async (res) => {
        isBackgroundImageUpload = "false";
        isHeaderImageUpload = "false";
        isRibbonImageUpload = "false";
        setIsSubmit(false);
        if (res?.data?.status === "success") {
          setLoading(false);
          navigate("/cancerTypes");
          common.notify("S", res?.data?.msg);
        } else {
          if (valid) {
            common.notify("E", res?.data?.message);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        isBackgroundImageUpload = "false";
        isHeaderImageUpload = "false";
        isRibbonImageUpload = "false";
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
  };

  const uploadImageFile = async (file, type) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "backgroundImage") {
      fileName = backgroundImageTokenDetails.fileName;
      credentials = backgroundImageTokenDetails.credentials;
    } else if (type === "headerImage") {
      fileName = headerImageTokenDetails.fileName;
      credentials = headerImageTokenDetails.credentials;
    } else {
      fileName = ribbonImageTokenDetails.fileName;
      credentials = ribbonImageTokenDetails.credentials;
    }
    const params = {
      Bucket: config.s3_video_Glossary_bucket,
      Key: ["headerImage", "backgroundImage"].includes(type)
        ? config.s3_cancer_theme_folder + fileName
        : config.s3_cancer_type_folder + fileName,
      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };
    const BUCKET = Aws.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );

    BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        if (progressPercentage < 100) {
          setProgress(progressPercentage);
          setValue("progressPercentage", progressPercentage);
        } else if (progressPercentage === 100) {
          setProgress(0);
        }
      })
      .send(function (err, data) {
        if (err) {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          } else if (type === "headerImage") {
            isHeaderImageUpload = "true";
          } else if (type === "ribbonImage") {
            isRibbonImageUpload = "true";
          }
          if (
            isBackgroundImageUpload === "true" &&
            isHeaderImageUpload === "true" &&
            isRibbonImageUpload === "true"
          ) {
            proceedApiCall();
          } else {
            setLoading(false);
            common.notify("E", err);
            setIsSubmit(false);
          }
        } else {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          } else if (type === "headerImage") {
            isHeaderImageUpload = "true";
          } else if (type === "ribbonImage") {
            isRibbonImageUpload = "true";
          }

          if (
            isBackgroundImageUpload === "true" &&
            isHeaderImageUpload === "true" &&
            isRibbonImageUpload === "true"
          ) {
            proceedApiCall();
          }
        }
      });
  };

  const [formState, setFormState] = useState(initialValues);

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e);
    setValue("gender", e);
    setFormState({ ...formState, "gender": e });
  };


  const onSubmit = async () => {
    setValue("formSubmitted", true);
    if (!errtext.primaryColor && !errtext.primaryFontColor) {
      await uploadImageFile(newBackgroundImageSource, "backgroundImage");
      await uploadImageFile(newHeaderImageSource, "headerImage");
      await uploadImageFile(newRibbonImageSource, "ribbonImage");
    }
  };

  const validateImage = (e, file, type) => {
    const reader = new FileReader();
    let isValid = false;

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      let width = config.cancerTypeImages[type].width;
      let height = config.cancerTypeImages[type].height;

      img.onload = () => {
        if (width == img.width && height == img.height) {
          handleImageDrop(e, file, type);
        } else {
          common.notify(
            "E",
            "Please upload the image with given format and size"
          );
          return;
        }
      };
    };

    reader.readAsDataURL(file);
    return isValid;
  };

  const getUpdatingCancerTypeDetails = async () => {
    setValue("formSubmitted", false);
    let data = {
      url: "cancerTypeEdit",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        const data = res?.data?.data;
        setFormState({
          ...formState,
          ...data,
          ...data.theme,
          gender: data.gender[0].value,
        });
      }
    });
  };

  useEffect(() => {
    if (id) {
      getUpdatingCancerTypeDetails();
    }
  }, [id]);

  useEffect(() => {
    getGenderList();
  }, []);

  const getGenderList = () => {
    let data = {
      url: "genderSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let genders = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...genders];
        setGenderList(options);
      }
    });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">{id ? "Update" : "Add"} Cancer Type</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Cancer Name <i className="star">*</i>
                          </label>

                          <input
                            name="cancerTypeName"
                            value={formState.cancerTypeName}
                            onChange={handleChange}
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Cancer Name"
                          />
                          {errors?.cancerTypeName ? (
                            <p className="text-danger">
                              {errors?.cancerTypeName}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label for="input2" className="form-label">
                            Description<i className="star">*</i>
                          </label>
                          <textarea
                            name="description"
                            value={formState.description}
                            onChange={handleChange}
                            className="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Video description"
                          ></textarea>
                          {errors?.description ? (
                            <p className="text-danger">{errors?.description}</p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Gender<i className="star">*</i>
                          </label>


                          {/* <select
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            name="gender"
                            defaultValue={""}
                            value={formState.gender || ""}
                            onChange={handleChange}
                          >
                            {" "}
                            <option value="">Select Gender</option>
                            {" "}
                            {genderList.length > 0
                              ? genderList.map((item) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))
                              : null}
                          {" "}
                          </select> */}

                          <Select
                            class="form-select form-select-lg"
                            options={genderList}
                            value={selectedGender ? selectedGender : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find(
                                (tagGroup) => tagGroup.value === "all"
                              )
                                ? handleGenderChange(
                                  genderList.slice(1),
                                    "tagGroup"
                                  )
                                : handleGenderChange(selected, "tagGroup")
                            }
                            isSearchable={true}
                            isMulti
                          />
                          {errors?.gender ? (
                            <p className="text-danger">{errors?.gender}</p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Primary font color<i className="star">*</i>
                          </label>
                          <input
                            name="primaryFontColor"
                            value={formState.primaryFontColor}
                            onChange={handleChange}
                            type="color"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Primary font color code"
                          />
                          {errors?.primaryFontColor ? (
                            <p className="text-danger">
                              {errors?.primaryFontColor}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Primary Color<i className="star">*</i>
                          </label>
                          <input
                            name="primaryColor"
                            onChange={handleChange}
                            value={formState.primaryColor}
                            type="color"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Primary color code"
                          />
                          {errors?.primaryColor ? (
                            <p className="text-danger">
                              {errors?.primaryColor}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div
                          className="mb-3"
                          onDrop={(e) => {
                            e.preventDefault();
                            validateImage(
                              e,
                              e.dataTransfer.files[0],
                              "backgroundImage"
                            );
                          }}
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                          disabled={isSubmit}
                        >
                          <label for="input1" className="form-label">
                            Upload Background Thumbnail<i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area d-flex align-items-center justify-content-center">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-secondary xs-small pb-2">
                                  ( Supported image formats - *.jpg, *.jpeg,
                                  *.png )
                                  <div className="justify-content-center align-items-center d-flex">
                                    Height{" "}
                                    {
                                      config.cancerTypeImages.backgroundImage
                                        .height
                                    }
                                    , Width{" "}
                                    {
                                      config.cancerTypeImages.backgroundImage
                                        .width
                                    }{" "}
                                    (Pixels)
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    bgref.current.click();
                                  }}
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button d-flex justify-content-center align-self-center"
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    className="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    newBackgroundImageSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center text-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center text-center"
                                  }
                                >
                                  {newBackgroundImageSource.name
                                    ? newBackgroundImageSource.name
                                    : id
                                      ? formState?.backgroundImageUrl
                                      : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input d-none"
                                  onChange={(e) => {
                                    validateImage(
                                      e,
                                      e.target.files[0],
                                      "backgroundImage"
                                    );
                                  }}
                                  ref={bgref}
                                  type="file"
                                  multiple
                                />
                              </div>
                            </div>
                          </div>
                          {errors?.backgroundImageSourceName ? (
                            <p className="text-danger">
                              {errors?.backgroundImageSourceName}
                            </p>
                          ) : null}
                        </div>
                        <div
                          className="mb-3"
                          onDrop={(e) => {
                            e.preventDefault();
                            validateImage(
                              e,
                              e.dataTransfer.files[0],
                              "headerImage"
                            );
                          }}
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                          disabled={isSubmit}
                        >
                          <label for="input1" className="form-label">
                            Upload Header Thumbnail<i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area d-flex align-items-center justify-content-center">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-secondary xs-small pb-2">
                                  ( Supported image formats - *.jpg, *.jpeg,
                                  *.png )
                                  <div className="justify-content-center align-items-center d-flex">
                                    Height{" "}
                                    {config.cancerTypeImages.headerImage.height}
                                    , Width{" "}
                                    {config.cancerTypeImages.headerImage.width}{" "}
                                    (Pixels)
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    headerRef.current.click();
                                  }}
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button d-flex justify-content-center align-self-center"
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    className="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    newHeaderImageSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center text-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center text-center"
                                  }
                                >
                                  {newHeaderImageSource.name
                                    ? newHeaderImageSource.name
                                    : id
                                      ? formState.headerImageUrl
                                      : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input d-none"
                                  type="file"
                                  multiple
                                  onChange={(e) => {
                                    validateImage(
                                      e,
                                      e.target.files[0],
                                      "headerImage"
                                    );
                                  }}
                                  ref={headerRef}
                                />
                              </div>
                            </div>
                          </div>
                          {errors?.headerImageSourceName ? (
                            <p className="text-danger">
                              {errors?.headerImageSourceName}
                            </p>
                          ) : null}
                        </div>
                        <div
                          className="mb-3"
                          onDrop={(e) => {
                            e.preventDefault();
                            validateImage(
                              e,
                              e.dataTransfer.files[0],
                              "ribbonImage"
                            );
                          }}
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                          disabled={isSubmit}
                        >
                          <label for="input1" className="form-label">
                            Upload Ribbon Thumbnail<i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area d-flex align-items-center justify-content-center">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-secondary xs-small pb-2">
                                  ( Supported image formats - *.jpg, *.jpeg,
                                  *.png )
                                  <div className="justify-content-center align-items-center d-flex">
                                    Height{" "}
                                    {config.cancerTypeImages.ribbonImage.height}
                                    , Width{" "}
                                    {config.cancerTypeImages.ribbonImage.width}{" "}
                                    (Pixels)
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    ribbenRef.current.click();
                                  }}
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button d-flex justify-content-center align-self-center"
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    className="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    newRibbonImageSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center text-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center text-center"
                                  }
                                >
                                  {newRibbonImageSource.name
                                    ? newRibbonImageSource.name
                                    : id
                                      ? formState.cancerTypeImage
                                      : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input d-none"
                                  type="file"
                                  multiple
                                  onChange={(e) => {
                                    validateImage(
                                      e,
                                      e.target.files[0],
                                      "ribbonImage"
                                    );
                                  }}
                                  ref={ribbenRef}
                                />
                              </div>
                            </div>
                          </div>
                          {errors?.ribbonImageSourceName ? (
                            <p className="text-danger">
                              {errors?.ribbonImageSourceName}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                      disabled={isSubmit}
                    >
                      {id ? "Update" : "Submit"}
                    </button>
                    <ModalPopup page="cancerTypes"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCancerType;