import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { api, common } from "../../helpers";
import Table from "../../elements/Table";

export default function GenderList(props) {
  const [genderList, setGenderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  const [search, setSearch] = useState("");
  const tableColumn = ["answer", "displayOrder", "status"];
  const tableHeader = ["S.No", "Gender Name", "Display Order ", "Status", " "];

  const deleteItem = function (id) {
    let data = {
      url: "genderDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getGenderList();
      }
    });
  };
  const getGenderList = () => {
    setLoading(true);
    let data = {
      url: "genderListData",
      method: "GET",
      query: `?search=${search}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setTotalRecord(res?.data?.data?.length);
        setGenderList(res?.data?.data);
        if (res?.data?.data?.length === 0) setLoading(false);
      }
    });
  };

  useEffect(() => {
    getGenderList();
  }, [search]);
  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-4">
          <div class="col-sm-6 d-flex">
            <h5 class="fw-medium mb-0 align-self-center">Manage Gender</h5>
          </div>
          <div class="col-sm-6">
            <div class="d-flex justify-content-end align-self-center">
              <div class="input-group input-group-sm w-50 me-3">
                <span
                  class="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i class="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  class="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Link
                to="/genderManagement-add"
                class="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Gender
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 rounded-1 h-100">
              <div class="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={genderList}
                  type={"genderManagement"}
                  onDelete={deleteItem}
                  activePage={1}
                  loading={loading}
                />
              </div>
              <hr />
              <div class="d-flex justify-content-between mt-3">
                {totalRecord > 0 ? (
                  <p class="mb-0 align-self-center xs-small">
                    Showing {totalRecord} entries
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
