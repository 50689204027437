import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const AdsDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [adsDetails, setAdsDetails] = useState([]);
  // init
  const [activeCheck, setActiveCheck] = useState();

  useEffect(() => {
    getAdsDetails();
  }, []);

  //API
  const getAdsDetails = () => {
    let data = {
      url: "adsView",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setAdsDetails(res?.data?.data);
        setActiveCheck(res?.data?.data?.status);
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/advertisements/edit/${id}`);
  };

  const statusChange = function (id) {
    let data = {
      url: "adsStatusUpdate",
      method: "PUT",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getAdsDetails();
        // alert("changes");
      }
    });
  };

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-3">
          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <Link to="/advertisements" class="text-white">
                    Advertisement Details{" "}
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <Link
              to="/advertisements"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 p-4 rounded-1 h-100">
              <div className="d-flex justify-content-end mb-3">
                Is Active
                <div class="form-check form-switch d-flex align-items-center ms-2">
                  <input
                    class="form-check-input py-2"
                    type="checkbox"
                    role="switch"
                    name="status"
                    id="flexSwitchCheckChecked"
                    checked={activeCheck}
                    onChange={(e) => {
                      // statusChange(id);
                      let message =
                        activeCheck == false
                          ? "Are you sure you wish to active this advertisement?"
                          : "Are you sure you wish to inactive this advertisement?";
                      var answer = window.confirm(message);
                      if (answer) {
                        statusChange(id);
                        setActiveCheck(!activeCheck);
                      }
                    }}
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">Type</h5>
                  <p class="fs-6 mb-0 fw-normal">{adsDetails.type}</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">Advertisement Title</h5>
                  <p class="fs-6 mb-0 fw-normal">{adsDetails.title}</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">Display Duration</h5>
                  <p class="fs-6 mb-0 fw-normal">
                    {adsDetails.type==="VIDEO_PLAYER"? adsDetails.displayDuration:"-"}
                  </p>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">Display Position</h5>
                  <p class="fs-6 mb-0 fw-normal">
                    {adsDetails.displayPosition}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">URL</h5>
                  <p class="fs-6 mb-0 fw-normal">{adsDetails.link}</p>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <h5 class="fw-medium">Priority</h5>
                  {(adsDetails.type==="VIDEO_DETAIL" || adsDetails.type==="NOTES" || adsDetails.type==="GLOSSARY")?"-":
                  <p class="fs-6 mb-0 fw-normal">{adsDetails.priority} </p>}
                </div>
                {adsDetails.videos ? (
                  <div class="col-lg-3 col-md-6 mb-3">
                    <h5 class="fw-medium">Videos</h5>
                     {adsDetails.type==="VIDEO_PLAYER"?
                    <p class="fs-6 mb-0 fw-normal">
                      {adsDetails.videos
                        ? adsDetails.videos.map((list) => <li>{list.label}</li>)
                        : null}
                    </p>:"-"}
                  </div>
                ) : null}
              </div>
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <h5 class="fw-medium">Advertisement image</h5>
                  <img src={adsDetails.imageUrl} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsDetails;
