import { useState } from "react";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.age) {
      formErrors.age = "Please enter age";
      isFormValid = false;
    }
    else
    {
      if (fields.age.trim().length === 0) 
      {
        formErrors.age = "Please enter age";
        isFormValid = false;
      }     
    }

    if (!fields.displayOrder || fields.displayOrder === 0 || fields.displayOrder === "0" || fields.displayOrder === "" || fields.displayOrder === undefined) {
      formErrors.displayOrder = "Please enter display order";
      isFormValid = false;
  }

    if (!fields.status) {
      formErrors.status = "Please select status ";
      isFormValid = false;
    }
    
    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};
