import React from "react";
import { useNavigate } from "react-router-dom";
import logout from "../pages/auth/logout";

const Header = () => {
  const navigate = useNavigate()
  const changePassword = () => {
   
      navigate('/change-password')
    
  }
  const handleLogout = () => {
    logout(() => {
      window.localStorage.clear()
      navigate("/")
    })
  }
  return (
    <div className="container-fluid p-3 pb-0">
      <div className="row">
        <div className="col-auto align-self-center">
          {/* <h4 className="mb-0 cursor-pointer d-flex">
            <iconify-icon icon="gg:menu-left-alt"></iconify-icon>
          </h4> */}
        </div>
        <div className="col-auto ms-auto align-self-center dropdown">
          <div className="d-flex">
            {/* <img src="/images/bell.svg" className="me-3" alt="" /> */}
            <div
              className="profile-img cursor-pointer"
              id="profile-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="/images/adminpic.png" alt="" />
            </div>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="profile-dropdown"
            >
              <li onClick={changePassword} className="pointer text-center">
                <span className="text-dark px-2 " >Change Password</span>
              </li>
              <li onClick={handleLogout} className="pointer text-center">
                <span className="text-danger px-2 " >Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;