import React, { useState } from "react";
import SessionDurationAnalytics from "../Analytics/sessionDurationAnalytics";
import VideoAnalytics from "./videoAnalytics";
import MyStoryAnalytics from "./myStoryAnalytics";
import NotesAnalytics from "./notesAnalytics";
import MedicationReminderAnalytics from "./medicationReminderAnalytics";
import UserListAnalytics from "./userListAnalytics";
import GlossaryAnalytics from "./glossaryAnalytics";

export default function AnalyticsMain(props) {
    const [selectedTime, setSelectedTime] = useState("30days");

    return (
        <div className="d-flex h-100 w-100 flex-row overflow-hidden">
            <div className="w-100 h-100 d-flex flex-column main-wrapper">

                <div className="container-fluid pt-3 px-4">
                    <div className="row mb-4">
                        <div className="col-sm-6 d-flex">
                            <h5 className="fw-medium mb-0 align-self-center">Downloadable Analytic Reports</h5>
                        </div>
                    </div>
                </div>
                <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="bg-muted-50 rounded-1">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">Session CSV</h6>
                                    <select
                                        className="form-select form-select-sm w-auto bg-transparent text-white border-0 me-3"
                                        style={{ fontSize: "20px" }}
                                        aria-label="Default select example"
                                        name="cancerType"
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    >
                                        <option value="30days">30 Days</option>
                                        <option value="3months">3 Months</option>
                                        <option value="6months">6 Months</option>
                                    </select>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <SessionDurationAnalytics
                                        dropdownData={selectedTime} />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">Video CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <VideoAnalytics />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">MyStory CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <MyStoryAnalytics />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">UserList CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <UserListAnalytics />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">Glossary CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <GlossaryAnalytics />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">Notes CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <NotesAnalytics />
                                </div>
                            </div>
                            <div className="bg-muted-50 rounded-1 my-3">
                                <div className="d-flex justify-content-between align-self-center p-3">
                                    <h6 className="fw-normal mb-0 align-self-center">MedicationReminder CSV</h6>
                                </div>
                                <div className="d-flex justify-content-end align-self-center p-3">
                                    <MedicationReminderAnalytics />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}