import AWS from "aws-sdk";

const Aws = {
  bucket: (accessKeyId, secretAccessKey, sessionToken, region) => {
    const Bucket = new AWS.S3({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: region,
    });

    return Bucket;
  },
};

export default Aws;
