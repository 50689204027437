import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, FormValidation, config } from "../../helpers";
import AWS from "../../helpers/aws";
import { ProgressBar } from "react-bootstrap";
import ReactQuill from "react-quill";
import ModalPopup from '../../elements/Modal';

import _ from "lodash";

const GlossaryEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const imageRef = useRef();
  const videoRef = useRef();
  const [dragActive, setDragActive] = useState(false);
  const [newVideoSource, setNewVideoSource] = useState([]);
  const [newImageSource, setNewImageSource] = useState([]);
  const [dragImageActive, setDragImageActive] = useState([]);
  const [videoTokenDetails, setVideoTokenDetails] = useState([]);
  const [imageTokenDetails, setImageTokenDetails] = useState([]);
  const [glossaryImageTokenDetails, setGlossaryImageTokenDetails] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [description, setDescription] = useState("");
  const [glossaryName, setGlossaryName] = useState("");
  const [videoSourceName, setVideoSourceName] = useState("");
  const [imageSourceName, setImageSourceName] = useState("");
  const [cancelVideoActive, setCancelVideoActive] = useState(false);
  const [glossaryType, setGlossaryType] = useState("");
  const [glossaryImageSourceName, setGlossaryImageSourceName] = useState("");
  const [glossaryImage, setGlossaryImage] = useState("");

  const { fields } = FormValidation([]);
  const isImgUpload = useRef(false);
  const isVideoUpload = useRef(false);
  const isGlossaryImageUpload = useRef(false)

  const editorModules = {
    toolbar: [
      ["bold", "italic"],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],

      ["link"],
    ],

    clipboard: {
      matchVisual: false,
    },
  };

  const editorFormats = [
    "bold",
    "italic",
    "list",
    "link",
  ];

  useEffect(() => {
    getGlossaryDetails();
  }, []);

  const quillRef = useRef(null);

  useEffect(() => {
    const removeTabBinding = () => {
      if (quillRef.current === null) {
        return;
      }
      const keyboard = quillRef.current.getEditor().getModule('keyboard');
      // 'hotkeys' have been renamed to 'bindings'
      delete keyboard.bindings[9];
    };

    removeTabBinding();
  }, [quillRef]);

  useEffect(() => {
    if (formSubmitted) {
      handleSubmit();
    }
  }, [glossaryName, description, videoSourceName, imageSourceName, newImageSource, newVideoSource]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};

    if (!glossaryName) {
      formErrors.glossaryName = "Please enter word ";
      isFormValid = false;
    }

    if (!description || description === "<p><br></p>") {
      formErrors.description = "Please enter description ";
      isFormValid = false;
    }


    if (glossaryType === "video" && videoSourceName) {
      if (!imageSourceName) {
        formErrors.imageSourceName = "Please select thump image";
        isFormValid = false;
      }
    }

    if (glossaryType === "image" && !glossaryImageSourceName) {
      formErrors.glossaryImageSourceName = "Please select image";
      isFormValid = false;
    }

    if (glossaryType === "video" && !videoSourceName) {
      formErrors.videoSourceName = "Please select video";
      isFormValid = false;
    }
    setErrors(formErrors);
    return isFormValid;
  };


  const getGlossaryDetails = () => {
    let data = {
      url: "glossaryGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setGlossaryName(res?.data?.data?.glossaryName);
        setVideoSourceName(res?.data?.data?.videoLink);
        setImageSourceName(res?.data?.data?.thumbnailLink);
        setDescription(res?.data?.data?.description);
        setGlossaryType(res?.data?.data?.type);
        setGlossaryImageSourceName(res?.data?.data?.image);
      } else {
        common.notify("E", res?.data?.message);
        setLoading(false);
        navigate("/glossary");
      }
    });
  };

  const glossaryUpdate = async () => {
    let payload = {
      glossaryName: glossaryName,
      description: description,
      type: glossaryType
    };

    setLoading(true);
    let data = {
      url: "glossaryUpdate",
      method: "PUT",
      query: `${id}`,
      body: payload,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        if (_.isEmpty(imageTokenDetails) && _.isEmpty(videoTokenDetails) && _.isEmpty(glossaryImageTokenDetails)) {
          setLoading(false);
          if (glossaryType === "text") {
            let videoSourcePayload = {
              videoLink: "",
              thumbnailLink: "",
              image: ""
            };
            deleteVideo(id, videoSourcePayload);
          }
          else{
            navigate("/glossary");
            common.notify("S", res?.data?.msg);
          }
        }
        else {
          if (glossaryType === "image") {
            if (!_.isEmpty(glossaryImageTokenDetails)) {
              await uploadVideoFiles(glossaryImage, "glossaryimage", id);
            }
          }
          else if (glossaryType === "text") {
            let videoSourcePayload = {
              videoLink: "",
              thumbnailLink: "",
              image: ""
            };
            deleteVideo(id, videoSourcePayload);
          }
          else {
            if (cancelVideoActive && !videoSourceName) {
              let videoSourcePayload = {
                videoLink: "",
              };
              deleteVideo(id, videoSourcePayload)
            }
            if (!_.isEmpty(imageTokenDetails) || !_.isEmpty(videoTokenDetails)) {
              if (!_.isEmpty(imageTokenDetails) && !_.isEmpty(videoTokenDetails)) {
                await uploadVideoFiles(newVideoSource, "video", id);
                await uploadVideoFiles(newImageSource, "image", id);
              } else {
                if (!_.isEmpty(imageTokenDetails)) {
                  await uploadVideoFiles(newImageSource, "image", id);
                }

                if (!_.isEmpty(videoTokenDetails)) {
                  await uploadVideoFiles(newVideoSource, "video", id);
                }
              }
            }

          }
        }
      } else {
        common.notify("E", res.data.message);
        setLoading(false);
        setIsSubmit(false);
      }
    });
  };

  const deleteVideo = (updateId, payload) => {

    let data = {
      url: "glossaryVideoSource",
      method: "PUT",
      query: `${updateId}`,
      body: payload,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        setLoading(false);
        navigate("/glossary");
        common.notify("S", "Glossary details updated succesfully");
        common.notify("E", res.data.message);
        setLoading(false);
        setIsSubmit(false);
      }
    });
  }

  const uploadVideoFiles = async (file, type, videoId) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "image") {
      fileName = imageTokenDetails.fileName;
      credentials = imageTokenDetails.credentials;
    }
    else if (type === "glossaryimage") {
      fileName = glossaryImageTokenDetails.fileName;
      credentials = glossaryImageTokenDetails.credentials;
    }
    else {
      fileName = videoTokenDetails.fileName;
      credentials = videoTokenDetails.credentials;
    }

    const params = {
      Bucket: config.s3_video_Glossary_bucket,
      Key: config.s3_video_Glossary_video_folder + fileName,
      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };
    const BUCKET = AWS.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );

    await BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        if (progressPercentage < 100) {
          setProgress(progressPercentage);
        } else if (progressPercentage === 100) {
          setProgress(0);
        }
      })
      .send(function (err, data) {
        if (err) {
          setLoading(false);
          common.notify("E", err);
          setIsSubmit(false);
        } else {
          if (type === "image")
            isImgUpload.current = true;
          else if (type === "glossaryimage")
            isGlossaryImageUpload.current = true;
          else
            isVideoUpload.current = true;

          if ((isImgUpload.current && isVideoUpload.current) || isGlossaryImageUpload) {
            updateVideoSource(videoId);
          }

        }
      });
  };

  const updateVideoSource = (updateId) => {
    let videoSrc = "",
      imageSrc = "",
      glossaryImageSrc = "";

    if (!_.isEmpty(videoTokenDetails) && isVideoUpload.current) {
      videoSrc = videoTokenDetails.fileName;
    } else {
      if (glossaryType === "video")
        videoSrc = videoSourceName;
      else
        videoSrc = "";
    }

    if (!_.isEmpty(imageTokenDetails) && isImgUpload.current) {
      imageSrc = imageTokenDetails.fileName;
    } else {
      if (glossaryType === "video")
        imageSrc = imageSourceName;
      else
        imageSrc = "";
    }

    if (!_.isEmpty(glossaryImageTokenDetails) && isGlossaryImageUpload.current) {
      glossaryImageSrc = glossaryImageTokenDetails.fileName;
    } else {
      if (glossaryType === "image")
        glossaryImageSrc = glossaryImageSourceName;
      else
        glossaryImageSrc = "";
    }

    let videoSourcePayload = {
      videoLink: videoSrc,
      thumbnailLink: imageSrc,
      image: glossaryImageSrc
    };
    let data = {
      url: "glossaryVideoSource",
      method: "PUT",
      query: `${updateId}`,
      body: videoSourcePayload,
    };
    api.call(data, async (res) => {
      if (res.status === 200) {
        setLoading(false);
        navigate("/glossary");
        common.notify("S", res?.data?.msg);
      } else {
        common.notify("E", res.data.message);
        setLoading(false);
        setIsSubmit(false);
      }
    });
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // handle Image drag events
  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let files = [];
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      files = e.dataTransfer.files[0];
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    }
  };

  const handleImageDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    let file = e.dataTransfer.files[0];
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const reader = new FileReader();
      let isValid = false;

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          if (img.width <= 1920 && img.height <= 1080) {
            checkFileValidation(file, "image");
          } else {
            common.notify(
              "E",
              "Please upload the image with given format and size"
            );
          }
        };
      };

      reader.readAsDataURL(e.dataTransfer.files[0]);
      return isValid
    }
  };

  const handleThumpDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFileValidation(e.dataTransfer.files[0], "image", "");
    }
  };

  const handleThumpFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      checkFileValidation(e.target.files[0], "image", "");
    };
  }

  const handleVideoFileChange = function (e) {
    e.preventDefault();
    var files = [];
    if (e.target.files && e.target.files[0]) {
      files = e.target.files[0];
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        files = e.dataTransfer.files[0];
      }
    }
    var fileExt = files.name.split('.').pop();
    var notSupportedFileFormats = config.not_supported_video_formats;
    let filetype = common.getFileType(files);
    if (filetype === "video" && !notSupportedFileFormats.includes(fileExt)) {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    }
    else {
      window.scrollTo(0, 0);
      common.notify("E", "Invalid file format");
    }
  };

  const handleImageFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      const reader = new FileReader();
      let isValid = false;

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          if (img.width <= 1920 && img.height <= 1080) {
            checkFileValidation(e.target.files[0], "image", "");
          } else {
            common.notify(
              "E",
              "Please upload the image with given format and size"
            );
          }
        };
      };

      reader.readAsDataURL(e.target.files[0]);
      return isValid
    }
  };

  //Normal Creat

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      glossaryUpdate();
    }
  };

  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    if (type === "image") {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "image",
      };
    } else {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "video",
        duration: String(videoDuration),
      };
    }
    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          if (type === "image") {
            if (glossaryType === "video") {
              setImageTokenDetails({
                credentials: res?.data?.data?.token?.Credentials,
                fileName: res?.data?.data?.fileName,
              });
              setNewImageSource(file);
              setImageSourceName(file.name);
              isImgUpload.current = false;
            }
            else {
              setGlossaryImageTokenDetails({
                credentials: res?.data?.data?.token?.Credentials,
                fileName: res?.data?.data?.fileName,
              });
              setGlossaryImageSourceName(file.name);
              setGlossaryImage(file);
              isGlossaryImageUpload.current = false;

            }
          } else {
            setVideoTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewVideoSource(file);
            setVideoSourceName(file.name);
            isVideoUpload.current = false;
          }
        } else {
          common.notify("E", type + ":  " + res.data.message);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", type + ":  " + err);
      });
  };

  const handleCancelVideo = () => {
    setCancelVideoActive(true)
    setVideoSourceName("");
  }

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Glossarys</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Name <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter glossary name"
                            name="glossaryName"
                            value={glossaryName || ""}
                            onChange={(e) =>
                              setGlossaryName(e.target.value)
                            }
                          />
                        </div>
                        <p className="error-field">{errors.glossaryName}</p>
                        <div className="mb-3">
                          <label for="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>
                          <ReactQuill
                            ref={quillRef}
                            class="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            theme="snow"
                            modules={editorModules}
                            formats={editorFormats}
                            value={description || ""}
                            autoFocus="true"
                            onChange={(value) => {
                              setDescription(value)
                            }}
                          ></ReactQuill>
                        </div>
                        <p className="error-field">{errors.description}</p>
                        <div className="mb-3">
                          <label for="input2" className="form-label">
                            Glossary Type <i className="star">*</i>
                          </label>
                          <select
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            value={glossaryType || ""}
                            onChange={(e) =>
                              setGlossaryType(e.target.value)
                            }>
                            <option value="text">Text</option>
                            <option value="image">Image</option>
                            <option value="video"> Video </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        {glossaryType === "video" ? <>
                          <div
                            className="mb-3"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                            disabled={isSubmit}
                          >
                            <label htmlFor="input1" className="form-label">
                              Upload Video
                            </label>
                            <div className="bg-secondary-50 p-3 rounded">
                              <div className="file-drop-area text-center">
                                <div className="text-secondary xs-small">
                                  ( Supported video format - *.mp4 )
                                </div>
                                <div className="my-2">
                                  <button
                                    tupe="button"
                                    onClick={() => videoRef.current.click()}
                                    className="btn btn-primary btn-sm text-white rounded-pill choose-file-button"
                                  >
                                    <iconify-icon
                                      icon="ic:round-upload"
                                      class="text-white fa-lg me-2"
                                    ></iconify-icon>
                                    Select Video
                                  </button>
                                  <button
                                    tupe="button"
                                    onClick={handleCancelVideo}
                                    className="btn mx-2 btn-primary btn-sm text-white rounded-pill choose-file-button"
                                  >
                                    Cancel
                                  </button>
                                  <span className={(videoSourceName) ? "file-message text-white fw-normal mt-3 d-flex justify-content-center" :
                                    "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"}>
                                    {videoSourceName
                                      ? videoSourceName
                                      : "Drag and drop"}
                                  </span>
                                  <input
                                    className="file-input d-none"
                                    ref={videoRef}
                                    type="file"
                                    onChange={handleVideoFileChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="error-field">{errors.videoSourceName}</p>
                            {progress > 0 ? (
                              <ProgressBar className="mt-2">
                                <ProgressBar
                                  striped
                                  animated
                                  variant="success"
                                  now={progress}
                                  label={`${progress}%`}
                                  key={3}
                                />
                              </ProgressBar>
                            ) : null}
                          </div>
                          <div
                            className="mb-3"
                            onDragEnter={handleImageDrag}
                            onDragLeave={handleImageDrag}
                            onDragOver={handleImageDrag}
                            onDrop={handleThumpDrop}
                            disabled={isSubmit || (newVideoSource.name ? false : (videoSourceName ? false : true))}
                          >
                            <label htmlFor="input1" className="form-label">
                              Upload Thumbnail
                            </label>
                            <div className="bg-secondary-50 p-3 rounded">
                              <div className="file-drop-area text-center ">
                                <div className="text-secondary xs-small">
                                  ( Supported image formats - *.jpg, *.jpeg, *.png )
                                </div>
                                <div className="my-2">
                                  <button
                                    tupe="button"
                                    onClick={() => imageRef.current.click()}
                                    className="btn btn-primary btn-sm text-white rounded-pill choose-file-button "
                                  >
                                    <iconify-icon
                                      icon="ic:round-upload"
                                      class="text-white fa-lg me-2"
                                    ></iconify-icon>
                                    Select Image
                                  </button>
                                  <span className={(imageSourceName) ? "file-message text-white fw-normal mt-3 d-flex justify-content-center" :
                                    "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"}>
                                    {imageSourceName
                                      ? imageSourceName
                                      : "Drag and drop"}
                                  </span>
                                  <input
                                    className="file-input d-none"
                                    ref={imageRef}
                                    type="file"
                                    onChange={(e) => handleThumpFileChange(e)}
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="error-field">
                              {errors.imageSourceName}
                            </p>
                          </div>
                        </> :
                          (glossaryType === "image" ?
                            <div
                              className="mb-3"
                              onDragEnter={handleImageDrag}
                              onDragLeave={handleImageDrag}
                              onDragOver={handleImageDrag}
                              onDrop={handleImageDrop}
                            >
                              <label htmlFor="input1" className="form-label">
                                Upload Image
                              </label>
                              <div className="bg-secondary-50 p-3 rounded">
                                <div className="file-drop-area text-center ">
                                  <div className="text-secondary xs-small">
                                    ( Supported image formats - *.jpg, *.jpeg, *.png )
                                    <div className="justify-content-center align-items-center d-flex">
                                      allowed maximum size: Width 1920 , Height 1080 (Pixels)
                                    </div>
                                  </div>
                                  <div className="my-2">
                                    <button
                                      tupe="button"
                                      onClick={() => imageRef.current.click()}
                                      className="btn btn-primary btn-sm text-white rounded-pill choose-file-button "
                                    >
                                      <iconify-icon
                                        icon="ic:round-upload"
                                        class="text-white fa-lg me-2"
                                      ></iconify-icon>
                                      Select Image
                                    </button>
                                    <span className={(glossaryImageSourceName) ? "file-message text-white fw-normal mt-3 d-flex justify-content-center" :
                                      "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"}>
                                      {glossaryImageSourceName
                                        ? glossaryImageSourceName
                                        : "Drag and drop"}
                                    </span>
                                    <input
                                      className="file-input d-none"
                                      ref={imageRef}
                                      type="file"
                                      onChange={(e) => handleImageFileChange(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <p className="error-field">
                                {errors.glossaryImageSourceName}
                              </p>
                            </div> : "")
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    {" "}
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                      disabled={isSubmit}
                    >
                      Update
                    </button>
                    <ModalPopup page="glossary"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlossaryEdit;