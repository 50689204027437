import { useState } from "react";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.glossaryName) {
      formErrors.glossaryName = "Please enter word ";
      isFormValid = false;
    }
    else
    {
      if (fields.glossaryName.trim().length === 0) 
      {
        formErrors.glossaryName = "Please enter word";
        isFormValid = false;
      }
         
    }

    if (!fields.glossaryType) {
      formErrors.glossaryType = "Please select glossary type";
      isFormValid = false;
    }

    if (!fields.description || fields.description === "<p><br></p>") {
      formErrors.description = "Please enter description ";
      isFormValid = false;
    }

    if (fields.glossaryType === "image" && !fields.glossaryImageSourceName) {
      formErrors.glossaryImageSourceName = "Please select image";
      isFormValid = false;
    }

    if (fields.glossaryType === "video" && !fields.videoSourceName) {
      formErrors.videoSourceName = "Please select video";
      isFormValid = false;
    }


    if (fields.glossaryType === "video" && fields.videoSourceName) {
      if (!fields.imageSourceName) {
        formErrors.imageSourceName = "Please select thump image";
        isFormValid = false;
      }
    }

    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};
