import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, common } from "../../helpers";
import validator from "validator";
import { FiEyeOff, FiEye } from "react-icons/fi";

export default function SignIn() {
  // init
  const navigate = useNavigate();
  // state
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const [fields, setFields] = useState({ email: "", password: "" });
  const [passwordType, setPasswordType] = useState("password");

  // handler
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setloading(true);
      let userType = "SUPER_ADMIN";
      let payload = {
        email: fields.email,
        password: fields.password,
        userType: userType,
      };

      let data = {
        url: "authLogin",
        method: "POST",
        body: payload,
      };
      api
        .call(data, (res) => {
          console.log("lod res:", res.data.data.status);
          if (res.data.status === "success") {
            localStorage.setItem("token", res.data.data.tokens.access.token);
            localStorage.setItem(
              "refresh-token",
              res.data.data.tokens.refresh.token
            );
            //common.localSet("authData", common.crypt(data, true));
            navigate("/dashboard");
          } else {
            common.notify("E", res.data.msg);
            setloading(false);
          }
        })
        .catch((error) => {
          common.notify("E", error.response.data.message);
          setloading(false);
        });
    }
  };

  const handleChange = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e?.target?.value }));
  };
  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.email) {
      formErrors.email = "Please enter email";
      isFormValid = false;
    } else {
      if (!validator.isEmail(fields.email)) {
        formErrors.email = "Please enter valid email";
        isFormValid = false;
      }
    }
    if (!fields.password) {
      formErrors.password = "Please enter password";
      isFormValid = false;
    }
    setError(formErrors);
    return isFormValid;
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <div className="d-flex h-100 w-100 flex-column">
      <div className="p-3 col-sm-10">
        <img src="images/logo1.png" className="col-sm-2" alt="" />
      </div>
      <div className="flex-fill justify-content-center d-flex align-items-center">
        <div className="p-5 bg-dark rounded-4 text-white col-sm-4">
          <h4 className="mb-4 fw-bold">Login</h4>
          <label htmlFor="" className="form-label">
            Email
          </label>
          <div className="input-group mb-4">
            <span className="input-group-text bg-white">
              <i className="far fa-envelope text-secondary"></i>
            </span>
            <input
              type="text"
              className="form-control border-start-0 ps-0 bg-white text-secondary"
              placeholder="Enter your email address"
              aria-label="email"
              aria-describedby="basic-addon1"
              name="email"
              maxLength={40}
              value={fields.email}
              onChange={(e) => {
                resetError("email");
                handleChange(e);
              }}
            />
          </div>
          <p className="error-field">{error.email}</p>

          <label htmlFor="" className="form-label">
            Password
          </label>
          <div className="input-group mb-2">
            <span className="input-group-text bg-white">
              <i className="far fa-lock text-secondary"></i>
            </span>
            <input
              type={passwordType === "text" ? "text" : "password"}
              className="form-control border-start-0 ps-0 bg-white text-secondary"
              placeholder="Enter your password"
              name="password"
              aria-label="Password"
              aria-describedby="basic-addon1"
              maxLength={15}
              onChange={(e) => {
                resetError("password");
                handleChange(e);
              }}
              value={fields.password}
            />
            <span className="input-group-text ">
              {passwordType === "text" ? (
                <FiEye onClick={(e) => setPasswordType("password")} />
              ) : (
                <FiEyeOff onClick={(e) => setPasswordType("text")} />
              )}
            </span>
          </div>
          <p className="error-field">{error.password}</p>
          {/* <div className="mb-4 text-end">
            <a href="forgot-password.html">Forgot Password?</a>
          </div> */}
          <button
            type="submit"
            className="btn btn-primary w-100 text-white fw-semibold text-uppercase"
            disabled={loading}
            onClick={onSubmit}
          >
            {loading ? "Processing" : "Sign In"}
          </button>
        </div>
      </div>
    </div>
  );
}
