import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, common, FormValidation } from "../../helpers";
import { ValidateForm } from "./ageGroupValidation";
import ModalPopup from '../../elements/Modal';

const AgeGroupAdd = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const { fields, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      ageGroupCreate();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const ageGroupCreate = async () => {
    let payload = {
      answer: fields.age,
      displayOrder: fields.displayOrder,
      status: fields.status,
    };
    let data = {
      url: "ageGroupSave",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          navigate("/ageGroup");
          common.notify("S", res?.data?.msg);
        } else {
          common.notify("E", res?.data?.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
  };

  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) { return true; }
    e.preventDefault();
    return false;
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Add Age Group </h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Age <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter age group name"
                            name="age"
                            onChange={(e) => {
                              setValue("age", e.target.value);
                            }}
                          />
                        </div>
                        <p className="error-field">{errors.age}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Display Order<i className="star">*</i>
                          </label>
                          <input type="text"
                            className="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Enter display Order"
                            name="displayOrder"
                            style={{ whiteSpace: "pre-wrap" }}
                            onKeyPress={(e) => numberValidation(e)}
                            onChange={(e) => {
                              setValue("displayOrder", e.target.value);
                            }}></input>
                        </div>
                        <p className="error-field">{errors.displayOrder}</p>
                      </div>
                    </div>


                  </div>
                  <div className="col-sm-6">
                    <div className="col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Status<i className="star">*</i>
                        </label>
                        <select
                          className="form-select form-select-lg"
                          aria-label="Default select example"
                          onChange={(e) =>
                            setValue("status", e.target.value)
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                      <p className="error-field">{errors.status}</p>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      {" "}
                      Submit
                    </button>
                    <ModalPopup page="ageGroup"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgeGroupAdd;
