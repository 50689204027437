import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";

const CancerTypesListView = () => {
  let { id } = useParams();
  const [activeCheck, setActiveCheck] = useState(false);
  const navigate = useNavigate();
  const [cancerTypeDetails, setCancerTypeDetails] = useState(null);

  const viewCancerType = () => {
    let data = {
      url: "viewCancerType",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        console.log(res, "res...");
        setCancerTypeDetails(res.data.data);
        setActiveCheck(res?.data?.data?.status);
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/cancerTypes/edit/${id}`);
  };

  const handleStatus = (id) => {
    let data = {
      url: "cancerTypeStatusUpdate",
      method: "PUT",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        viewCancerType();
        // alert("changes");
      }
    });
  };

  useEffect(() => {
    viewCancerType();
  }, []);
  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-3">
          <div className="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="#" className="text-white">
                    Cancer Type Details
                  </a>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <button
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              onClick={editDetails}
              className="btn btn-muted btn-lg text-white fw-semibold">
              Edit
            </button>

          </div>
        </div>
      </div>
      <div></div>
      {cancerTypeDetails ? (
        <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
          <div className="row h-100">
            <div className="col-12">
              <div className="bg-muted-50 p-4 rounded-1 h-100">
                <div className="col-lg-12 mb-12">
                  <div className="d-flex justify-content-end mb-3">
                    Is Active
                    <div className="form-check form-switch d-flex align-items-center ms-2">
                      <input
                        className="form-check-input py-2"
                        type="checkbox"
                        role="switch"
                        name="status"
                        id="flexSwitchCheckChecked"
                        checked={activeCheck}
                        onChange={(e) => {
                          // statusChange(id);
                          let message =
                            activeCheck == false
                              ? "Are you sure you wish to active this cancer type?"
                              : "Are you sure you wish to inactive this cancer type?";
                          var answer = window.confirm(message);
                          if (answer) {
                            handleStatus(id);
                            setActiveCheck(!activeCheck);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Cancer Type</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {cancerTypeDetails?.cancerTypeName}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Gender</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {cancerTypeDetails?.gender.map((gen) => (
                        <li className="fs-6 mb-0 fw-normal">{gen.label}</li>
                      )
                      )
                    }
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Primary font color</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {cancerTypeDetails?.theme?.primaryFontColor}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-3">
                    <h5 className="fw-medium">Primary Color</h5>
                    <p className="fs-6 mb-0 fw-normal">
                      {cancerTypeDetails?.theme?.primaryColor}
                    </p>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 mb-3">
                    <h5 className="fw-medium">Description</h5>
                    <p className="fs-6 mb-0 fw-normal description-scroll">
                      {cancerTypeDetails?.description}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 mb-4">
                        <h5 className="fw-medium">Ribbon Image</h5>
                        <div className="card css-t3ipsp-control">
                          <div className="card-body">
                            <img
                              src={cancerTypeDetails?.cancerTypeImageUrlLink}
                              height="150px"
                              style={{ objectFit: "contain" }}
                              className="w-50 mx-auto d-flex"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4">
                        <h5 className="fw-medium">Background Image</h5>
                        <div className="card css-t3ipsp-control">
                          <div className="card-body">
                            <img
                              src={
                                cancerTypeDetails?.theme?.backgroundImageLink
                              }
                              height="150px"
                              style={{ objectFit: "contain" }}
                              className="w-50  mx-auto d-flex"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mb-4">
                        <h5 className="fw-medium">Header Image</h5>
                        <div className="card css-t3ipsp-control">
                          <div className="card-body">
                            <img
                              src={cancerTypeDetails?.theme?.headerImageUrlLink}
                              height="150px"
                              style={{ objectFit: "contain" }}
                              className="w-50  mx-auto d-flex"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CancerTypesListView;