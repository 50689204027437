import React, { useEffect, useRef, useState } from "react";
import { api, common } from "../../helpers";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment-timezone";

export default function SessionDurationAnalytics({ dropdownData }) {

    const [subscriberExportCsv, setsubscriberExportCsv] = useState([]);
    const [sessionsPerUserTypeExportCsv, setSessionsPerUserTypeExportCsv] = useState([]);
    const csvDownloadRef = useRef(null);
    const [subscriberReport, setSubscriberReport] = useState([]);
    const [sessionsPerUserTypeReport, setSessionsPerUserTypeReport] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [exportDetails, setExportDetails] = useState([
        {
            "S.No": "",
            "First Name": "",
            "Last Name": "",
            "Email": "",
            "User Type": "",
            "Cancer Type": "",
            "Session Date": "",
            "Daily Session Count": "",
            "Session Duration": ""
        },

    ]);

    const [totalSessionsPerUserType, setTotalSessionsPerUserType] = useState([
        {
            "S.No": "",
            "Date": "",
            "Total Session Duration": "",
            "Total Sessions": "",
            "User Type": "",
        },
    ]);
    useEffect(() => {
        if (dropdownData) {
            csvData(dropdownData);
        }
    }, [dropdownData]);

    const csvData = (dropdownData) => {
        setDownloading(true);
        let data = {
            url: "sessionDuration",
            method: "GET",
            query: `?timeRange=${dropdownData}`,
        };
        api
            .call(data, (res) => {
                if (res.status === 200) {
                    setSubscriberReport(res?.data?.data?.userSessions);
                    setSessionsPerUserTypeReport(res?.data?.data?.totalSessionsPerUsertype);
                    setDownloading(false);
                } else {
                    common.notify("E", res.data.message);
                    setDownloading(false);
                }
            })
            .catch((err) => {
                common.notify("E", err);
            });
    };
    useEffect(() => {
        if (subscriberReport?.length > 0) {
            setExportDetails(
                subscriberReport?.map(({ userId, totalCount, ...rest }) => rest)
            );
        }
    }, [subscriberReport]);
    useEffect(() => {
        if (sessionsPerUserTypeReport?.length > 0) {
            setTotalSessionsPerUserType(
                sessionsPerUserTypeReport?.map(({ userId, totalCount, ...rest }) => rest)
            );
        }
    }, [sessionsPerUserTypeReport]);

    useEffect(() => {
        const convertSecondsToHHMMSS = (seconds) => {
            const duration = moment.duration(seconds, 'seconds');
            return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        };
        const modifiedData = exportDetails.map((item, index) => ({
            "S.No": index + 1,
            "First Name": item["First Name"] === "" ? "-" : item["First Name"],
            "Last Name": item["Last Name"] === "" ? "-" : item["Last Name"],
            "Email": item["Email"] === "" ? "-" : item["Email"],
            "User Type": item["User Type"] === "" ? "-" : item["User Type"],
            "Cancer Type": item["Cancer Type"] === "" ? "-" : item["Cancer Type"],
            "Session Date": moment(item["Session Date"]).format("MM/DD/YYYY"),
            "Daily Session Count": item["Daily Session Count"] === "" ? "-" : item["Daily Session Count"],
            "Session Duration": convertSecondsToHHMMSS(item["Session Duration"]),

        }));
        setsubscriberExportCsv(modifiedData);
    }, [exportDetails]);

    useEffect(() => {
        const convertSecondsToHHMMSS = (seconds) => {
            const duration = moment.duration(seconds, 'seconds');
            return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
        };
        const modifiedData1 = totalSessionsPerUserType.map((item, index) => ({
            "S.No": index + 1,
            "Date": moment(item["Date"]).format("MM/DD/YYYY"),
            "User Type": item["User Type"] === "" ? "-" : item["User Type"],
            "Total Sessions": item["Total Sessions"] === "" ? "-" : item["Total Sessions"],
            "Total Session Duration": convertSecondsToHHMMSS(item["Total Session Duration"]),
        }));
        setSessionsPerUserTypeExportCsv(modifiedData1);
    }, [totalSessionsPerUserType]);

    return (
        <div className="d-flex justify-content-end">

            {downloading ? <p className="btn btn-primary btn-lg w-auto d-flex px-4 me-4 fw-medium col-3 text-white">Processing...</p>
                : <>
                    <button type="button"
                        className="btn btn-primary btn-lg w-auto d-flex px-4 fw-medium col-3 me-2"
                        disabled={!dropdownData}>
                        <CSVLink
                            data={subscriberExportCsv}
                            ref={csvDownloadRef}
                            filename="fcn_user_sessions.csv"
                            className="cursor-pointer text-white"
                        >
                            User Sessions CSV</CSVLink></button></>}
            {downloading ? <p className="btn btn-primary btn-lg w-auto d-flex px-4  fw-medium col-3 text-white">Processing...</p>
                : <>
                    <button type="button"
                        className="btn btn-primary btn-lg w-auto d-flex px-4  fw-medium col-3"
                        disabled={!dropdownData}>
                        <CSVLink
                            data={sessionsPerUserTypeExportCsv}
                            ref={csvDownloadRef}
                            filename="fcn_total_sessions_per_usertype.csv"
                            className="cursor-pointer text-white"
                        >
                            Total Sessions Per Usertype CSV</CSVLink></button></>}
        </div>
    );
}
