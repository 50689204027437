import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation().pathname;
  const [navName, setNavName] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    // alert(2);
    if (
      location.includes("/cancerTypes") ||
      location.includes("/userTypes") ||
      location.includes("/tags") ||
      location.includes("/genderManagement") ||
      location.includes("/ageGroup") ||
      location.includes("/tagGroup") ||
      location.includes("/ageGroup") ||
      location.includes("/raceManagement")
    )
      setShow(true);
  }, []);
  return (
    <ul className="list-unstyled ps-0 d-flex flex-column mt-5 flex-fill overflow-auto side-navigation">
      <li>
        <Link
          to="/dashboard"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location === ("/dashboard") || location === ("/") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="ant-design:pie-chart-outlined"
            class="fa-md align-self-center me-3"
          ></iconify-icon>
          <span className="nav-text">Dashboard</span>
        </Link>
      </li>
      <li>
        <Link
          to="/videos"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/videos") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="fluent:video-28-regular"
            class="fa-md align-self-center me-3"
          ></iconify-icon>
          <span className="nav-text">Videos</span>
        </Link>
      </li>
      <li>

        <Link
          to="/VideoRecommendation"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/VideoRecommendation") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="material-symbols:video-settings-rounded"
            class="fa-md align-self-center"
          ></iconify-icon>
          <span className="nav-text">Video Recommendations</span>
        </Link>

      </li>
      <li>
        <Link
          to="/advertisements"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/advertisements") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="material-symbols:inbox-outline"
            class="fa-md align-self-center me-3"
          ></iconify-icon>
          <span className="nav-text">Advertisements</span>
        </Link>
      </li>
      {/* <li>
        <a href="advertisement.html" className="d-flex btn-toggle-none w-100 align-items-center border-0 rounded">
        <iconify-icon icon="material-symbols:inbox-outline" className="fa-md align-self-center me-3">
        </iconify-icon>
        <span className="nav-text">Advertisement</span>
        </a>
        </li> */}
      <li>
        <Link
          to="#"
          className="d-flex btn-toggle w-100 align-items-center border-0 rounded collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded="false"
        >
          <iconify-icon
            icon="icon-park-outline:setting-config"
            class="fa-md align-self-center"
          ></iconify-icon>
          <span className="nav-text">Config</span>
        </Link>
        <div
          className={`collapse ${location.includes(navName) !== true
              ? !show
                ? ""
                : "show"
              : !show
                ? ""
                : "show"
            }`}
          id="home-collapse"
        >
          <ul className="btn-toggle-nav list-unstyled fw-normal pt-2 small ms-3">
            <li
              onClick={() => {
                setNavName("/tagGroup");
                setShow(true);
              }}
            >
              <Link
                to="/tagGroup"
                className={`d-flex text-decoration-none" ${location.includes("/tagGroup") ? " active" : ""
                  }`}
              >
                Tag Groups
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/tags");
                setShow(true);
              }}
            >
              <Link
                to="/tags"
                className={`d-flex text-decoration-none" ${location.includes("/tags") ? " active" : ""
                  }`}
              >
                Tags
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/userTypes");
                setShow(true);
              }}
            >
              <Link
                to="/userTypes"
                className={`d-flex text-decoration-none" ${location.includes("/userTypes") ? " active" : ""
                  }`}
              >
                User Types
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/cancerTypes");
                setShow(true);
              }}
            >
              <Link
                to="/cancerTypes"
                className={`d-flex text-decoration-none" ${location.includes("/cancerTypes") ? " active" : ""
                  }`}
              >
                Cancer Types
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/genderManagement");
                setShow(true);
              }}
            >
              <Link
                to="/genderManagement"
                className={`d-flex text-decoration-none" ${location.includes("/genderManagement") ? " active" : ""
                  }`}
              >
                Manage Gender
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/ageGroup");
                setShow(true);
              }}
            >
              <Link
                to="/ageGroup"
                className={`d-flex text-decoration-none" ${location.includes("/ageGroup") ? " active" : ""
                  }`}
              >
                Manage Age Group
              </Link>
            </li>
            <li
              onClick={() => {
                setNavName("/raceManagement");
                setShow(true);
              }}
            >
              <Link
                to="/raceManagement"
                className={`d-flex text-decoration-none" ${location.includes("/raceManagement") ? " active" : ""
                  }`}
              >
                Manage Ethnicity
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={`collapse ${location.includes("userTypes") ? "show" : ""}`}
          id="home-collapse"
        >
          <ul className="btn-toggle-nav list-unstyled fw-normal pt-2 small ms-3"></ul>
        </div>
      </li>
      <li>
        <Link
          to="/glossary"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/glossary") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="material-symbols:chat-outline-rounded"
            class="fa-md align-self-center me-3"
          ></iconify-icon>
          <span className="nav-text">Glossary</span>
        </Link>
      </li>
      <li>
        <Link
          to="/users"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/users") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="carbon:report"
            class="fa-md align-self-center me-3"
          ></iconify-icon>
          <span className="nav-text">Manage users</span>
        </Link>
      </li>
      {/* <li>
          <a href="invited-users.html" class="d-flex btn-toggle-none w-100 align-items-center rounded border-0">
          <i className="bi bi-person-plus fa-md d-flex align-self-center me-3"></i> <span class="nav-text">Invited users</span>
          </a>
          </li>*/}
      <li>
        <Link
          to="/onboarding"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/onboarding") ? " active" : ""
            }`}
        >
          <i class="fal fa-handshake fa-md align-self-center me-3"></i>{" "}
          <span class="nav-text">On boarding</span>
        </Link>
      </li>
      <li>
        <Link
          to="/episode"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/episode") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="fluent:video-clip-multiple-24-regular"
            class="fa-md align-self-center me-3"
          ></iconify-icon>

          <span className="nav-text">Episodes & group</span>
        </Link>
      </li>
      <li
      >
        <Link
          to="/mobile-dashboard-list"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/mobile-dashboard") ? " active" : ""
            }`}
        >
          <iconify-icon
            icon="subway:paragraph-2"
            class="fa-md align-self-center me-3"
          ></iconify-icon>

          <span className="nav-text">Mobile Dashboard - Portal</span>
        </Link>
      </li>
      <li
      >
        <Link
          to="/Analytics"
          className={`d-flex btn-toggle-none w-100 align-items-center border-0 rounded ${location.includes("/Analytics") ? " active" : ""
            }`}
        >
          <iconify-icon icon="material-symbols:download" class="fa-md align-self-center me-3"></iconify-icon>

          <span className="nav-text">Analytic Reports</span>
        </Link>
      </li>
    </ul>
  );
};
export default Header;
