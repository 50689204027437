import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import { Link } from "react-router-dom";

const TagsDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [tagsDetails, settagsDetails] = useState([]);
  const [activeCheck, setActiveCheck] = useState();
  // init

  //API
  const gettagsDetails = () => {
    let data = {
      url: "tagsGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        settagsDetails(res?.data?.data);
        setActiveCheck(res?.data?.data?.status);
      }
    });
  };
  const statusChange = function (id) {
    let data = {
      url: "tagsStatus",
      method: "PUT",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        gettagsDetails();
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/tags/edit/${id}`);
  };
  useEffect(() => {
    gettagsDetails();
  }, []);
  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-3">
          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <Link to="/tags" class="text-white">
                    Tag Details{" "}
                  </Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {tagsDetails.tagName}
                </li>
              </ol>
            </nav>
          </div>
          <div
            className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3"
            style={{ alignItems: "center" }}
          >
            <Link
              to="/tags"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 p-4 rounded-1 h-100">
              <div className="d-flex align-items-center justify-content-between">

             <h5 className="fw-medium">Description</h5>
              <div className="d-flex justify-content-end mb-3">
                Is Active
                <div class="form-check form-switch d-flex align-items-center ms-2">
                  <input
                    class="form-check-input py-2"
                    type="checkbox"
                    role="switch"
                    name="status"
                    id="flexSwitchCheckChecked"
                    checked={activeCheck}
                    onChange={(e) => {
                      // statusChange(id);
                      let message =
                        activeCheck == false
                          ? "Are you sure you wish to active this Tag?"
                          : "Are you sure you wish to inactive this Tag?";
                      var answer = window.confirm(message);
                      if (answer) {
                        statusChange(id);
                        setActiveCheck(!activeCheck);
                      }
                    }}
                  />
                </div>
         
              </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-3">
                  
                  <p
                    className="fs-6 mb-0 fw-normal"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {tagsDetails.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsDetails;