import { useState } from "react";

const checkColorValidation = (value) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return reg.test(value);
};

export const ValidateForm = (fields) => {
  console.log(fields);
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.cancerTypeName) {
      formErrors.cancerTypeName = "Please enter cancer type name";
      isFormValid = false;
    }
    else{
      if (fields.cancerTypeName.trim().length === 0) 
      {
        formErrors.cancerTypeName = "Please enter cancer type name";
        isFormValid = false;
      }
    }
    if (!fields.description) {
      formErrors.description = "Please enter description ";
      isFormValid = false;
    }
    else {
      if (fields.description.trim().length === 0) {
        formErrors.description = "Please enter description";
        isFormValid = false;
      }
    }

    if (!fields.gender) {
      formErrors.gender = "Please select gender";
      isFormValid = false;
    } else {
      if (fields.gender.length <= 0) {
        formErrors.gender = "Please select gender";
        isFormValid = false;
      }
    }


    if (!fields.primaryFontColor || fields.primaryFontColor === "<p><br></p>") {
      formErrors.primaryFontColor = "Please enter Primary Font Color ";
      isFormValid = false;
    } else if (!checkColorValidation(fields.primaryFontColor)) {
      formErrors.primaryFontColor = "Primary font color not valid";
      isFormValid = false;
    }

    if (!fields.primaryColor) {
      formErrors.primaryColor = "Please enter primaryColor";
      isFormValid = false;
    } else if (!checkColorValidation(fields.primaryColor)) {
      formErrors.primaryColor = "Primary color not valid";
      isFormValid = false;
    }

    if (!fields.backgroundImageSourceName) {
      formErrors.backgroundImageSourceName = "Please select background image";
      isFormValid = false;
    }
    if (!fields.headerImageSourceName) {
      formErrors.headerImageSourceName = "Please select header image";
      isFormValid = false;
    }
    if (!fields.ribbonImageSourceName) {
      formErrors.ribbonImageSourceName = "Please select ribbon image";
      isFormValid = false;
    }

    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};