export const config = {
  base_url: process.env.REACT_APP_BASE_URL,
  url: process.env.REACT_APP_API_URL,
  s3_video_path: process.env.REACT_APP_S3_BUCKET_PATH_VIDEO,
  s3_image_path: process.env.REACT_APP_S3_BUCKET_PATH_IMAGE,
  s3_video_thumpnail_folder:
    process.env.REACT_APP_S3_BUCKET_PATH_VIDEO_THUMPNAIL_FOLDER,

  s3_video_Glossary_bucket: process.env.REACT_APP_S3_BUCKET_GLOSSARY_PATH,
  s3_video_Glossary_video_folder:
    process.env.REACT_APP_S3_BUCKET_PATH_VIDEO_FOLDER,
  s3_MobileDashboard_folder:process.env.REACT_APP_S3_BUCKET_MOBILE_DASHBOARD,
  not_supported_video_formats:
    process.env.REACT_APP_NOT_SUPPORTED_VIDEO_FORMATS,
  s3_cancer_type_folder: process.env.REACT_APP_S3_BUCKET_PATH_CANCER_FOLDER,
  s3_cancer_theme_folder:
    process.env.REACT_APP_S3_BUCKET_PATH_CANCER_THEME_FOLDER,
  s3_ads_type_folder: process.env.REACT_APP_S3_BUCKET_PATH_AD_FOLDER,
  addadvertisement: {
    backgroundImage: {
      height: process.env.REACT_APP_S3_HEIGHT_AD,
    },
  },
  cancerTypeImages: {
    backgroundImage: {
      height: process.env.REACT_APP_BACKGROUND_IMAGE_SIZE_HEIGHT,
      width: process.env.REACT_APP_BACKGROUND_IMAGE_SIZE_WIDTH,
    },
    headerImage: {
      height: process.env.REACT_APP_HEADER_IMAGE_SIZE_HEIGHT,
      width: process.env.REACT_APP_HEADER_IMAGE_SIZE_WIDTH,
    },
    ribbonImage: {
      height: process.env.REACT_APP_RIBBON_IMAGE_SIZE_HEIGHT,
      width: process.env.REACT_APP_RIBBON_IMAGE_SIZE_WIDTH,
    },
  },
  // api
  api: {
    isExpired: 401,
    url: process.env.REACT_APP_API_URL,
    cloudImgPath: "",
  },
  cryptKey: "#cErLyPpKt#",
  // elements
  elements: {
    toast: {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  },
};
