import { useState } from "react";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.tagName) {
      formErrors.tagName = "Please enter tag name ";
      isFormValid = false;
    }

    if (!fields.description) {
      formErrors.description = "Please enter description ";
      isFormValid = false;
    }

    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};
