import React, { useEffect, useRef, useState } from "react";
import { api, common } from "../../helpers";
import { CSVLink } from "react-csv";
import moment from "moment";

export default function UserListAnalytics(props) {

    const [subscriberExportCsv, setsubscriberExportCsv] = useState([]);
    const csvDownloadRef = useRef(null);
    const [subscriberReport, setSubscriberReport] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [exportDetails, setExportDetails] = useState([
        {
            "S.No": "",
            "First Name": "",
            "Last Name": "",
            "Email": "",
            "Gender": "",
            "Phone Number": "",
            "Login Type": "",
            "User Type": "",
            "Cancer Type": "",
            "Age Group": "",
            "Ethnicity": "",
            "Referrels Count": "",
            "Account Status": "",
            "Device OS": "",
            "APP Version": "",
            "Last login": "",
            "Created At": "",
        },

    ]);
    const csvData = () => {
        setDownloading(true);
        let data = {
            url: "userListDownload",
            method: "GET",
        };
        api
            .call(data, (res) => {
                if (res.status === 200) {
                    setSubscriberReport(res?.data?.data);
                    setDownloading(false);
                } else {
                    common.notify("E", res.data.message);
                    setDownloading(false);
                }
            })
            .catch((err) => {
                common.notify("E", err);
            });
    };

    useEffect(() => {
        csvData();
    }, []);

    useEffect(() => {
        if (subscriberReport?.length > 0) {
            setExportDetails(
                subscriberReport?.map(({ userId, totalCount, ...rest }) => rest)
            );
        }
    }, [subscriberReport]);

    useEffect(() => {
        const modifiedData = exportDetails.map((item, index) => ({
            "S.No": index + 1,
            "First Name": item["First Name"] === "" ? "-" : item["First Name"],
            "Last Name": item["Last Name"] === "" ? "-" : item["Last Name"],
            "Email": item["Email"] === "" ? "-" : item["Email"],
            "Gender": item["Gender"] === "" ? "-" : item["Gender"],
            "Phone Number": item["Phone Number"] === "" ? "-" : item["Phone Number"],
            "Login Type": item["Login Type"] === "" ? "-" : item["Login Type"],
            "User Type": item["User Type"] === "" ? "-" : item["User Type"],
            "Cancer Type": item["Cancer Type"] === "" ? "-" : item["Cancer Type"],
            "Age Group": item["Age Group"] === "" ? "-" : item["Age Group"],
            "Ethnicity": item["Ethnicity"] === "" ? "-" : item["Ethnicity"],
            "Referrels Count": item["Referrels Count"] === "" ? "-" : item["Referrels Count"],
            "Account Status": item["Account Status"] === "" ? "-" : item["Account Status"],
            "Device OS": item["Device Os"] === "" ? "-" : item["Device Os"],
            "APP Version": item["App Version"] === "" ? "-" : item["App Version"],
            "Last login": item["Last Login"] === "" ? "-" : moment(item["Last Login"]).tz("America/Chicago").format("MM/DD/YYYY HH:mm"),
            "Created At": moment(item["Created At"]).tz("America/Chicago").format("MM/DD/YYYY HH:mm")
        }));

        setsubscriberExportCsv(modifiedData);
    }, [exportDetails]);

    return (
        <>
            <div className="d-flex justify-content-center">
                {downloading ? <p className="mb-0 pe-4" style={{ color: "grey" }}>Processing...</p>
                    : <>
                        <button type="button"
                            className="btn btn-primary btn-lg w-auto d-flex px-4 fw-medium col-3">
                            <CSVLink
                                data={subscriberExportCsv}
                                ref={csvDownloadRef}
                                filename="fcn_users_categorization.csv"
                                className="cursor-pointer text-white"
                            >UserList CSV </CSVLink></button></>}
            </div>
        </>
    );
}
