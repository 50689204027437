import React, { useEffect, useState } from "react";
import Table from "../../elements/Table";
import PaginationElements from "../../elements/pagination";
import { api, common } from "../../helpers";
import "moment-timezone";

export default function UserList(props) {

  const tableHeader = [
    "Sno",
    "Name",
    "Gender",
    "Cancer Type",
    "User Type",
    "Activate/Deactivate",
    "Actions",
    " ",
  ];
  const tableColumn = ["name", "genderValue", "cancerTypeLabels", "userType"];
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [itemSize, setItemSize] = useState(10);
  const [search, setSearch] = useState("");
  const [pagingCounter, setPagingCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('activePage')) {
      getUserList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!localStorage.getItem('activePage')?.length) {
      getUserList();
    }
  }, [search]);

  useEffect(() => {
    if (localStorage.getItem('activePage')
    ) {
      setCurrentPage(Number(localStorage.getItem('activePage')));
      if (Number(localStorage.getItem('activePage')) === 1) {
        getUserList();
      }
      localStorage.removeItem('activePage');

    } else {
      setCurrentPage(1);
    }
  }, [])
  //handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteItem = function (id) {
    let data = {
      url: "userDelete",
      method: "POST",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getUserList();
      }
    });
  };

  //API
  const getUserList = () => {
    setLoading(true);
    let data = {
      url: "userList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=createdAt&sortDir=desc&search=${search}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setUserList(
          res?.data?.data?.results.map((each) => ({
            ...each,
            genderValue: each.gender ? each.gender?.label : "",
            cancerTypeLabels: each.cancerTypes
              .map((each) => each.label)
              .join(","),
          }))
        );
        setTotalRecord(res?.data?.data?.totalResults);
        setItemSize(res?.data?.data?.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        setLoading(false);
      }
    });
  };

  const onToggleClick = (item) => {
    console.log(item);
    if (item) {
      const id = item?._id;
      let data = {
        url: "userStatus",
        method: "PUT",
        query: `/${id}`,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          console.log(res, "res...");
          getUserList();
        }
      });
    }
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-4">
          <div className="col-sm-6 d-flex">
            <h5 className="fw-medium mb-0 align-self-center">User List</h5>
          </div>

          <div className="col-sm-6">
            <div className="d-flex justify-content-end align-items-center">
              <div className="input-group input-group-sm w-50 me-3">
                <span
                  className="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i className="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control rounded-end ps-0 bg-muted border-0 py-2"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 rounded-1 h-100">
              <div className="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  onToggleClick={onToggleClick}
                  tableData={userList}
                  type={"users"}
                  onDelete={deleteItem}
                  activePage={currentPage}
                  toggleColumn={true}
                  toggleActive={true}
                  toggleName="status"
                  editActive={false}
                  deleteActive={true}
                  loading={loading}
                />
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-3">
                {totalRecord > 0 ? (
                  <p className="mb-0 align-self-center xs-small">
                    Showing {pagingCounter} to{" "}
                    {pagingCounter > 0
                      ? pagingCounter + (userList.length - 1)
                      : pagingCounter}{" "}
                    of {totalRecord} entries
                  </p>
                ) : null}
                <div className="d-flex flex-row-reverse">
                  {totalRecord > itemSize && (
                    <PaginationElements
                      activePage={currentPage}
                      TotalRecord={totalRecord}
                      itemSize={itemSize}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
