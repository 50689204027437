import React, { useEffect, useState, useRef } from "react";
import PaginationElements from "../../elements/pagination";
import { api, common } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Table from "../../elements/Table";

export default function QuestionsList(props) {
  //inti
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [itemSize, setItemSize] = useState(10);
  const [search, setSearch] = useState("");
  const [cancerType, setCancerType] = useState("");
  const [cancerList, setCancerList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [userType, setUserType] = useState("");
  const [pagingCounter, setPagingCounter] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  let init = useRef(true);

  const handleFilterClick = (event) => {
    init.current = false;
    setCurrentPage(1);
    getQuestionList();
  };

  const handleFilterClear = (event) => {
    init.current = true;
    setCancerType("");
    setUserType("");
    setStatus("");
    setCurrentPage(1);
    getQuestionList();
  };

  useEffect(() => {
    if (!localStorage.getItem('activePage')) {
      getQuestionList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!localStorage.getItem('activePage')?.length) {
      getQuestionList();
    }
  }, [search]);

  useEffect(() => {
    if (localStorage.getItem('activePage')
    ) {
      setCurrentPage(Number(localStorage.getItem('activePage')));
      if (Number(localStorage.getItem('activePage')) === 1) {
        getQuestionList();
      }
      localStorage.removeItem('activePage');
    } else {
      setCurrentPage(1);
    }
  }, [])
  const tableHeader = [
    "#",
    "User Type",
    "Cancer Type",
    "Total Question",
    "Status",
    " ",
  ];
  const tableColumn = [
    "userType.label",
    "cancerType.label",
    "totalQuestions",
    "status",
  ];

  useEffect(() => {
    getCancerList();
    getUserTypeList();
  }, []);

  //handlers
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //API
  const getQuestionList = () => {
    setLoading(true);
    let urlCancerType = "", urlUserType = "", urlStatus = "";
    if (!init.current) {
      urlCancerType = Boolean(cancerType.length)
        ? `&cancerType=${cancerType}`
        : "";
      urlUserType = Boolean(userType.length)
        ? `&userType=${userType}`
        : "";

      urlStatus = Boolean(status.length) ? `&status=${status}` : "";
      debugger;
    }
    let data = {
      url: "onboardingList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=&createdAt=&sortDir=asc&search=${search}${urlCancerType}${urlUserType}${urlStatus}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoList(res?.data?.data?.results);
        setTotalRecord(res?.data?.data?.totalResults);
        setItemSize(res?.data?.data?.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        if (res?.data?.data?.results === 0)
          setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  //API
  const getCancerList = () => {
    let data = {
      url: "cancerSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCancerList(res?.data?.data);
      }
    });
  };

  const getUserTypeList = () => {
    let data = {
      url: "userTypeSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setUserTypeList(res?.data?.data);
      }
    });
  };

  const deleteItem = function (id) {
    let data = {
      url: "onboardingDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getQuestionList();
      }
    });
  };



  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-4">
          <div className="col-sm-6 d-flex">
            <h5 className="fw-medium mb-0 align-self-center">Onboarding Questions</h5>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-end align-self-center">
              <div className="input-group input-group-sm w-50 me-3">
                <span
                  className="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i className="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <button
                type="button"
                class="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
                onClick={() => navigate(`/onboarding-add`)}
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Questions
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded-1">
              <div className="d-flex justify-content-end align-self-center p-3">
                <select
                  className="form-select form-select-sm bg-transparent text-white border-0 me-2 xs-small form-select-filter"
                  aria-label="Default select example"
                  name="cancerType"
                  value={cancerType || ""}
                  onChange={(e) => setCancerType(e.target.value)}
                >
                  <option value="">Select Cancer type</option>
                  {cancerList.length > 0
                    ? cancerList.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))
                    : null}
                </select>
                <select
                  className="form-select form-select-sm bg-transparent text-white border-0 me-2 xs-small form-select-filter"
                  aria-label="Default select example"
                  defaultValue={""}
                  value={userType || ""}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option value="">Select User type</option>
                  {userTypeList.length > 0
                    ? userTypeList.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))
                    : null}
                </select>

                <select
                  className="form-select form-select-sm bg-transparent text-white border-0 me-2 xs-small form-select-filter"
                  aria-label="Default select example"
                  defaultValue={""}
                  value={status || ""}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>

                <div>
                  <button
                    className="btn btn-muted py-0 px-2 fs-5"
                    type="button"
                    onClick={handleFilterClick}
                  >
                    <i className="fal fa-filter me-1"></i>
                    Filter
                  </button>
                </div>

                <div className="ms-2">
                  <button
                    className="btn btn-muted py-0 px-2 fs-5 "
                    type="button"
                    onClick={handleFilterClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={videoList}
                  type={"onboarding"}
                  onDelete={deleteItem}
                  activePage={currentPage}
                  loading={loading}
                />
              </div>
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  {totalRecord > 0 ?
                    <p className="mb-0 align-self-center xs-small">
                      Showing {pagingCounter} to{" "}
                      {pagingCounter > 0
                        ? pagingCounter + (videoList.length - 1)
                        : pagingCounter}{" "}
                      of {totalRecord} entries
                    </p> : null}
                  <nav aria-label="Page navigation example">
                    {totalRecord > itemSize && (
                      <PaginationElements
                        activePage={currentPage}
                        TotalRecord={totalRecord}
                        itemSize={itemSize}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
