import React, { useEffect, useRef, useState } from "react";
import { api, common } from "../../helpers";
import { CSVLink } from "react-csv";
import moment from "moment";

export default function VideoAnalytics(props) {

    const [subscriberExportCsv, setsubscriberExportCsv] = useState([]);
    const csvDownloadRef = useRef(null);
    const [subscriberReport, setSubscriberReport] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [exportDetails, setExportDetails] = useState([]);
    const [subscriberVideoCsv, setsubscriberVideoCsv] = useState([]);
    const [videoWatchedCount, setvideoWatchedCount] = useState([]);
    const [videoDetails, setVideoDetails] = useState([]);
    let init = useRef(true);

    const csvData = () => {
        setDownloading(true);
        let data = {
          url: "videoCSVDownload",
          method: "GET",
        };
        api
          .call(data, (res) => {
            if (res.status === 200) {
              setSubscriberReport(res?.data?.data?.videoWatchedByUsers);
              setvideoWatchedCount(res?.data?.data?.videoWatchedCount);
              setDownloading(false);
            } else {
              common.notify("E", res.data.message);
              setDownloading(false);
            }
          })
          .catch((err) => {
            common.notify("E", err);
          });
      };

    useEffect(() => {
        csvData();
    }, []);

    useEffect(() => {
        if (subscriberReport?.length > 0) {
          setExportDetails(
            subscriberReport?.map(({ userId, totalCount, ...rest }) => rest)
          );
        }
      }, [subscriberReport]);
      useEffect(() => {
        if (videoWatchedCount?.length > 0) {
          setVideoDetails(
            videoWatchedCount?.map(({ userId, totalCount, ...rest }) => rest)
          );
        }
      }, [videoWatchedCount]);

    useEffect(() => {
    const modifiedData = exportDetails.map((item, index) => ({
      "S.No": index + 1,
      "First Name": item["First Name"] === "" ? "-" : item["First Name"],
      "Last Name": item["Last Name"] === "" ? "-" : item["Last Name"],
      "Email": item["Email"] === "" ? "-" : item["Email"],
      "User Type": item["User Type"] === "" ? "-" : item["User Type"],
      "Cancer Type": item["Cancer Type"] === "" ? "-" : item["Cancer Type"],
      "Video Title": item["Video Title"] === "" ? "-" : item["Video Title"],
      "Video Index": item["Video Index"] === "" ? "-" : item["Video Index"],
      "Total Duration(Min)": item["Total Duration"] === "" ? "-" : (() => {
        const durationInSeconds = moment.duration(item["Total Duration"], 'seconds');
        const minutes = Math.floor(durationInSeconds.asMinutes());
        const seconds = durationInSeconds.seconds();
        return `${minutes} min ${seconds} sec`;
      })(),
      "Watched Duration(Min)": item["Watched Duration"] === ""
        ? "-"
        : (() => {
          const durationInSeconds = moment.duration(item["Watched Duration"], 'seconds');
          const minutes = Math.floor(durationInSeconds.asMinutes());
          const seconds = durationInSeconds.seconds();
          return `${minutes} min ${seconds} sec`;
        })(),
      "Watched Percentage": item["Watched Percentage"] === "" ? "-" : item["Watched Percentage"],
      "Watched At": moment(item["Watched At"]).tz("America/Chicago").format("MM/DD/YYYY HH:mm")
    }));

    setsubscriberExportCsv(modifiedData);
  }, [exportDetails]);

  useEffect(() => {
    const modifiedData1 = videoDetails.map((item, index) => ({
      "S.No": index + 1,
      "Video Title": item["Video Title"] === "" ? "-" : item["Video Title"],
      "Video Index": item["Video Index"] === "" ? "-" : item["Video Index"],
      "Watched Count": item["Watched Count"] === "" ? "-" : item["Watched Count"],

    }));

    setsubscriberVideoCsv(modifiedData1);
  }, [videoDetails]);

    return (
        <>
            <div className="d-flex justify-content-center">

{downloading ? <p className="mb-0 pe-4" style={{ color: "grey" }}>Processing...</p>
  : <>
    <button type="button"
      className="btn btn-primary btn-lg w-auto d-flex px-4 me-2 fw-medium col-3">
      <CSVLink
        data={subscriberExportCsv}
        ref={csvDownloadRef}
        filename="fcn_video_watched_by_users.csv"
        className="cursor-pointer text-white"
      >Video Watched By Users CSV</CSVLink></button></>}
{downloading ? <p className="mb-0 pe-4" style={{ color: "grey" }}>Processing...</p>
  : <>
    <button type="button"
      className="btn btn-primary btn-lg w-auto d-flex px-4 fw-medium col-3">
      <CSVLink
        data={subscriberVideoCsv}
        ref={csvDownloadRef}
        filename="fcn_video_watched_count.csv"
        className="cursor-pointer text-white"
      >Video Watched Count CSV </CSVLink></button></>}
</div>
        </>
    );
}
