import { useState } from "react";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.cancerType) {
      formErrors.cancerType = "Please select cancerType";
      isFormValid = false;
    } else {
      if (fields.cancerType.length <= 0) {
        formErrors.cancerType = "Please select cancerType";
        isFormValid = false;
      }
    }
    
    if (!fields.tags && !fields.tagGroup) {
      formErrors.tagGroup = "Either tag or tag group are required";
      isFormValid = false;
    } else {
      let tagLength=0, tagGroupLength=0
      if(fields.tags)
        tagLength= fields.tags.length;
      if(fields.tagGroup)
        tagGroupLength= fields.tagGroup.length;

      if (tagLength <= 0 && tagGroupLength<= 0) {
        formErrors.tagGroup = "Either tag or tag group are required";
        isFormValid = false;
      }
    }

    if (!fields.country) {
      formErrors.country = "Please select country";
      isFormValid = false;
    } else {
      if (fields.country.length <= 0) {
        formErrors.country = "Please select country";
        isFormValid = false;
      }
    }
    if (!fields.ageGroup) {
      formErrors.ageGroup = "Please select age group";
      isFormValid = false;
    } else {
      if (fields.ageGroup.length <= 0) {
        formErrors.ageGroup = "Please select age group";
        isFormValid = false;
      }
    }
    if (!fields.priority) {
      formErrors.priority = "Please enter priority";
      isFormValid = false;
    } else {
      if (fields.priority > 1000 || fields.priority < 1) {
        formErrors.priority = "Please enter value between 1 to 1000";
        isFormValid = false;
      }
    }
    if (!fields.videoIndex) {
      formErrors.videoIndex = "Please enter video index";
      isFormValid = false;
    }
    
    if (!fields.race) {
      formErrors.race = "Please select ethnicity";
      isFormValid = false;
    } else {
      if (fields.race.length <= 0) {
        formErrors.race = "Please select ethnicity";
        isFormValid = false;
      }
    }
    if (!fields.videoTitle) {
      formErrors.videoTitle = "Please enter video title";
      isFormValid = false;
    }
    else
    {
      if (fields.videoTitle.trim().length === 0) 
      {
        formErrors.videoTitle = "Please enter video title";
        isFormValid = false;
      }
         
    }
    if (!fields.videoDescription) {
      formErrors.videoDescription = "Please enter video description";
      isFormValid = false;
    }
    else
    {
      if (fields.videoDescription.trim().length === 0) 
      {
        formErrors.videoDescription = "Please enter video description";
        isFormValid = false;
      }
         
    }
    if (!fields.isEpisode) {
      formErrors.isEpisode = "Please select Episode type";
      isFormValid = false;
    }

    if (!fields.imageSourceName) {
      formErrors.imageSourceName = "Please select thump image";
      isFormValid = false;
    }
    if (!fields.videoSourceName) {
      formErrors.videoSourceName = "Please select video";
      isFormValid = false;
    }
    if (!fields.gender) {
      formErrors.gender = "Please select gender";
      isFormValid = false;
    } else {
      if (fields.gender.length <= 0) {
        formErrors.gender = "Please select gender";
        isFormValid = false;
      }
    }
    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};
