import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import PaginationElements from "../../elements/pagination";
import { api, common } from "../../helpers";
import Table from "../../elements/Table";

export default function TagsList(props) {
  //inti
  const [tagsList, settagsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [itemSize, setItemSize] = useState(10);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagingCounter, setPagingCounter] = useState(0);

  const tableHeader = ["#", "Tag Name", "Status", " "];
  const tableColumn = ["tagName", "status"];
  const init = useRef(true);

  useEffect(() => {
    getTagsList();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getTagsList();
  }, [search]);

  //handlers
  const handlePageChange = (page) => {
    getTagsList("");
    setCurrentPage(page);
  };

  const handleFilterClick = (event) => {
    init.current = false;
    setCurrentPage(1);
    getTagsList();
  };
  const deleteItem = function (id) {
    let data = {
      url: "tagsDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getTagsList();
      }
    });
  };

  const handleFilterClear = (event) => {
    init.current = true;
    getTagsList("");
    setActive("");
    setCurrentPage(1);
  };

  //API
  const getTagsList = () => {
    setLoading(true);
    let userStatus = "";
    if (!init.current) {
      userStatus = Boolean(active.length) ? `&status=${active}` : "";
    }

    let data = {
      url: "tagsList",
      method: "GET",
      query: `?limit=${itemSize}&page=${currentPage}&sortBy=createdAt&sortDir=desc&search=${search}${userStatus}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        settagsList(res?.data?.data?.results);
        setTotalRecord(res?.data?.data?.totalResults);
        setItemSize(res?.data?.data?.limit);
        setPagingCounter(res?.data?.data?.pagingCounter);
        if (res?.data?.data?.results.length === 0) setCurrentPage(1);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-4">
          <div class="col-sm-6 d-flex">
            <h5 class="fw-medium mb-0 align-self-center">Tags</h5>
          </div>
          <div class="col-sm-6">
            <div class="d-flex justify-content-end align-self-center">
              <div class="input-group input-group-sm w-50 me-3">
                <span
                  class="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i class="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  class="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Link
                to="/tags-add"
                class="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Tags
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 rounded-1 h-100">
              <div className="d-flex justify-content-end align-self-center p-3">
                <select
                  className="form-select form-select-sm w-auto bg-transparent text-white border-0 me-2 xs-small"
                  aria-label="Default select example"
                  defaultValue={""}
                  value={active || ""}
                  onChange={(e) => setActive(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>

                <div>
                  <button
                    className="btn btn-muted py-0 px-2 fs-5"
                    type="button"
                    onClick={handleFilterClick}
                  >
                    <i className="fal fa-filter me-1"></i>
                    Filter
                  </button>
                </div>

                <div className="ms-2">
                  <button
                    className="btn btn-muted py-0 px-2 fs-5 "
                    type="button"
                    onClick={handleFilterClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={tagsList}
                  type={"tags"}
                  onDelete={deleteItem}
                  activePage={currentPage}
                  loading={loading}
                />
              </div>
              <hr />
              <div class="d-flex justify-content-between mt-3">
              {totalRecord>0?
                <p class="mb-0 align-self-center xs-small">
                  Showing {pagingCounter} to{" "}
                  {pagingCounter > 0
                    ? pagingCounter + (tagsList.length - 1)
                    : pagingCounter}{" "}
                  of {totalRecord} entries
                </p>:null}
                <div className="d-flex flex-row-reverse">
                  {totalRecord > itemSize && (
                    <PaginationElements
                      activePage={currentPage}
                      TotalRecord={totalRecord}
                      itemSize={itemSize}
                      handlePageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

