import { useState } from "react";
import validator from 'validator';


export const ValidateForm = (fields) => {
    const [errors, setErrors] = useState([]);

    const handleFormSubmit = (fields) => {
        return validateForm(fields);
    };

    const handleQuestionSubmit = (fields) => {
        return validateQuestionData(fields);
    };

    const validateQuestionData = (data) => {
        let isFormValid = true;
        const formErrors = [{}];
        let inputAnswerList = data.options;
        if (validator.isEmpty(data.question)) {
            formErrors.requiredFields = "Please fill all required values";
            isFormValid = false;
        }

        if (validator.isEmpty(data.questionType)) {
            formErrors.requiredFields = "Please fill all required values";
            isFormValid = false;
        }

        if (data.displayOrder === 0 || data.displayOrder === "0" || data.displayOrder === "" || data.displayOrder === undefined) {
            formErrors.requiredFields = "Please fill all required values";
            isFormValid = false;
        }

        inputAnswerList.forEach(obj => {

            if (obj.tagGroups.length <= 0 && obj.otherTags.length <= 0) {
                formErrors.TagsError = "Either Tags or Tag Groups are required";
                isFormValid = false;
            }

            Object.entries(obj).forEach(([key, value]) => {
                if (key === "answer" && data.questionType!=="textbox") {
                    if (validator.isEmpty(value)) {
                        formErrors.requiredFields = "All fields are mandatory! Please fill all required values";
                        isFormValid = false;
                    }
                }
                else if (key === "displayOrder" && (value === 0 || value === "0" || value === "" || value === undefined)) {
                    formErrors.requiredFields = "Please fill all required values";
                    isFormValid = false;
                }
                else {

                }
            });
        });

        const unique = new Set();
        const DuplicateDisplayOrder = inputAnswerList.some(element => unique.size === unique.add(element.displayOrder).size);
        if (DuplicateDisplayOrder) {
            formErrors.displayOrder = "Some answers are having duplicate display order";
            isFormValid = false;
        }
        const uniqueAnswer = new Set();
        const DuplicateAnswer = inputAnswerList.some(element => uniqueAnswer.size === uniqueAnswer.add(element.answer).size);
        if (DuplicateAnswer && data.questionType!=="textbox") {
        formErrors.duplicateAnswer = "Question having duplicate answer";
        isFormValid = false;
        }
        setErrors(formErrors);
        let output = {
            "isFormValid": isFormValid,
            "formErrors": formErrors
        }

        return output;

    };

    const validateForm = (data, cancerType, userType) => {
        let isFormValid = true;
        const formErrors = [{}];
        let inputQuestionList = data;
        const unique = new Set();
        const DuplicateDisplayOrder = inputQuestionList.some(element => unique.size === unique.add(element.displayOrder).size);
        if (DuplicateDisplayOrder) {
            formErrors.displayOrder = "Some questions are having duplicate display order";
            isFormValid = false;
        }
        const uniqueQuestion = new Set();
        const DuplicateQuestion = inputQuestionList.some(element => uniqueQuestion.size === uniqueQuestion.add(element.question).size);
        if (DuplicateQuestion) {
        formErrors.duplicateQuestions = "Found some duplicate questions";
        isFormValid = false;
        }
        setErrors(formErrors);
        let output = {
            "isFormValid": isFormValid,
            "formErrors": formErrors
        }

        return output;

    };

    return {
        errors,
        handleQuestionSubmit,
        handleFormSubmit
    };
};
