import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const GlossaryDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [glossaryDetails, setGlossaryDetails] = useState([]);
  // init
  const [activeCheck, setActiveCheck] = useState();

  useEffect(() => {
    getGlossaryDetails();
  }, []);

  //API
  const getGlossaryDetails = () => {
    let data = {
      url: "glossaryGet",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setGlossaryDetails(res?.data?.data);
        setActiveCheck(res?.data?.data?.status);
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/glossary/edit/${id}`);
  };

  const statusChange = function (id) {
    let data = {
      url: "glossaryStatusUpdate",
      method: "PUT",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getGlossaryDetails();
        // alert("changes");
      }
    });
  };

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-3">
          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <Link to="/glossary" class="text-white">
                    Glossary Details{" "}
                  </Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {glossaryDetails.glossaryName}
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <Link
              to="/glossary"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 p-4 rounded-1 h-100">
              <div className=" row justify-content d-flex  ">
                <h5 class="fw-medium w-50">Description</h5>
                <div className="d-flex justify-content-end w-50 ">
                  Is Active
                  <div class="form-check form-switch d-flex align-items-center ms-2">
                    <input
                      class="form-check-input py-2"
                      type="checkbox"
                      role="switch"
                      name="status"
                      id="flexSwitchCheckChecked"
                      checked={activeCheck}
                      onChange={(e) => {
                        // statusChange(id);
                        let message =
                          activeCheck === false
                            ? "Are you sure you wish to active this glossary?"
                            : "Are you sure you wish to inactive this glossary?";
                        var answer = window.confirm(message);
                        if (answer) {
                          statusChange(id);
                          setActiveCheck(!activeCheck);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <p
                class="fs-6 mb-0 fw-normal"
                dangerouslySetInnerHTML={{
                  __html: glossaryDetails?.description,
                }}
              ></p>
              {glossaryDetails.type ? <>
                <h5 class="fw-medium w-50">Type</h5>
                <div> {glossaryDetails.type} <br/></div></> : "" 
              }
              <div class="col-lg-12 mb-3">
                <div class="col-lg-4 mb-3">
                  {glossaryDetails.videoUrlLink ? (
                    <div style={{ height: "150px", minHeight: "170px" }}>
                      <ReactPlayer
                        url={glossaryDetails.videoUrlLink}
                        light={glossaryDetails?.thumbnailUrlLink}
                        playing
                        controls={true}
                        className="react-player"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ) :
                    glossaryDetails.imageUrlLink ? <div style={{ height: "150px", minHeight: "170px" }}>
                      <input type="image" src={glossaryDetails.imageUrlLink} alt="" style={{ height: "150px", minHeight: "170px" }}></input>
                    </div> : " "}
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default GlossaryDetails;
