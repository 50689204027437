
import axios from 'axios';
import React from 'react'
import { api, common, services } from '../../helpers';

const logout = (onSuccess) => {
    const refreshToken = localStorage.getItem("refresh-token")

    let payload = {
        refreshToken: refreshToken
    };

    let data = {
        url: "authLogout",
        method: "POST",
        body: payload,
    };
    
    api
        .call(data, (res) => {
            if(res.status === 200){
               onSuccess()
            }
        }).catch(err => {
            common.notify("E", err.response.data.message);
        })

}

export default logout

