import React, { useEffect, useRef, useState } from "react";
import { api, common } from "../../helpers";
import { CSVLink } from "react-csv";

export default function NotesAnalytics(props) {

    const [subscriberExportCsv, setsubscriberExportCsv] = useState([]);
    const csvDownloadRef = useRef(null);
    const [subscriberReport, setSubscriberReport] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [exportDetails, setExportDetails] = useState([
        {
            "S.No": "",
            "First Name": "",
            "Last Name": "",
            "Email": "",
            "User Type": "",
            "Cancer Type": "",
            "Notes Count": "",
        },

    ]);
    const csvData = () => {
        setDownloading(true);
        let data = {
            url: "notesCSV",
            method: "GET",
        };
        api
            .call(data, (res) => {
                if (res.status === 200) {
                    setSubscriberReport(res?.data?.data);
                    setDownloading(false);
                } else {
                    common.notify("E", res.data.message);
                    setDownloading(false);
                }
            })
            .catch((err) => {
                common.notify("E", err);
            });
    };

    useEffect(() => {
        csvData();
    }, []);

    useEffect(() => {
        if (subscriberReport?.length > 0) {
            setExportDetails(
                subscriberReport?.map(({ userId, totalCount, ...rest }) => rest)
            );
        }
    }, [subscriberReport]);

    useEffect(() => {
        const modifiedData = exportDetails.map((item, index) => ({
            "S.No": index + 1,
            "First Name": item["First Name"] === "" ? "-" : item["First Name"],
            "Last Name": item["Last Name"] === "" ? "-" : item["Last Name"],
            "Email": item["Email"] === "" ? "-" : item["Email"],
            "User Type": item["User Type"] === "" ? "-" : item["User Type"],
            "Cancer Type": item["Cancer Type"] === "" ? "-" : item["Cancer Type"],
            "Notes Count": item["Notes Count"] === "" ? "-" : item["Notes Count"],
        }));

        setsubscriberExportCsv(modifiedData);
    }, [exportDetails]);

    return (
        <>
            <div className="d-flex justify-content-center">
                {downloading ? <p className="mb-0 pe-4" style={{ color: "grey" }}>Processing...</p>
                    : <>
                        <button type="button"
                            className="btn btn-primary btn-lg w-auto d-flex px-4 fw-medium col-3">
                            <CSVLink
                                data={subscriberExportCsv}
                                ref={csvDownloadRef}
                                filename="notes_analytics.csv"
                                className="cursor-pointer text-white"
                            >Notes CSV </CSVLink></button></>}
            </div>
        </>
    );
}
