import React, { useEffect, useState } from "react";
import { api } from "../../helpers";
import validator from 'validator';
import moment from 'moment';
import SessionDurationAnalytics from "../Analytics/sessionDurationAnalytics";
import MyChart from "../../helpers/chart";
import { ThreeDots } from "react-loader-spinner";

export default function DashboardMain(props) {

  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [totalUser, setTotalUsers] = useState("");
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [errors, setErrors] = useState("");
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  const initialStartDate = currentDate.toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [selectedTime, setSelectedTime] = useState("30days");
  const [videoGraphs, setVideoGraph] = useState([]);
  const [sessionGraphs, setSessionGraph] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    getDashboardDetails(startDate, endDate);
    getTotalUsers();
  }, []);

  useEffect(() => {
    if (selectedTime) {
      videoGraph(selectedTime);
      sessionGraph(selectedTime);
    }
  }, [selectedTime]);

  const getDashboardDetails = (startDate, endDate) => {
    let fd = startDate + " 00:00:00";
    let ed = endDate + " 23:59:59";
    let fromDate = moment(fd).utc().format();
    let toDate = moment(ed).utc().format();
    let data = {
      url: "dashboardDetails",
      method: "GET",
      query: `?startDate=${fromDate}&endDate=${toDate}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setDashboardDetails(res?.data?.data);

      }
    });
  };

  const videoGraph = (selectedTime) => {
    setLoading1(true);
    let data = {
      url: "videoGraph",
      method: "GET",
      query: `?timeRange=${selectedTime}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        console.log(res, "res");
        setVideoGraph(res?.data?.data)
        setLoading1(false);
      }
    });
  };

  const sessionGraph = (selectedTime) => {
    setLoading2(true);
    let data = {
      url: "sessionGraph",
      method: "GET",
      query: `?timeRange=${selectedTime}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        console.log(res, "res");
        setSessionGraph(res?.data?.data)
        setLoading2(false);
      }
    });
  };

  const getTotalUsers = () => {
    let data = {
      url: "dashboardUsersTotal",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        console.log(res, "res");
        setTotalUsers(res?.data?.data);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateDate()) {
      getDashboardDetails(startDate, endDate);
    }
    // else {
    //   common.notify("E", "please select valid dates");
    // }
  };

  const validateDate = () => {
    let formErrors = {};
    let isFormValid = true;

    if (validator.isEmpty(startDate)) {
      formErrors.startDate = "Please enter start date";
      isFormValid = false;
    }
    

    if (validator.isEmpty(endDate)) {
      formErrors.endDate = "Please enter end date";
      isFormValid = false;
    }
  
    if (startDate > endDate) {
      console.log(startDate > endDate,"boolean");
      formErrors.startDate = "Start date should be less than end date";
      console.log(formErrors.startDate);
      isFormValid = false;
    }

    setErrors(formErrors);
    return isFormValid;
  }
  return (
    <>
      <div className="container-fluid p-3 overflow-auto h-100">
        <div className="row">
          <div className="col-xl-4  col-12">
            <h5 className="fw-medium mb-0">Dashboard</h5>
          </div>
          <div className="col-xl-8  col-12 d-flex justify-content-xl-end justify-content-start pe-lg-2 pe-0">
            <div className="d-flex mt-xl-0 mt-3">
              <select
                className="form-select form-select-sm w-auto bg-transparent text-white border-0 me-3"
                style={{ fontSize: "20px" }}
                aria-label="Default select example"
                name="cancerType"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                <option value="30days">30 Days</option>
                <option value="3months">3 Months</option>
                <option value="6months">6 Months</option>
              </select>
             
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 mb-4">
            <div className="bg-muted-50 rounded-1 p-3 position-relative">
              <h5 className="fw-medium">Video Analytics</h5>
              {loading1 ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#83BB41"
                  ariaLabel="Loading Copmanies list"
                  wrapperStyle={{ position: "absolute", left: "48%" }}
                  wrapperClassName=""
                  visible={true}
                />) : (
                <MyChart
                  graphData={videoGraphs}
                  color="#0066ff"
                  label="Video" />)}
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="bg-muted-50 rounded-1 p-3 position-relative">
              <h5 className="fw-medium">Session Count</h5>
              {loading2 ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#83BB41"
                  ariaLabel="Loading Copmanies list"
                  wrapperStyle={{ position: "absolute", left: "48%" }}
                  wrapperClassName=""
                  visible={true}
                />) : (
                <MyChart
                  graphData={sessionGraphs}
                  label="Session"
                  color="#ff0000" />)}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-xl-8 mb-3">
            <div className="bg-muted-50 rounded-1 p-3">
              <div className="d-flex justify-content-between flex-column flex-lg-row align-items-lg-center align-items-start py-2">
                <div className="d-flex bg-dark h-4 active text-white p-3 align-items-center border-0 rounded mb-2 mb-lg-0">
                  Total Users: {totalUser}</div>
                <div className="d-flex justify-content-between flex-lg-row">
                  <div className="d-flex">
                    <label for="date" className="form-label pt-2">From</label>
                    <input type="date" className="form-control bg-black text-white py-1 ms-3" id="date"
                      value={startDate} max={currentDate}
                      name="startDate" onChange={(e) => setStartDate(e.target.value)} />
                  </div>
                  <p className="error-field">{errors.startDate}</p>

                  <div className=" d-flex">
                    <label for="date" className="form-label pt-2 ms-3">To</label>
                    <input type="date" className="form-control bg-black text-white py-1 ms-3" id="date"
                      value={endDate} max={currentDate}
                      name="endDate" onChange={(e) => setEndDate(e.target.value)} />

                  </div>
                  <p className="error-field">{errors.endDate}</p>
                  <button className="btn btn-primary text-white fw-semibold ms-3 py-1" type="button" onClick={onSubmit}>Generate</button>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-white">
                    <div className="d-flex justify-content-between pb-2">
                      <span>
                        <iconify-icon icon="circum:user" class="h4 mb-0 align-middle text-primary"></iconify-icon>
                      </span>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.users}</h5>
                      </div>
                    </div>
                    <small className="text-black">
                      New User Signups</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-purple-25">
                    <div className="d-flex justify-content-between pb-2">
                      <span>
                        <iconify-icon icon="akar-icons:video"
                          class="h4 mb-0 align-middle text-purple">
                        </iconify-icon></span>

                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.videos}</h5>
                      </div>
                    </div>
                    <small className="text-black">No. of Videos Uploaded</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-danger-25">
                    <div className="d-flex justify-content-between pb-2">
                      <iconify-icon icon="material-symbols:inbox-outline"
                        class="h4 mb-0 align-middle text-danger"></iconify-icon>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.ads}</h5>
                      </div>
                    </div>
                    <small className="text-black">No. of Advertisement Added</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-primary-25">
                    <div className="d-flex justify-content-between pb-2">
                      <iconify-icon icon="material-symbols:chat-outline-rounded"
                        class="h4 mb-0 align-middle text-primary"></iconify-icon>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.glossary}</h5>
                      </div>
                    </div>
                    <small className="text-black">No. of Glossaries Added</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-primary-25">
                    <div className="d-flex justify-content-between pb-2">
                      <iconify-icon icon="icon-park-outline:setting-config"
                        class="h4 mb-0 align-middle text-primary"></iconify-icon>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.userTypes}</h5>
                      </div>
                    </div>
                    <small className="text-black">No. of Users Types</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <div className="mb-lg-0 mb-3 status bg-warning-25">
                    <div className="d-flex justify-content-between pb-2">
                      <iconify-icon icon="icon-park-outline:setting-config"
                        class="h4 mb-0 align-middle text-warning"></iconify-icon>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.cancerTypes}</h5>
                      </div>
                    </div>
                    <small className="text-black">No. of Cancer Types</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-lg-0 mb-3 status bg-purple-25">
                    <div className="d-flex justify-content-between pb-2">
                      <span>
                        <iconify-icon icon="icon-park-outline:setting-config"
                          class="h4 mb-0 align-middle text-purple">
                        </iconify-icon>
                      </span>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.tagGroups}</h5>
                      </div>
                    </div>
                    <small className="text-black">
                      No. of Tag Groups</small>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-lg-0 mb-3 status bg-danger-25">
                    <div className="d-flex justify-content-between pb-2">
                      <span>
                        <iconify-icon icon="icon-park-outline:setting-config"
                          class="h4 mb-0 align-middle text-danger"></iconify-icon>
                      </span>
                      <div className="text-black">
                        <h5 className="mb-0 fw-bold">{dashboardDetails.tags}</h5>
                      </div>

                    </div>
                    <small className="text-black">
                      No. of Tags</small>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="text-center col-xl-4 col-12 ">
            <div className="bg-muted-50 rounded-1 p-3">
              <div className="rounded-1 border border-white">
                <table className="table mb-0">
                  <thead>
                    <tr >
                      <th className="text-white h6 border-end border-white" scope="col">Cancer Type</th>
                      <th className="text-white h6 border-end border-white" scope="col">User Type</th>
                      <th className="text-white h6 border-white" scope="col">No of questions</th>
                    </tr>
                  </thead>
                  <tbody >
                    {dashboardDetails?.onBoardingQuestions?.length > 0 ?
                      dashboardDetails?.onBoardingQuestions.map((item) => (
                        <tr className="dash-border">
                          <th scope="row" className="border-end border-bottom-0 border-white"> {item.cancerType}</th>
                          <th scope="row" className="border-end border-bottom-0 border-white"> {item.userType}</th>
                          <td className="border-0">{item.count}</td>
                        </tr>
                      )) :
                      <tr className="border-0">
                        <th scope="row" className="border-0 border-bottom-0 border-white" colSpan="3">
                          No Data Found!
                        </th>
                      </tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

