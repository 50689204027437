import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common, FormValidation, config } from "../../helpers";
import { ValidateForm } from "./adsValidation";
import Select from "react-select";
import Aws from "../../helpers/aws";
import ModalPopup from "../../elements/Modal";

const AdsEdit = () => {
  // init
  const navigate = useNavigate();
  let { id } = useParams();
  const imageRef = useRef();

  // state
  const [loading, setLoading] = useState(false);
  const [videosList, setVideosList] = useState([]);
  const [newImageSource, setNewImageSource] = useState([]);
  const [dragImageActive, setDragImageActive] = useState([]);
  const [imageTokenDetails, setImageTokenDetails] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const { fields, setValue, setMultiValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    setValue({ displayOrder: 0 });
    getVideos();
    getAdDetails();
  }, []);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  // handle drag events

  // handle Image drag events
  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };

  const handleImageDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFileValidation(e.dataTransfer.files[0], "image", "");
    }
  };

  const handleImageFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      checkFileValidation(e.target.files[0], "image", "");
    }
  };

  //Normal Creat

  const getAdDetails = () => {
    let data = {
      url: "adsView",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setMultiValue({
          displayDuration: res?.data?.data?.displayDuration,
          displayOrder: res?.data?.data?.displayOrder,
          displayPosition: res?.data?.data?.displayPosition,
          link: res?.data?.data?.link,
          priority: res?.data?.data?.priority,
          title: res?.data?.data?.title,
          type: res?.data?.data?.type,
          videos: res?.data?.data?.videos,
          image: res?.data?.data?.image,
        });
        setImageTokenDetails(res?.data?.data?.image);
        // setValue("image", res?.data?.data?.image);
        setNewImageSource(res?.data?.data?.image);
      }
    });
  };

  let isBackgroundImageUpload = "";

  //Normal Creat
  const uploadImageFile = async (file, type) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "backgroundImage") {
      fileName = imageTokenDetails.fileName;
      credentials = imageTokenDetails.credentials;
    }
    const params = {
      Bucket: config.s3_video_Glossary_bucket,
      Key: config.s3_ads_type_folder + fileName,

      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };

    const BUCKET = Aws.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );

    BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
      })
      .send(function (err, data) {
        if (err) {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          }
          if (isBackgroundImageUpload === "true") {
            adUpdate();
          } else {
            setLoading(false);
            common.notify("E", err);
            setIsSubmit(false);
          }
        } else {
          if (type === "backgroundImage") {
            isBackgroundImageUpload = "true";
          }
          if (isBackgroundImageUpload === "true") {
            adUpdate();
          }
        }
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);

    if (valid) {
      setIsSubmit(true);
      await uploadImageFile(newImageSource, "backgroundImage");
      // adUpdate();/
    } else {
      window.scrollTo(0, 0);
    }
  };

  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    if (type === "image") {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "image",
      };
    }
    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          if (type === "image") {
            setImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewImageSource(file);
            setValue("image", file.name);
          }
        } else {
          common.notify("E", type + ":  " + res.data.message);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", type + ":  " + err);
      });
  };
  const removeLabel = (listExtract) => {
    return listExtract.map((item) => item.value);
  };
  const adUpdate = async () => {
    let body = {
      type: fields.type,
      title: fields.title,
      image: imageTokenDetails?.fileName || imageTokenDetails,
      link: fields.link,
      displayDuration: fields.displayDuration,
      displayPosition: fields.displayPosition,
      priority: fields.priority || undefined,
      videos: removeLabel(fields?.videos),
    };
    setLoading(true);
    let data = {
      url: "adsUpdate",
      method: "PUT",
      query: `${id}`,
      body: body,
    };
    api
      .call(data, async (res) => {
        if (res.data.status === "success") {
          navigate("/advertisements");
          common.notify("S", res?.data?.msg);
        } else {
          common.notify("E", res?.data?.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
    setLoading(false);
  };

  const getVideos = () => {
    let data = {
      url: "adsVideoList",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideosList(res?.data?.data);
      }
    });
  };
  const validateImage = (e, file, type) => {
    const reader = new FileReader();
    let isValid = false;
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      let height = config.addadvertisement.backgroundImage.height;
      img.onload = () => {
        if (height == img.height) {
          handleImageFileChange(e);
        } else {
          common.notify(
            "E",
            `Please upload the image with given format and size`
          );
        }
      };
    };
    reader.readAsDataURL(file);
    return isValid;
  };
  function handleSelect(data, field) {
    setValue(field, data);
  }
  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };
  const priorityCheck = [
    "APP_DASHBOARD",
    "VIDEO_DASHBOARD",
    "ALL_PAGES",
    "VIDEO_PLAYER",
  ];
  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Edit Advertisement</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      {/* Add Type */}
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Type <i className="star">*</i>
                          </label>
                          <select
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            name="type"
                            value={fields.type}
                            onChange={(e) => setValue("type", e.target.value)}
                          >
                            <option value="">Select Advertisement Type</option>
                            <option value="APP_DASHBOARD">APP DASHBOARD</option>
                            <option value="VIDEO_DASHBOARD">
                              VIDEO DASHBOARD
                            </option>
                            <option value="VIDEO_DETAIL">VIDEO DETAIL</option>
                            <option value="NOTES">NOTES</option>
                            <option value="GLOSSARY">GLOSSARY</option>
                            <option value="ALL_PAGES">ALL PAGES</option>

                            <option value="VIDEO_PLAYER">VIDEO PLAYER</option>
                          </select>
                        </div>
                        <p className="error-field">{errors.type}</p>
                      </div>
                      {/* Add Title */}
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Advertisement Title <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Advertisement Title"
                            name="title"
                            value={fields.title}
                            min={1}
                            max={100}
                            onChange={(e) => setValue("title", e.target.value)}
                          />
                        </div>
                        <p className="error-field">{errors.title}</p>
                      </div>
                      {/* Add Url */}
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            URL
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter URL"
                            min={1}
                            name="link"
                            value={fields.link}
                            max={100}
                            onChange={(e) => setValue("link", e.target.value)}
                          />
                        </div>
                        {/* <p className="error-field">{errors.priority}</p> */}
                      </div>
                      {/* Add Position */}
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Position <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter advertisement position"
                            min={1}
                            max={100}
                            name="displayPosition"
                            value={fields.displayPosition}
                            onKeyPress={(e) => {
                              numberValidation(e);
                            }}
                            onChange={(e) => {
                              setValue("displayPosition", e.target.value);
                            }}
                          />
                        </div>
                        <p className="error-field">{errors.displayPosition}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      {/* Add Priority */}
                      {priorityCheck.includes(fields.type) && (
                        <div className="col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Priority
                              <i className="star">*</i>
                            </label>
                            <input
                              type="text"
                              id="input1"
                              className="form-control form-control-lg border-0"
                              placeholder="Enter advertisement priority"
                              min={1}
                              name="priority"
                              value={fields.priority}
                              max={100}
                              onKeyPress={(e) => {
                                numberValidation(e);
                              }}
                              onChange={(e) => {
                                setValue("priority", e.target.value);
                              }}
                            />
                          </div>
                          <p className="error-field">{errors.priority}</p>
                        </div>
                      )}
                      {/* Add Display Duration and Video  */}
                      {fields.type == "VIDEO_PLAYER" && (
                        <>
                          <div className="col-sm-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Display Duration <i className="star">*</i>
                              </label>
                              <input
                                type="text"
                                id="input1"
                                className="form-control form-control-lg border-0"
                                placeholder="Enter duration"
                                min={1}
                                max={100}
                                name="displayDuration"
                                value={fields.displayDuration}
                                onKeyPress={(e) => {
                                  numberValidation(e);
                                }}
                                onChange={(e) => {
                                  setValue("displayDuration", e.target.value);
                                }}
                              />
                            </div>
                            <p className="error-field">
                              {errors.displayDuration}
                            </p>
                          </div>

                          <div className="col-sm-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Videos
                                <i className="star">*</i>
                              </label>
                              <Select
                                class="form-select form-select-lg"
                                options={videosList}
                                value={fields.videos}
                                onChange={(event) => {
                                  handleSelect(event, "videos");
                                }}
                                isSearchable={true}
                                isMulti
                              />
                            </div>
                            <p className="error-field">{errors.videos}</p>
                          </div>
                        </>
                      )}
                      {/* Add Thumbnail Image */}
                      {/* Add Thumbnail Image */}
                      <div className="col-sm-12" disabled={isSubmit}>
                        <div
                          className="mb-3"
                          onDrop={(e) =>
                            handleImageDrop(
                              e,
                              e.dataTransfer.files[0],
                              "backgroundImage"
                            )
                          }
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                          disabled={isSubmit}
                        >
                          <label htmlFor="input1" className="form-label">
                            Upload Thumbnail
                            {fields.type != "VIDEO_PLAYER" && (
                              <i className="star">*</i>
                            )}
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area text-center ">
                              <div className="text-secondary xs-small">
                                ( Supported image formats - *.jpg, *.jpeg, *.png
                                )
                                <div className="justify-content-center align-items-center d-flex">
                                  {/* {
                                    config.addadvertisement.backgroundImage
                                      .height
                                  } */}
                                  {"320 * 100"} (Pixels)
                                </div>
                              </div>
                              <div className="my-2">
                                <button
                                  tupe="button"
                                  onClick={() => {
                                    imageRef.current.click();
                                  }}
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button "
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    className="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    newImageSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"
                                  }
                                >
                                  {newImageSource.name || newImageSource
                                    ? newImageSource.name || newImageSource
                                    : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input"
                                  type="file"
                                  multiple
                                  onChange={(e) => {
                                    validateImage(
                                      e,
                                      e.target.files[0],
                                      "backgroundImage"
                                    );
                                  }}
                                  ref={imageRef}
                                  // onChange={(e) => {
                                  //   validateImage(e, e.target.files[0]);
                                  // }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {fields.type != "VIDEO_PLAYER" && (
                          <p className="error-field">{errors.image}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold"
                      data-bs-toggle="modal"
                      data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                      disabled={isSubmit}
                    >
                      {isSubmit ? "Processing..." : "Submit"}
                    </button>
                    <ModalPopup page="advertisements"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsEdit;
