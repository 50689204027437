import { useState } from "react";

export const ValidateForm = (fields) => {
  const [errors, setErrors] = useState([]);

  const handleSubmit = (formFields) => {
    return validateForm(formFields);
  };

  function isNullEmptyBlank(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.type) {
      formErrors.type = "Please select advertisement type";
      isFormValid = false;
    }

    if (
      fields.type == "APP_DASHBOARD" ||
      fields.type == "VIDEO_DASHBOARD" ||
      fields.type == "ALL_PAGES"
    ) {
      if (!fields.priority) {
        formErrors.priority = "Please enter priority";
        isFormValid = false;
      } else {
        if (fields.priority > 100 || fields.priority < 1) {
          formErrors.priority = "Please enter value between 1 to 100";
          isFormValid = false;
        }
      }
    } else if (fields.type == "VIDEO_PLAYER") {
      if (!fields.priority) {
        formErrors.priority = "Please enter priority";
        isFormValid = false;
      } else {
        if (fields.priority != 1) {
          formErrors.priority = "Please enter value 1 ";
          isFormValid = false;
        }
      }
    }
    if (!fields.displayPosition) {
      formErrors.displayPosition = "Please enter position";
      isFormValid = false;
    } else {
      if (fields.type == "APP_DASHBOARD") {
        if (fields.displayPosition > 3 || fields.displayPosition < 1) {
          formErrors.displayPosition = "Please enter value between 1 to 3";
          isFormValid = false;
        }
      }
      if (fields.type == "VIDEO_DASHBOARD") {
        if (fields.displayPosition > 5 || fields.displayPosition < 1) {
          formErrors.displayPosition = "Please enter value between 1 to 5";
          isFormValid = false;
        }
      }
      if (fields.type == "VIDEO_DETAIL") {
        if (fields.displayPosition > 2 || fields.displayPosition < 1) {
          formErrors.displayPosition = "Please enter value 1 or 2";
          isFormValid = false;
        }
      }
      if (fields.type == "NOTES") {
        if (fields.displayPosition != 1) {
          formErrors.displayPosition = "Please enter value 1 ";
          isFormValid = false;
        }
      }
      if (fields.type == "VIDEO_PLAYER") {
        if (fields.displayPosition != 1) {
          formErrors.displayPosition = "Please enter value 1 ";
          isFormValid = false;
        }
      }
    }
    if (fields.type == "VIDEO_PLAYER") {
      if (!fields.displayDuration) {
        formErrors.displayDuration = "Please enter video duration";
        isFormValid = false;
      }
    }
    if (fields.type == "VIDEO_PLAYER") {
      if (!fields.videos) {
        formErrors.videos = "Please select videos";
        isFormValid = false;
      } else {
        if (fields.videos.length <= 0) {
          formErrors.videos = "Please select videos";
          isFormValid = false;
        }
      }
    }
    if (!fields.title || isNullEmptyBlank(fields.title)) {
      formErrors.title = "Please enter advertisement title";
      isFormValid = false;
    }
    // else if (fields.title[0] == " ") {
    //   formErrors.title = "Please remove space   ";
    //   isFormValid = false;
    // }
    if (fields.type != "VIDEO_PLAYER") {
      if (!fields.image) {
        formErrors.image = "Please select thump image";
        isFormValid = false;
      }
    }
    setErrors(formErrors);
    return isFormValid;
  };

  return {
    errors,
    handleSubmit,
  };
};
