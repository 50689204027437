import Pagination from "react-js-pagination";
const PaginationElements = ({
  activePage,
  TotalRecord,
  itemSize,
  handlePageChange,
}) => {
  return (
    <div className="pagination mb-0">
      <Pagination
        aria-label="Page navigation example"
        itemClass="page-item"
        linkClass="page-link"
        prevPageText="Prev"
        nextPageText="Next"
        firstPageText="First"
        lastPageText="Last"
        activePage={activePage}
        itemsCountPerPage={itemSize}
        totalItemsCount={TotalRecord}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default PaginationElements;
