import React, { useEffect, useState } from "react";
import { api, common } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ValidateForm } from "./validation";
import ModalPopup from '../../elements/Modal';

export default function QuestionsAdd(props) {

    const navigate = useNavigate();
    const [cancerList, setCancerList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);
    const [cancerType, setCancerType] = useState('');
    const [userType, setUserType] = useState('');
    const [tagGroupList, setTagGroupList] = useState([]);
    const [tagsList, setTagsList] = useState('');
    const [accordion, setAccordion] = useState(false);
    const { handleQuestionSubmit, handleFormSubmit } = ValidateForm();
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [showQuestionErrorNotification, setShowQuestionErrorNotification] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [error, setError] = useState([]);
    const [inputQuestionList, setInputQuestionList] = useState([{
        question: "",
        questionType: "",
        options: [{
            answer: "",
            otherTags: [],
            tagGroups: [],
            responseMsg: "",
            displayOrder: 0,
            status: true
        }],
        displayOrder: 0,
        status: true
    }
    ]);


    useEffect(() => {
        getCancerList();
        getUserTypeList();
        getTagGroupList();
        getTagList();
    }, []);

    useEffect(() => {
    }, [showErrorNotification, showQuestionErrorNotification]);

    useEffect(() => {
        if (isFormSubmitted) {
            if (cancerType !== "" && userType !== "") {
                setShowQuestionErrorNotification(false);
                setShowErrorNotification(false);
                setError([]);
            }
            else {
                let formErrors = {};
                if (cancerType === "") {
                    formErrors.cancerType = "Please select cancer type";
                }
                if (userType === "") {
                    formErrors.userType = "Please select user type";
                }
                setShowQuestionErrorNotification(true);
                setError(formErrors);
            }
        }
    }, [cancerType, userType]);

    const getTagList = () => {
        setTagsList([]);
        let data = {
            url: "tagsSelect",
            method: "GET",
            query: `?pagination=false`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                let tags=res?.data?.data;
                const options = [{ label: "All", value: "all" }, ...tags];
                setTagsList(options);
            }
        });
    };

    const getCancerList = () => {
        let data = {
            url: "cancerSelect",
            method: "GET",
            query: `?pagination=false`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                setCancerList(res?.data?.data);
            }
        });
    };

    const getUserTypeList = () => {
        let data = {
            url: "userTypeSelect",
            method: "GET",
            query: `?pagination=false`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                setUserTypeList(res?.data?.data);
            }
        });
    };

    const getTagGroupList = () => {
        let data = {
            url: "tagGroupSelect",
            method: "GET",
            query: `?pagination=false`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                let group=res?.data?.data;
                const options = [{ label: "All", value: "all" }, ...group];
                setTagGroupList(options);
            }
        });
    };

    const removeLabel = (listExtract) => {
        return listExtract.map((item) => item.value);
    };

    const handleAnswerInputChange = (e, index, fieldName, questionIndex) => {
        let value = "";
        if (fieldName === "tagGroups" || fieldName === "otherTags") {
            value = e;
        }
        else
            value = e.target.value;

        let options = inputQuestionList[questionIndex].options[index];
        options[fieldName] = value;
        //updating answer in corresponding question's answers array
        const Qlist = [...inputQuestionList];
        Qlist[questionIndex].options[index] = options;
        setInputQuestionList(Qlist);
    };

    const handleQuestionInputChange = (value, index, fieldName) => {
        const list = [...inputQuestionList];
        list[index][fieldName] = value;
        setInputQuestionList(list);
    };

    const handleRemoveAnswerClick = (questionIndex, answerIndex) => {
        const list = [...inputQuestionList[questionIndex].options];
        list.splice(answerIndex, 1);
        const Qlist = [...inputQuestionList];
        Qlist[questionIndex].options = list;
        setInputQuestionList(Qlist)

        //setInputAnswerList(list);
    };

    const handleQuestionAddClick = () => {

        let lastQuestion = inputQuestionList[inputQuestionList.length - 1];
        let response = handleQuestionSubmit(lastQuestion);
        if (!response.isFormValid) {
            debugger;
            setShowErrorNotification(true);
            setError(response.formErrors);
        }
        else {
            setAccordion(true);
            setShowErrorNotification(false);
            setInputQuestionList([...inputQuestionList, {
                question: "",
                questionType: "",
                options: [{
                    answer: "",
                    otherTags: [],
                    tagGroups: [],
                    responseMsg: "",
                    displayOrder: 0,
                    status: true
                }],
                displayOrder: 0,
                status: true
            }]
            );

        }
    };

    const handleQuestionRemoveClick = index => {
        const list = [...inputQuestionList];
        list.splice(index, 1);
        setInputQuestionList(list);
    };

    const handleCurrentQuestionRemoveClick = index => {
        const list = [...inputQuestionList];
        list.splice((inputQuestionList.length - 1), 1);
        setInputQuestionList(list);
        setShowErrorNotification(false);
        setError([]);
    };

    const handleAddAnswerClick = (questionIndex, answerIndex) => {
        let newOptions = [];
        let options = inputQuestionList[questionIndex].options;
        newOptions = ([...options, {
            answer: "",
            otherTags: [],
            tagGroups: [],
            responseMsg: "",
            displayOrder: 0,
            status: true
        }]);

        const Qlist = [...inputQuestionList];
        Qlist[questionIndex].options = newOptions;
        setInputQuestionList(Qlist);
    };

    const handleSubmit = (e) => {
        setIsFormSubmitted(true);
        let lastQuestion = inputQuestionList[inputQuestionList.length - 1];
        let response = handleQuestionSubmit(lastQuestion);
        if (!response.isFormValid) {
            setShowErrorNotification(true);
            setError(response.formErrors)
        }
        else {
            let formErrors = {};
            if (cancerType !== "" && userType !== "") {
                setShowQuestionErrorNotification(false);
                setShowErrorNotification(false);
                setError([]);
                let res = handleFormSubmit(inputQuestionList);
                if (res.isFormValid) {
                    setShowQuestionErrorNotification(false);
                    let payloadQuestions = JSON.parse(JSON.stringify(inputQuestionList));
                    payloadQuestions.map((question) => {
                        question.options.map((answer) => {
                            answer.tagGroups = removeLabel(answer.tagGroups);
                            answer.otherTags = removeLabel(answer.otherTags);
                            return true;
                        })
                        return true;

                    });
                    const payload = {
                        userTypeId: userType,
                        cancerTypeId: cancerType,
                        questions: payloadQuestions
                    }

                    console.log("payload", payload);
                    let data = {
                        url: "onboardingSave",
                        method: "POST",
                        body: payload,
                    };
                    api
                        .call(data, async (res) => {
                            if (res.data.status === "success") {
                                common.notify("S", res.data.msg);
                                navigate("/onboarding");
                            }
                            else {
                                window.scrollTo(0, 0);
                                common.notify("E", res.data.message);
                            }
                        })
                        .catch((err) => {
                            window.scrollTo(0, 0);
                            common.notify("E", err);
                        });
                }
                else {
                    setShowQuestionErrorNotification(true);
                    setError(res.formErrors);
                }
            }
            else {
                if (cancerType === "") {
                    formErrors.cancerType = "Please select cancer type";
                }
                if (userType === "") {
                    formErrors.userType = "Please select user type";
                }

                setShowQuestionErrorNotification(true);
                setError(formErrors);
            }
        }

    }

    const numberValidation = (e) => {
        var regex = new RegExp("^[0-9]+$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) { return true; }
        e.preventDefault();
        return false;
    };


    return (
        <>
            <div className="container-fluid pt-3 px-4">
                <div className="row">
                    <div className="col-sm-12">
                        <h5 className="fw-medium mb-4">Add Questions</h5>
                    </div>

                </div>
            </div>
            <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-muted-50 rounded p-3">
                            <div className="row pb-5">
                                <div className="col-sm-12">
                                    {showQuestionErrorNotification ?
                                        <div className="d-table-caption">
                                            {error.requiredFields ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.requiredFields} </span> : null}
                                            {error.duplicateQuestions ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.duplicateQuestions} </span> : null}
                                            {error.displayOrder ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.displayOrder} </span> : null}
                                        </div> : null}
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" class="form-label">User
                                                    Type <i className="star">*</i></label>
                                                <select className="form-select form-select-lg"
                                                    aria-label="Default select example"
                                                    defaultValue={""}
                                                    value={userType || ""}
                                                    onChange={(e) => setUserType(e.target.value)}>
                                                    <option value="">Select User type</option>
                                                    {userTypeList.length > 0
                                                        ? userTypeList.map((item) => (
                                                            <option value={item.value}>{item.label}</option>
                                                        ))
                                                        : null}
                                                </select>
                                                <p className="error-field">
                                                    {error.userType}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Cancer
                                                    Type <i className="star">*</i></label>
                                                <select className="form-select form-select-lg"
                                                    aria-label="Default select example"
                                                    defaultValue={""}
                                                    value={cancerType || ""}
                                                    onChange={(e) => setCancerType(e.target.value)}>
                                                    <option value="">Select Cancer type</option>
                                                    {cancerList.length > 0
                                                        ? cancerList.map((item) => (
                                                            <option value={item.value}>{item.label}</option>
                                                        ))
                                                        : null}
                                                </select>
                                                <p className="error-field">
                                                    {error.cancerType}
                                                </p>
                                            </div>
                                        </div>
                                        {accordion ?
                                            inputQuestionList.map((item, i) => (
                                                inputQuestionList.length !== (i + 1) ?
                                                    <div class="col-sm-12 mb-3" >
                                                        <div class="bg-muted-50 rounded p-3">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="accordion" id="accordionExample">
                                                                        <div class="accordion-item border-0">
                                                                            <h2 class="accordion-header d-flex" id={`heading${i}`} >
                                                                                <button class="accordion-button collapsed px-0 pb-3" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>
                                                                                    <span class="col-6 pe-3"> Question : {item.question}</span>
                                                                                    <span class="col-6 pe-3"> Question Order : {item.displayOrder}</span>
                                                                                </button>
                                                                                <button type="button" class="btn btn-muted text-danger d-flex p-2 align-self-center align-items-center btn-trash"
                                                                                    onClick={(e) => {
                                                                                        if (
                                                                                            window.confirm(
                                                                                                "Are you sure you wish to delete this item?"
                                                                                            )
                                                                                        )
                                                                                            handleQuestionRemoveClick(i)
                                                                                    }}>
                                                                                    <iconify-icon icon="mdi:trash" class="fa-2x align-self-center"></iconify-icon>
                                                                                </button>
                                                                            </h2>
                                                                            <div id={`collapse${i}`} class="accordion-collapse collapse" aria-labelledby={`heading${i}`} data-bs-parent="#accordionExample">
                                                                                <div class="accordion-body px-0">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="mb-3">
                                                                                                <label for="input2" className="form-label">Question <i className="star">*</i></label>
                                                                                                <textarea className="form-control form-control-lg border-0"
                                                                                                    id="exampleFormControlTextarea1" rows="5"
                                                                                                    placeholder="Enter question" name="question"
                                                                                                    value={item.question || ""}
                                                                                                    onChange={(e) => handleQuestionInputChange(e.target.value, i, "question")}>
                                                                                                </textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="mb-3">
                                                                                                <label for="exampleFormControlInput1" className="form-label">Question Type <i className="star">*</i></label>
                                                                                                <select className="form-select form-select-lg" aria-label="Default select example"
                                                                                                    value={item.questionType || ""} name="questionType"
                                                                                                    onChange={(e) => handleQuestionInputChange(e.target.value, i, "questionType")}>
                                                                                                    <option selected>Select question type</option>
                                                                                                    <option value="radio">Radio</option>
                                                                                                    <option value="checkbox">Check box</option>
                                                                                                    <option value="textbox">Text Box</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            <div class="mb-3">
                                                                                                <label for="exampleFormControlInput1" className="form-label">Question Order</label>
                                                                                                <input type="number" id="input1" className="form-control form-control-lg border-0"
                                                                                                    placeholder="Enter display order" name="displayOrder"
                                                                                                    value={item.displayOrder || ""}
                                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                                    min="1"
                                                                                                    onChange={(e) => handleQuestionInputChange(e.target.value, i, "displayOrder")} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.options.map((y, j) => {
                                                                                            return (
                                                                                                <div className="col-12">
                                                                                                    <div className="row">
                                                                                                        <div className="col-3">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="exampleFormControlInput1" className="form-label">Answer <i className="star">*</i></label>

                                                                                                                <input type="text" id="input1" class="form-control form-control-lg border-0"
                                                                                                                    placeholder="Enter answer"
                                                                                                                    value={y.answer}
                                                                                                                    disabled= {item.questionType === 'textbox' ?true : false }
                                                                                                                    onChange={(e) => handleAnswerInputChange(e, j, "answer", i)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="exampleFormControlInput1" className="form-label">Tag Group <i className="star">*</i></label>

                                                                                                                <Select
                                                                                                                    class="form-select form-select-lg"
                                                                                                                    options={tagGroupList}
                                                                                                                    value={y.tagGroups}
                                                                                                                    onChange={(e) =>
                                                                                                                        e.length &&
                                                                                                                        e.find((tagGroups) => tagGroups.value === "all")?
                                                                                                                         handleAnswerInputChange(tagGroupList.slice(1), j, "tagGroups", i)
                                                                                                                        : handleAnswerInputChange(e, j, "tagGroups", i)}
                                                                                                                    isSearchable={true}
                                                                                                                    isMulti
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            <div className="mb-3">
                                                                                                                <label for="exampleFormControlInput1" className="form-label">Tags <i className="star">*</i></label>
                                                                                                                <Select
                                                                                                                    class="form-select form-select-lg"
                                                                                                                    options={tagsList}
                                                                                                                    value={y.otherTags || ""}
                                                                                                                    onChange={(e) => e.length &&
                                                                                                                        e.find((tags) => tags.value === "all")? 
                                                                                                                           handleAnswerInputChange(tagsList.slice(1), j, "otherTags", i)
                                                                                                                         :handleAnswerInputChange(e, j, "otherTags", i)}
                                                                                                                    isSearchable={true}
                                                                                                                    isMulti
                                                                                                                />

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {item.questionType !== "checkbox"?
                                                                                                        <div className="col-2">
                                                                                                            <div className="mb-3">
                                                                                                                <label for="exampleFormControlInput1"
                                                                                                                    className="form-label">Response Message</label>
                                                                                                                <input type="text" id="input1" class="form-control form-control-lg border-0"
                                                                                                                    placeholder="Enter reponse"
                                                                                                                    value={y.responseMsg}
                                                                                                                    onChange={(e) => handleAnswerInputChange(e, j, "responseMsg", i)} />
                                                                                                            </div>
                                                                                                        </div>:null}
                                                                                                        <div className="col-2">
                                                                                                            <div className="mb-3">
                                                                                                                <label for="exampleFormControlInput1"
                                                                                                                    className="form-label">Display Order <i className="star">*</i></label>
                                                                                                                <input type="number" id="input1" className="form-control form-control-lg border-0"
                                                                                                                    placeholder="Enter"
                                                                                                                    value={y.displayOrder || ""}
                                                                                                                    onKeyPress={(e) => numberValidation(e)}
                                                                                                                    min="1"
                                                                                                                    onChange={(e) => handleAnswerInputChange(e, j, "displayOrder", i)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {item.options.length !== 1 &&

                                                                                                            <div class="col-1">
                                                                                                                <div style={{ position: "relative", top: "32px" }}>
                                                                                                                    <button type="button" class="btn btn-muted text-danger d-flex p-2" onClick={(e) => handleRemoveAnswerClick(i, j)}>
                                                                                                                        <iconify-icon icon="mdi:trash" class="fa-2x align-self-center"></iconify-icon>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>)
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null))

                                            : null
                                        }
                                        {inputQuestionList.map((x, i, inputQuestionList) => {
                                            if (i + 1 === inputQuestionList.length) {
                                                return (
                                                    <div className="col-sm-12">
                                                        <div className="bg-muted-50 rounded p-3">

                                                            {showErrorNotification ?
                                                                <div className="d-table-caption">
                                                                    {error.requiredFields ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.requiredFields} </span> : null}
                                                                    {error.TagsError ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.TagsError} </span> : null}
                                                                    {error.duplicateAnswer ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.duplicateAnswer} </span> : null}
                                                                    {error.displayOrder ? <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2"><i class="fas fa-exclamation-triangle me-3"></i>{error.displayOrder} </span> : null}

                                                                </div>
                                                                : null}
                                                            <div className="row ">
                                                                <div className="col-sm-6">

                                                                    <div className="mb-3">

                                                                        <label for="input2" className="form-label">Question <i className="star">*</i></label>
                                                                        <textarea className="form-control form-control-lg border-0"
                                                                            id="exampleFormControlTextarea1" rows="5"
                                                                            placeholder="Enter question" name="question"
                                                                            value={x.question || ""}
                                                                            onChange={(e) => handleQuestionInputChange(e.target.value, i, "question")}>

                                                                        </textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label for="exampleFormControlInput1" className="form-label">Question Type <i className="star">*</i></label>
                                                                        <select className="form-select form-select-lg" aria-label="Default select example"
                                                                            value={x.questionType || ""} name="questionType"
                                                                            onChange={(e) => handleQuestionInputChange(e.target.value, i, "questionType")}>
                                                                            <option selected>Select question type</option>
                                                                            <option value="radio">Radio</option>
                                                                            <option value="checkbox">Check box</option>
                                                                            <option value="textbox">Text Box</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInput1" className="form-label">Question Order <i className="star">*</i></label>
                                                                        <input type="number" id="input1" className="form-control form-control-lg border-0"
                                                                            placeholder="Enter display order" name="displayOrder"
                                                                            value={x.displayOrder || ""}
                                                                            onKeyPress={(e) => numberValidation(e)}
                                                                            min="1"
                                                                            onChange={(e) => handleQuestionInputChange(e.target.value, i, "displayOrder")} />
                                                                    </div>
                                                                </div>
                                                                {x.options.map((y, j) => {
                                                                    return (
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-3">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Answer <i className="star">*</i></label>

                                                                                        <input type="text" id="input1" class="form-control form-control-lg border-0"
                                                                                            placeholder="Enter answer"
                                                                                            value={y.answer}
                                                                                            disabled= {x.questionType === 'textbox' ?true : false }
                                                                                            onChange={(e) => handleAnswerInputChange(e, j, "answer", i)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Tag Group <i className="star">*</i></label>
                                                                                        <Select
                                                                                            class="form-select form-select-lg"
                                                                                            options={tagGroupList}
                                                                                            value={y.tagGroups}
                                                                                            onChange={(e) => 
                                                                                                e.length &&
                                                                                                e.find((tagGroups) => tagGroups.value === "all")?
                                                                                                 handleAnswerInputChange(tagGroupList.slice(1), j, "tagGroups", i)
                                                                                                :handleAnswerInputChange(e, j, "tagGroups", i)}
                                                                                            isSearchable={true}
                                                                                            isMulti
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <div className="mb-3">
                                                                                        <label for="exampleFormControlInput1" className="form-label">Tags <i className="star">*</i></label>
                                                                                        <Select
                                                                                            class="form-select form-select-lg"
                                                                                            options={tagsList}
                                                                                            value={y.otherTags || ""}
                                                                                            onChange={(e) => e.length &&
                                                                                                e.find((tags) => tags.value === "all")? 
                                                                                                   handleAnswerInputChange(tagsList.slice(1), j, "otherTags", i)
                                                                                                 :handleAnswerInputChange(e, j, "otherTags", i)}
                                                                                            isSearchable={true}
                                                                                            isMulti
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                                {x.questionType !== "checkbox"?
                                                                                <div className="col-2">
                                                                                    <div className="mb-3">
                                                                                        <label for="exampleFormControlInput1"
                                                                                            className="form-label">Response Message </label>
                                                                                        <input type="text" id="input1" class="form-control form-control-lg border-0"
                                                                                            placeholder="Enter reponse"
                                                                                            value={y.responseMsg}
                                                                                            onChange={(e) => handleAnswerInputChange(e, j, "responseMsg", i)} />
                                                                                    </div>
                                                                                </div>:null}
                                                                                <div className="col-2">
                                                                                    <div className="mb-3">
                                                                                        <label for="exampleFormControlInput1"
                                                                                            className="form-label">Display Order <i className="star">*</i></label>
                                                                                        <input type="number" id="input1" className="form-control form-control-lg border-0"
                                                                                            placeholder="Enter"
                                                                                            value={y.displayOrder || ""}
                                                                                            onKeyPress={(e) => numberValidation(e)}
                                                                                            min="1"
                                                                                            onChange={(e) => handleAnswerInputChange(e, j, "displayOrder", i)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {x.options.length !== 1 &&
                                                                                    <div class="col-1">
                                                                                        <div style={{ position: "relative", top: "32px" }}>
                                                                                            <button type="button" class="btn btn-muted text-danger d-flex p-2" onClick={(e) => handleRemoveAnswerClick(i, j)}>
                                                                                                <iconify-icon icon="mdi:trash" class="fa-2x align-self-center"></iconify-icon>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {x.options.length - 1 === j &&
                                                                                <button type="button" class="btn btn-muted btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
                                                                                    onClick={(e) => handleAddAnswerClick(i, j)}>
                                                                                    <iconify-icon icon="material-symbols:add-box-outline" class="me-2 fa-lg"></iconify-icon>Add New Answer
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return true;
                                        })}
                                    </div>
                                    <div className="col-12 d-flex">
                                        <button type="button" class="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium col-3"
                                            onClick={handleQuestionAddClick} >
                                            <iconify-icon icon="material-symbols:add-box-outline" class="me-2 fa-lg"></iconify-icon>Add New Question
                                        </button>
                                        {inputQuestionList.length !== 1 ?
                                            <button type="button" className="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium col-3 ms-3"
                                                onClick={(e) => {
                                                    if (
                                                        window.confirm(
                                                            "Are you sure you wish to delete this item?"
                                                        )
                                                    )
                                                        handleCurrentQuestionRemoveClick()
                                                }}>
                                                <iconify-icon icon="mdi:trash" class="fa-lg me-2"></iconify-icon>Remove Current Question
                                            </button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12 d-flex">
                                    <button className="btn btn-muted btn-lg text-white fw-semibold" type="button" data-bs-toggle="modal" data-bs-target="#backAlert"
                                         >Back</button>
                                    <button className="btn btn-primary btn-lg text-white fw-semibold ms-3" type="button"
                                        onClick={handleSubmit} >Save</button>
                                        <ModalPopup page="onboarding"></ModalPopup>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
}