import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, common, FormValidation } from "../../helpers";
import { ValidateForm } from "./userTypesValidation";
import ModalPopup from '../../elements/Modal';

const UserTypeAdd = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const { fields, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      userTypesUpdate();
    }
  };

  const userTypesUpdate = async () => {
    let payload = {
      userType: fields.userType,
      reason: fields.description,
      displayOrder: fields.displayOrder.toString(),
    };
    let data = {
      url: "userTypesSave",
      method: "POST",

      body: payload,
    };
    api
      .call(data, async (res) => {
        debugger;
        if (res.data.status === "success") {
          navigate("/userTypes");
          common.notify("S", res?.data?.msg);
        } else {
          common.notify("E", res?.data?.message);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
        setIsSubmit(false);
      });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Add User Type</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            User Name <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="User name"
                            name="userType"
                            value={fields.userType}
                            onChange={(e) => {
                              setValue("userType", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-field">{errors.userType}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Display Order <i className="star">*</i>
                          </label>
                          <input
                            type="number"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Display order"
                            name="displayOrder"
                            value={fields.displayOrder}
                            onChange={(e) => {
                              setValue("displayOrder", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <p className="error-field">{errors.displayOrder}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label for="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>
                          <textarea
                            style={{ whiteSpace: "pre-wrap" }}
                            //  style={{whiteSpace:"pre-wrap"}}​​​​​​
                            className="form-control form-control-lg border-0"
                            id="exampleFormControlTextarea1"
                            rows="4"
                            placeholder="Description"
                            name="description"
                            value={fields.description}
                            onChange={(e) => {
                              setValue("description", e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <p className="error-field">{errors.description}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      {" "}
                      ADD
                    </button>
                    <ModalPopup page="userTypes"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTypeAdd;
