import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import { Link } from "react-router-dom";

const QuestionsDetails = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [questionDetails, setQuestionDetails] = useState([]);
    const [activeCheck, setActiveCheck] = useState();
    // init

    //API
    const getQuestionsDetails = () => {
        let data = {
            url: "onboardingGet",
            method: "GET",
            query: `${id}`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                console.log("Question details:", res?.data?.data);
                debugger;
                setQuestionDetails(res?.data?.data);
                setActiveCheck(res?.data?.data?.status);
            }
        });
    };
    const statusChange = function (id) {
        let data = {
            url: "onboardingStatusUpdate",
            method: "PUT",
            query: `${id}`,
        };
        api.call(data, (res) => {
            if (res.status === 200) {
                common.notify("S", res?.data?.msg);
                getQuestionsDetails();
            }
        });
    };

    const editDetails = async (e) => {
        navigate(`/onboarding/edit/${id}`);
    };

    useEffect(() => {
        getQuestionsDetails();
    }, []);

    return (
        <>
            <div className="container-fluid pt-3 px-4">
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">Question Details</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
                        <Link
                            to="/onboarding"
                            className="btn btn-muted btn-lg text-white fw-semibold me-3"
                        >
                            Back
                        </Link>
                        <button
                            className="btn btn-muted btn-lg text-white fw-semibold"
                            onClick={editDetails}
                        >
                            Edit
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
                <div className="row h-100">
                    <div className="col-12">
                        <div class="bg-muted-50 p-4 rounded-1 h-100">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <h5 className="fw-medium">User Type</h5>
                                    <p className="fs-6 mb-0 fw-normal">{questionDetails?.userType?.label}</p>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <h5 className="fw-medium">Cancer Type</h5>

                                    <p class="fs-6 mb-0 fw-normal">{questionDetails?.cancerType?.label}</p>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-3 text-end">
                                    <div className="d-flex justify-content-end mb-3">
                                        Is Active
                                        <div class="form-check form-switch d-flex align-items-center ms-2">
                                            <input
                                                class="form-check-input py-2"
                                                type="checkbox"
                                                role="switch"
                                                name="status"
                                                id="flexSwitchCheckChecked"
                                                checked={activeCheck}
                                                onChange={(e) => {
                                                    let message =
                                                        activeCheck === false
                                                            ? "Are you sure you wish to active these questions?"
                                                            : "Are you sure you wish to inactive these questions?";
                                                    var answer = window.confirm(message);
                                                    if (answer) {
                                                        statusChange(id);
                                                        setActiveCheck(!activeCheck);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    </div>
                            </div>
                           {questionDetails?.questions?.map((item) => (
                            <div className="col-sm-12">
                                <div className="bg-muted-50 rounded p-3">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <h5 className="fw-medium">Question</h5>
                                                <p className="fs-6 mb-0 fw-normal">{item.question}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Question Type</h5>
                                                <p className="fs-6 mb-0 fw-normal">{item.questionType}</p>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Question Order</h5>
                                                <p className="fs-6 mb-0 fw-normal">{item.displayOrder}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {item.options.map((a) => (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <h5 className="fw-medium">Answer</h5>
                                                <p className="fs-6 mb-0 fw-normal">{a.answer}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Tag</h5>
                                                {a?.tags.map((tag) => (
                                                   <li className="fs-6 mb-0 fw-normal">{tag.label}</li>
                                                )
                                                )}
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Tag Group</h5>
                                                {a?.tagGroups.map((tagGroup) => (
                                                   <li className="fs-6 mb-0 fw-normal">{tagGroup.label}</li>
                                                )
                                                )}
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Display Order</h5>
                                                <p className="fs-6 mb-0 fw-normal">{a.displayOrder}</p>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-3">
                                                <h5 className="fw-medium">Response Message</h5>
                                                <p className="fs-6 mb-0 fw-normal">{a.responseMsg}</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                    )}
                                </div>
                            </div>
                           )
                        )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default QuestionsDetails;
