import { AES } from "crypto-js";
import moment from "moment";
import { toast } from "react-toastify";
// includes
import { config } from "./config";

export const common = {
  notify(type, msg) {
    switch (type) {
      case "S":
        toast.success(msg, config.elements.toast);
        break;
      case "W":
        toast.warning(msg, config.elements.toast);
        break;
      case "E":
        toast.error(msg, config.elements.toast);
        break;
      case "I":
        toast.info(msg, config.elements.toast);
        break;
      case "M":
        toast(msg, config.elements.toast);
        break;
      default:
        break;
    }
  },

  // local storage
  localSet(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  localGet(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },
  localRemove(key) {
    localStorage.removeItem(key);
  },

  // local data
  localDataSet(value) {
    this.localSet("localData", value);
  },

  localDataGet() {
    let res = this.localGet("localData", true);
    this.localDataRemove();
    return res;
  },

  localDataRemove() {
    return this.localRemove("localData");
  },

  // auth
  checkAuth() {
    let authData = this.localGet("authData");
    if (authData === "" || authData == null) {
      return false;
    } else {
      return this.getAuth() ? true : false;
    }
  },
  getAuth() {
    let token = localStorage.getItem("token");
    return token;
  },

  // encode decode
  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },

  apiStatus(res) {
    let status = "success";
    if (res.error) {
      status = "error";
    } else if (res.data?.length === 0 || res.data === null) {
      status = "empty";
    }
    return status;
  },

  redirect(url = "") {
    window.location.href = config.base_url + "/" + url;
  },

  dateFormatA(val) {
    return val ? moment(val).format(config.elements.dp.formatA) : "";
  },

  trimString(str, len) {
    let res = str;
    if (str?.length > len) {
      res = str.substring(0, len) + "...";
    }
    return res;
  },

  objectLevel(obj, level) {
    level = level.split(".");
    let data = obj;
    if (data === null) {
      data = "";
    } else {
      for (var i = 0; i < level.length; i++) {
        if (typeof data !== "object") {
          data = "";
        } else {
          data = data[level[i]];
        }
      }
    }
    return data;
  },
  // initialize
  initFields(formData) {
    let data = {};
    for (const [key, field] of Object.entries(formData)) {
      data[key] = typeof field.value == "undefined" ? "" : field.value;
    }
    return data;
  },
  initErrors(formData) {
    let data = {};
    for (const [key, field] of Object.entries(formData)) {
      if (field.validate) {
        data[key] = "";
      }
    }
    return data;
  },
  initAllow(formData) {
    let data = {};
    for (const [key, field] of Object.entries(formData)) {
      data[key] = "*";
      if (field.allow) {
        data[key] = field.allow;
      }
    }
    return data;
  },
  // validate
  amlMessage(str) {
    return str.replace("${amlHash}", "#");
  },
  hashMessage(str, rep) {
    str = str.replace("#value#", rep);
    str = str.replace("+number", "positive number");
    str = str.replace("+-number", "positive or negative number");
    str = str.replace("+float", "positive float");
    return str;
  },

  getFileType(fileInfo) {
    //let AcceptedFileGroup = "image" | "video";
    if (fileInfo.type.indexOf("video") !== -1) {
      return "video";
    } else if (fileInfo.type.indexOf("image") !== -1) {
      return "image";
    }
  },
};
