import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, common } from "../../helpers";
import { FiEyeOff, FiEye } from "react-icons/fi";

const ChangePassword = () => {
  const navigate = useNavigate();

  // state
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
  const [fields, setFields] = useState({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
  const [passwordType, setPasswordType] = useState("oldPassword");
  const [newpasswordType, setNewPasswordType] = useState("newPassword");
  const [confirmPasswordType, setConfirmPasswordType] = useState("confirmNewPassword");

  // handler
  const navDash = () => {
    navigate("/dashboard");
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setloading(true);
      let payload = {
        oldPassword: fields.oldPassword,
        newPassword: fields.newPassword,
        confirmNewPassword: fields.confirmNewPassword,
      };

      let data = {
        url: "authChangepassword",
        method: "POST",
        body: payload,
      };
      api
        .call(data, (res) => {
          if (res.status === 200) {
            common.notify("S", res.data.msg);
            navigate("/dashboard");
            setloading(false);
          } else {
            common.notify("E", res.data.message);
            setloading(false);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          common.notify("E", error.response.data.message);
          setloading(false);
        });
    }
  };

  const handleChange = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e?.target?.value }));
  };
  const validateForm = (data) => {
    let isFormValid = true;
    const formErrors = {};
    if (!fields.oldPassword) {
      formErrors.oldPassword = "Please enter current password";
      isFormValid = false;
    }
    if (!fields.newPassword) {
      formErrors.newPassword = "Please enter a new password";
      isFormValid = false;
    } else if (
      !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]).{7,40}/.test(fields.newPassword)
    ) {
      formErrors.newPassword =
        "Password must have 1 uppercase letter, 1 lowercase letter, 1 special character, and be between 7 and 40 characters long";
      isFormValid = false;
    } else if (fields.oldPassword === fields.newPassword) {
      formErrors.newPassword = "New password cannot be the same as the old password. Please choose a different password.";
      isFormValid = false;
    }
    if (!fields.confirmNewPassword) {
      formErrors.confirmNewPassword = "Please enter current new password";
      isFormValid = false;
    } else if (fields.newPassword !== fields.confirmNewPassword) {
      formErrors.confirmNewPassword = "Please enter the same password";
      isFormValid = false;
    }
    setError(formErrors);
    return isFormValid;
  };

  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };
  return (
    <div className="d-flex h-100 w-100 flex-column">
      <div className="p-3 col-sm-10">
        <img src="images/logo1.png" className="col-sm-2" alt="" />
      </div>
      <div className="flex-fill justify-content-center d-flex align-items-center">
        <div className="p-5 bg-dark rounded-4 text-white col-sm-4">
          <h4 className="mb-4 fw-bold">Change Password</h4>
          <label htmlFor="" className="form-label">
            Current Password <i className="star">*</i>
          </label>
          <div className="input-group mb-4">
            <span className="input-group-text bg-white">
              <i className="far fa-lock text-secondary"></i>
            </span>
            <input
              type={passwordType === "text" ? "text" : "password"}
              className="form-control border-start-0 ps-0 bg-white text-secondary"
              placeholder="Enter your current password"
              aria-label="oldPassword"
              aria-describedby="basic-addon1"
              name="oldPassword"
              maxLength={40}
              value={fields.oldPassword}
              onChange={(e) => {
                resetError("oldPassword");
                handleChange(e);
              }}
            />
            <span className="input-group-text ">
              {passwordType === "text" ? (
                <FiEye onClick={(e) => setPasswordType("oldPassword")} />
              ) : (
                <FiEyeOff onClick={(e) => setPasswordType("text")} />
              )}
            </span>
          </div>
          <p className="error-field">{error.oldPassword}</p>
          <label htmlFor="" className="form-label">
            New Password <i className="star">*</i>
          </label>
          <div className="input-group mb-4">
            <span className="input-group-text bg-white">
              <i className="far fa-lock text-secondary"></i>
            </span>
            <input
              type={newpasswordType === "text" ? "text" : "password"}
              className="form-control border-start-0 ps-0 bg-white text-secondary"
              placeholder="Enter your new password"
              aria-label="newPassword"
              aria-describedby="basic-addon1"
              name="newPassword"
              maxLength={40}
              value={fields.newPassword}
              onChange={(e) => {
                resetError("newPassword");
                handleChange(e);
              }}
            />
            <span className="input-group-text ">
              {newpasswordType === "text" ? (
                <FiEye onClick={(e) => setNewPasswordType("newPassword")} />
              ) : (
                <FiEyeOff onClick={(e) => setNewPasswordType("text")} />
              )}
            </span>
          </div>
          <p className="error-field">{error.newPassword}</p>
          <label htmlFor="" className="form-label">
            Confirm New Password <i className="star">*</i>
          </label>
          <div className="input-group mb-4">
            <span className="input-group-text bg-white">
              <i className="far fa-lock text-secondary"></i>
            </span>
            <input
              type={confirmPasswordType === "text" ? "text" : "password"}
              className="form-control border-start-0 ps-0 bg-white text-secondary"
              placeholder="Enter your confirm new password"
              aria-label="confirmNewPassword"
              aria-describedby="basic-addon1"
              name="confirmNewPassword"
              maxLength={40}
              value={fields.confirmNewPassword}
              onChange={(e) => {
                resetError("confirmNewPassword");
                handleChange(e);
              }}
            />
            <span className="input-group-text ">
              {confirmPasswordType === "text" ? (
                <FiEye onClick={(e) => setConfirmPasswordType("confirmNewPassword")} />
              ) : (
                <FiEyeOff onClick={(e) => setConfirmPasswordType("text")} />
              )}
            </span>
          </div>
          <p className="error-field">{error.confirmNewPassword}</p>
          <div class="row">
            <div class="col-6">
              <button
                type="submit"
                className="btn btn-secondary w-100 text-white fw-semibold text-uppercase"

                onClick={navDash}
              >
                Cancel
              </button>
            </div>
            <div class="col-6">
              <button
                type="submit"
                className="btn btn-primary w-100 text-white fw-semibold text-uppercase"
                disabled={loading}
                onClick={onSubmit}
              >
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
