import MainLayout from "./layouts/main";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/auth/signIn";
import Dashboard from "./pages/dashboard/dashboadMain";
import ChangePassword from "./pages/auth/changePassword";
import UserList from "./pages/users/userList";
import UserView from "./pages/users/userView";
import VideoList from "./pages/videos/videoList";
import VideoAdd from "./pages/videos/videoAdd";
import VideoEdit from "./pages/videos/videoEdit";
import VideoView from "./pages/videos/videoDetails";

import GlossaryAdd from "./pages/glossary/glossaryAdd";
import GlossaryEdit from "./pages/glossary/glossaryEdit";
import GlossaryList from "./pages/glossary/glossaryList";
import GlossaryView from "./pages/glossary/glossaryDetails";

import OnBoardingQuestions from "./pages/onboardingQuestions/questionsList";
import OnBoardingQuestionsAdd from "./pages/onboardingQuestions/questionsAdd";
import OnBoardingQuestionsEdit from "./pages/onboardingQuestions/questionsEdit";
import OnBoardingQuestionsView from "./pages/onboardingQuestions/questionView";

import TagGroupAdd from "./pages/tagGroup/tagGroupAdd";
import TagGroupList from "./pages/tagGroup/tagGroupList";
import TagGroupView from "./pages/tagGroup/tagGroupDetails";
import TagGroupEdit from "./pages/tagGroup/tagGroupEdit";
import CancerTypesList from "./pages/cancerTypes/CancerTypesList";
import CancerTypesListView from "./pages/cancerTypes/CancerTypesListView";
import AddCancerType from "./pages/cancerTypes/AddCancerType";

// userTypes
import UseTypesAdd from "./pages/userTypes/userTypesAdd";
import UserTypesList from "./pages/userTypes/userTypesList";
import UserTypesEdit from "./pages/userTypes/userTypeEdit";
import UserTypesView from "./pages/userTypes/userTypesView";

import EditCancerType from "./pages/cancerTypes/EditCancerType";
import TagsAdd from "./pages/tags/tagsAdd";
import TagsList from "./pages/tags/tagsList";
import TagsView from "./pages/tags/tagsDetails";
import TagsEdit from "./pages/tags/tagsEdit";

import AdsList from "./pages/Advertisements/adsList";
import AdsView from "./pages/Advertisements/adsDetails";
import AdsAdd from "./pages/Advertisements/adsAdd";
import AdsEdit from "./pages/Advertisements/adsEdit";

// Race
import RaceList from "./pages/RaceManagement/RaceList";
import RaceAdd from "./pages/RaceManagement/RaceAdd"
import RaceEdit from "./pages/RaceManagement/RaceEdit"
import RaceView from "./pages/RaceManagement/RaceView"

//Episode
import EpisodeList from "./pages/episode/episodeList";
import EpisodeAdd from "./pages/episode/episodeAdd";
import EpisodeEdit from "./pages/episode/episodeEdit";

import AgeGroupList from "./pages/ageGroup/ageGroupList";
import AgeGroupView from "./pages/ageGroup/ageGroupDetails";
import AgeGroupAdd from "./pages/ageGroup/ageGroupAdd";
import AgeGroupEdit from "./pages/ageGroup/ageGroupEdit";

import GenderList from "./pages/genderManagement/genderList";
import GenderAdd from "./pages/genderManagement/genderAdd";
import GenderEdit from "./pages/genderManagement/genderEdit";
import GenderDetail from "./pages/genderManagement/genderDetail";

import VideoRecommendation from "./pages/videoRecommendation/videoRecommendation"
import MobileDashboard from "./pages/mobileDashboard/mobileDashboard";
import MobileDashboardList from "./pages/mobileDashboard/mobileDashboardListing";
import MobileDashboardShow from "./pages/mobileDashboard/mobileDashboardShow";
import MobileDashboardAdd from "./pages/mobileDashboard/mobileDashboardAdd";
import AnalyticsMain from "./pages/Analytics/analyticsMain";
function App() {
  // Add a request interceptor

  return (
    <div className="App" style={{ height: "100%" }}>
      <Router>
        {/* Front */}

        <Routes>
          <Route path="/login" element={<SignIn />} />

          {/* After Auth */}
          <Route
            path="/"
            element={
              <MainLayout>
                <Dashboard></Dashboard>
              </MainLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <MainLayout>
                <Dashboard></Dashboard>
              </MainLayout>
            }
          />
           <Route
            path="/change-password"
            element={
                <ChangePassword/>
            }
          />
          <Route
            path="/users"
            element={
              <MainLayout>
                <UserList></UserList>
              </MainLayout>
            }
          />
          <Route
            path="/users/view/:id"
            element={
              <MainLayout>
                <UserView></UserView>
              </MainLayout>
            }
          />
          <Route
            path="/videos"
            element={
              <MainLayout>
                <VideoList></VideoList>
              </MainLayout>
            }
          />

          <Route
            path="/videos-add"
            element={
              <MainLayout>
                <VideoAdd></VideoAdd>
              </MainLayout>
            }
          />
          <Route
            path="/videos/edit/:id"
            element={
              <MainLayout>
                <VideoEdit />
              </MainLayout>
            }
          />

          <Route
            path="/videos/view/:id"
            element={
              <MainLayout>
                <VideoView />
              </MainLayout>
            }
          />
          <Route
            path="/glossary"
            element={
              <MainLayout>
                <GlossaryList></GlossaryList>
              </MainLayout>
            }
          />

          <Route
            path="/glossary-add"
            element={
              <MainLayout>
                <GlossaryAdd />
              </MainLayout>
            }
          />
          <Route
            path="/mobile-dashboard-list"
            element={
              <MainLayout>
                <MobileDashboardList />
              </MainLayout>
            }
          />
           <Route
            path="/mobile-dashboard/edit/:id"
            element={
              <MainLayout>
                <MobileDashboard />
              </MainLayout>
            }
          />
          <Route
            path="/mobile-dashboard/add"
            element={
              <MainLayout>
                <MobileDashboardAdd />
              </MainLayout>
            }
          />
           <Route
            path="/mobile-dashboard/view/:id"
            element={
              <MainLayout>
                <MobileDashboardShow />
              </MainLayout>
            }
          />          
          <Route
            path="/glossary/edit/:id"
            element={
              <MainLayout>
                <GlossaryEdit />
              </MainLayout>
            }
          />

          <Route
            path="/glossary/view/:id"
            element={
              <MainLayout>
                <GlossaryView />
              </MainLayout>
            }
          />

          <Route
            path="/onboarding"
            element={
              <MainLayout>
                <OnBoardingQuestions />
              </MainLayout>
            }
          />
          <Route
            path="/onboarding-add"
            element={
              <MainLayout>
                <OnBoardingQuestionsAdd />
              </MainLayout>
            }
          />

          <Route
            path="/onboarding/edit/:id"
            element={
              <MainLayout>
                <OnBoardingQuestionsEdit />
              </MainLayout>
            }
          />
          <Route
            path="/onboarding/view/:id"
            element={
              <MainLayout>
                <OnBoardingQuestionsView />
              </MainLayout>
            }
          />

          <Route
            path="/tagGroup"
            element={
              <MainLayout>
                <TagGroupList />
              </MainLayout>
            }
          />

          <Route
            path="/tagGroup/view/:id"
            element={
              <MainLayout>
                <TagGroupView />
              </MainLayout>
            }
          />
          <Route
            path="/taggroup-add"
            element={
              <MainLayout>
                <TagGroupAdd />
              </MainLayout>
            }
          />
          <Route
            path="/tagGroup/edit/:id"
            element={
              <MainLayout>
                <TagGroupEdit />
              </MainLayout>
            }
          />

          {/* tag */}
          <Route
            path="/tags"
            element={
              <MainLayout>
                <TagsList />
              </MainLayout>
            }
          />

          <Route
            path="/tags/view/:id"
            element={
              <MainLayout>
                <TagsView />
              </MainLayout>
            }
          />
          <Route
            path="/tags-add"
            element={
              <MainLayout>
                <TagsAdd />
              </MainLayout>
            }
          />
          <Route
            path="/tags/edit/:id"
            element={
              <MainLayout>
                <TagsEdit />
              </MainLayout>
            }
          />

          <Route
            path="/cancerTypes"
            element={
              <MainLayout>
                <CancerTypesList />
              </MainLayout>
            }
          />

          <Route
            path="/cancerTypes/view/:id"
            element={
              <MainLayout>
                <CancerTypesListView />
              </MainLayout>
            }
          />

          <Route
            path="/cancerTypes/edit/:id"
            element={
              <MainLayout>
                <EditCancerType />
              </MainLayout>
            }
          />

          <Route
            path="/cancerTypes/add"
            element={
              <MainLayout>
                <AddCancerType />
              </MainLayout>
            }
          />

          {/* userTypes */}
          <Route
            path="/userTypes"
            element={
              <MainLayout>
                <UserTypesList />
              </MainLayout>
            }
          />

          <Route
            path="/userTypes-add"
            element={
              <MainLayout>
                <UseTypesAdd />
              </MainLayout>
            }
          />
          {/* <Route
            path="/taggroup-add"
            element={
              <MainLayout>
                <TagGroupAdd />
              </MainLayout>
            }
          /> */}
          <Route
            path="/userTypes/edit/:id"
            element={
              <MainLayout>
                <UserTypesEdit />
              </MainLayout>
            }
          />
          <Route
            path="/userTypes/view/:id"
            element={
              <MainLayout>
                <UserTypesView />
              </MainLayout>
            }
          />
          <Route
            path="/raceManagement"
            element={
              <MainLayout>
                <RaceList />
              </MainLayout>
            }
          />
          <Route
            path="/raceManagement-add"
            element={
              <MainLayout>
                <RaceAdd />
              </MainLayout>
            }
          />
           <Route
            path="/raceManagement/edit/:id"
            element={
              <MainLayout>
                <RaceEdit />
              </MainLayout>
            }
          />
          <Route
            path="/raceManagement/view/:id"
            element={
              <MainLayout>
                <RaceView />
              </MainLayout>
            }
          />

          <Route
            path="/advertisements/view/:id"
            element={
              <MainLayout>
                <AdsView />
              </MainLayout>
            }
          />

          <Route
            path="/advertisements"
            element={
              <MainLayout>
                <AdsList />
              </MainLayout>
            }
          />
          <Route
            path="/advertisements-add"
            element={
              <MainLayout>
                <AdsAdd />
              </MainLayout>
            }
          />
          <Route
            path="/advertisements/edit/:id"
            element={
              <MainLayout>
                <AdsEdit />
              </MainLayout>
            }
          />
          <Route
            path="/episode"
            element={
              <MainLayout>
                <EpisodeList />
              </MainLayout>
            }
          />

          <Route
            path="/episode-add"
            element={
              <MainLayout>
                <EpisodeAdd />
              </MainLayout>
            }
          />
          <Route
            path="/episode/edit/:id"
            element={
              <MainLayout>
                <EpisodeEdit />
              </MainLayout>
            }
          />

          <Route
            path="/ageGroup"
            element={
              <MainLayout>
                <AgeGroupList />
              </MainLayout>
            }
           />

           <Route
            path="/ageGroup-add"
            element={
              <MainLayout>
               <AgeGroupAdd />
              </MainLayout>
              }
            />
          
         <Route
            path="/ageGroup/edit/:id"
            element={
              <MainLayout>
                <AgeGroupEdit />
              </MainLayout>
              }
           />
           
           <Route
            path="/ageGroup/view/:id"
            element={
              <MainLayout>
                <AgeGroupView />
              </MainLayout>
            }
          />

          <Route
            path="/genderManagement"
            element={
              <MainLayout>
               <GenderList />
              </MainLayout>
            }
          />
         
           <Route
            path="/genderManagement-add"
            element={
              <MainLayout>
                <GenderAdd />
              </MainLayout>
            }
          />
          
           <Route
            path="/genderManagement/edit/:id"
            element={
              <MainLayout>
                <GenderEdit />
              </MainLayout>
            }
          />
          
           <Route
            path="/genderManagement/view/:id"
            element={
              <MainLayout>
                <GenderDetail />
              </MainLayout>
            }
          />
            <Route
            path="/VideoRecommendation"
            element={
              <MainLayout>
                <VideoRecommendation />
              </MainLayout>
            }
          />
          <Route
            path="/Analytics"
            element={
              <MainLayout>
                <AnalyticsMain />
              </MainLayout>
            }
          />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
