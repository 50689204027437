import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, common } from "../../helpers";
import { Link } from "react-router-dom";

const RaceView = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [raceDetails, setRaceDetails] = useState([]);
  const [activeCheck, setActiveCheck] = useState();
  // init

  useEffect(() => {
    getRaceDetails();
  }, []);

  //API
  const getRaceDetails = () => {
    let data = {
      url: "raceView",
      method: "GET",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setRaceDetails(res?.data?.data);
        setActiveCheck(res?.data?.data?.status);
      }
    });
  };

  const editDetails = async (e) => {
    navigate(`/raceManagement/edit/${id}`);
  };

  const statusChange = function (id) {
    let data = {
      url: "raceStatus",
      method: "POST",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getRaceDetails();
      }
    });
  };

  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row mb-3">
          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <Link to="/tagGroup" class="text-white">
                  Ethnicity Details{" "}
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 d-flex justify-content-end mt-lg-0 mt-3">
            <Link
              to="/raceManagement"
              className="btn btn-muted btn-lg text-white fw-semibold me-3"
            >
              Back
            </Link>
            <button
              className="btn btn-muted btn-lg text-white fw-semibold"
              onClick={editDetails}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 p-4 rounded-1 h-100">
              <div className="d-flex justify-content-end mb-3">
                Is Active
                <div class="form-check form-switch d-flex align-items-center ms-2">
                  <input
                    class="form-check-input py-2"
                    type="checkbox"
                    role="switch"
                    name="status"
                    id="flexSwitchCheckChecked"
                    checked={activeCheck}
                    onChange={(e) => {
                      let message =
                        activeCheck == false
                          ? "Are you sure you wish to active this Ethnicity?"
                          : "Are you sure you wish to inactive this Ethnicity?";
                      var answer = window.confirm(message);
                      if (answer) {
                        statusChange(id);
                        setActiveCheck(!activeCheck);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 mb-3">
                  <h5 className="fw-medium">Ethnicity Name</h5>
                  <p
                    className="fs-6 mb-0 fw-normal "
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {raceDetails.answer}
                  </p>
                </div>
                <div className="col-lg-12 mb-3">
                  <h5 className="fw-medium">Display Order</h5>
                  <p
                    className="fs-6 mb-0 fw-normal "
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {raceDetails.displayOrder}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaceView;