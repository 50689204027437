import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api, common, FormValidation, config } from "../../helpers";
import AWS from "../../helpers/aws";
import { ValidateForm } from "./videoValidation";
import Select from "react-select";
import ModalPopup from '../../elements/Modal';

const VideoAdd = () => {
  // init
  const navigate = useNavigate();
  // state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cancerTypeList, setCancerTypeList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagGroupList, setTagGroupList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [ageGroupList, setAgegroupList] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [newVideoSource, setNewVideoSource] = useState([]);
  const [duration, setDuration] = useState([]);
  const [newImageSource, setNewImageSource] = useState([]);
  const [dragImageActive, setDragImageActive] = useState([]);
  const [videoTokenDetails, setVideoTokenDetails] = useState([]);
  const [imageTokenDetails, setImageTokenDetails] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [tagGroupvalue, setTagGroupvalue] = useState([]);
  const [tagvalue, setTagvalue] = useState([]);
  const [racevalue, setRacevalue] = useState([]);
  const [cancerTypevalue, setCancerTypevalue] = useState([]);
  const [genderValue, setGenderValue] = useState([]);
  const [ageGroupvalue, setAgeGroupvalue] = useState([]);
  let isImgUpload = "",
    isVideoUpload = "";

  const { fields, setValue } = FormValidation([]);
  const { errors, handleSubmit } = ValidateForm(fields);

  useEffect(() => {
    setValue({ displayOrder: 0 });
    getCancerList();
    getGenderList();
    getAgegroupList();
    getTagGroupList();
    getRaceList();
    getCountryList();
    getTagList();
  }, []);

  useEffect(() => {
    if (fields.tagGroup) {
      getTagList();
    }
  }, [fields?.tagGroup]);

  useEffect(() => {
    if (fields.formSubmitted) {
      handleSubmit();
    }
  }, [fields]);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // handle Image drag events
  const handleImageDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragImageActive(true);
    } else if (e.type === "dragleave") {
      setDragImageActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let files = [];
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      files = e.dataTransfer.files[0];
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setDuration({
          duration: video.duration,
          height: video.videoHeight,
        });
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    }
  };

  const handleImageDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragImageActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFileValidation(e.dataTransfer.files[0], "image", "");
    }
  };

  const handleVideoFileChange = function (e) {
    e.preventDefault();
    var files = [];
    if (e.target.files && e.target.files[0]) {
      files = e.target.files[0];
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        files = e.dataTransfer.files[0];
      }
    }
    var fileExt = files.name.split(".").pop();
    var notSupportedFileFormats = config.not_supported_video_formats;
    let filetype = common.getFileType(files);
    if (filetype === "video" && !notSupportedFileFormats.includes(fileExt)) {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        setDuration({
          duration: video.duration,
          height: video.videoHeight,
        });
        checkFileValidation(files, "video", video.duration);
      };
      video.src = URL.createObjectURL(files);
    } else {
      window.scrollTo(0, 0);
      common.notify("E", "Invalid file format");
    }
  };

  const handleImageFileChange = function (e) {
    e.preventDefault();
    if (e.target.files[0]) {
      checkFileValidation(e.target.files[0], "image", "");
    }
  };

  //Normal Creat

  const onSubmit = async (e) => {
    e.preventDefault();
    setValue("formSubmitted", true);
    let valid = handleSubmit(fields);
    if (valid) {
      setIsSubmit(true);
      videoGalleryCreate();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const checkFileValidation = (file, type, videoDuration) => {
    let payload = [];
    if (type === "image") {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "image",
      };
    } else {
      payload = {
        fileName: file?.name,
        size: String(file?.size),
        type: "video",
        duration: String(videoDuration),
      };
    }
    let data = {
      url: "stsTokenGenerate",
      method: "POST",
      body: payload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          if (type === "image") {
            setImageTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewImageSource(file);
            setValue("imageSourceName", file.name);
          } else {
            setVideoTokenDetails({
              credentials: res?.data?.data?.token?.Credentials,
              fileName: res?.data?.data?.fileName,
            });
            setNewVideoSource(file);
            setValue("videoSourceName", file.name);
          }
        } else {
          common.notify("E", type + ":  " + res.data.message);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", type + ":  " + err);
      });
  };

  const videoGalleryCreate = async () => {
    debugger;
    let body = {
      videoTitle: fields.videoTitle,
      videoDescription: fields.videoDescription,
      videoType: common.getFileType(newVideoSource),
      duration: String(duration.duration),
      resolution: String(duration.height),
      isEpisode: fields.isEpisode,
      cancerType: fields.cancerType,
      country: fields.country,
      race: fields.race,
      ageGroup: fields.ageGroup,
      gender: fields.gender,
      otherTags: fields.tags?fields.tags :[],
      tagGroup: fields.tagGroup?fields.tagGroup :[],
      priority: fields.priority,
      videoIndex: fields.videoIndex,
    };
    setLoading(true);
    let data = {
      url: "videoSave",
      method: "POST",
      body: body,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          await uploadVideoFiles(newImageSource, "image", res?.data?.data?._id);
          await uploadVideoFiles(newVideoSource, "video", res?.data?.data?._id);
        } else {
          setIsSubmit(false);
          common.notify("E", res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const uploadVideoFiles = async (file, type, videoId) => {
    let fileName = "",
      credentials = "",
      progressPercentage = 0;
    if (type === "image") {
      fileName = imageTokenDetails.fileName;
      credentials = imageTokenDetails.credentials;
    } else {
      fileName = videoTokenDetails.fileName;
      credentials = videoTokenDetails.credentials;
    }

    const params = {
      Bucket: type === "image" ? config.s3_image_path : config.s3_video_path,
      Key:
        type === "image"
          ? config.s3_video_thumpnail_folder + fileName
          : fileName,
      Body: file,
      ContentType: file?.type,
      options: { queueSize: 1 },
      httpOptions: { timeout: 0 },
      maxRetries: 10,
      signatureVersion: "v2",
    };
    const BUCKET = AWS.bucket(
      credentials?.AccessKeyId,
      credentials?.SecretAccessKey,
      credentials?.SessionToken,
      credentials?.region
    );

    BUCKET.upload(params)
      .on("httpUploadProgress", function (progress) {
        progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        if (progressPercentage < 100) {
          setProgress(progressPercentage);
          setValue("progressPercentage", progressPercentage);
        } else if (progressPercentage === 100) {
          setProgress(0);
        }
      })
      .send(function (err, data) {
        if (err) {
          setLoading(false);
          common.notify("E", err);
          setIsSubmit(false);
        } else {
          if (type === "image") isImgUpload = "true";
          else isVideoUpload = "true";

          if (isImgUpload === "true" && isVideoUpload === "true") {
            updateVideoSource(videoId);
          }
        }
      });
  };

  const updateVideoSource = (updateId) => {
    let videoSourcePayload = {
      videoSource: videoTokenDetails.fileName,
      videoThumbnail: imageTokenDetails.fileName,
    };
    let data = {
      url: "videoSource",
      method: "POST",
      query: `/${updateId}`,
      body: videoSourcePayload,
    };
    api
      .call(data, async (res) => {
        if (res.status === 200) {
          setLoading(false);
          navigate("/videos");
          common.notify("S", "Added successfully");
        } else {
          common.notify("E", res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        common.notify("E", err);
      });
  };

  const getCancerList = () => {
    let data = {
      url: "cancerSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let cancer = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...cancer];
        setCancerTypeList(options);
      }
    });
  };

  const getGenderList = () => {
    let data = {
      url: "genderSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let cancer = res?.data?.data;
        const options = [{ label: "All", value: "all" }, ...cancer];
        setGenderList(options);
      }
    });
  };

  const getAgegroupList = () => {
    let data = {
      url: "ageGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let agegroup = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...agegroup];

        setAgegroupList(options);
      }
    });
  };

  const getRaceList = () => {
    let data = {
      url: "raceSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let race = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...race];

        setRaceList(options);
      }
    });
  };

  const getTagGroupList = () => {
    let data = {
      url: "tagGroupSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tagGroup = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...tagGroup];

        setTagGroupList(options);
      }
    });
  };

  const getTagList = () => {
    setTagsList([]);
    let data = {
      url: "tagList",
      method: "POST",
      body: { tagGroup: fields.tagGroup ? fields.tagGroup : [] },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let tag = res?.data?.data;

        const options = [{ label: "All", value: "all" }, ...tag];

        setTagsList(options);
      }
    });
  };

  const getCountryList = () => {
    let data = {
      url: "countrySelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCountryList(res?.data?.data);
        handleSelect(res?.data?.data, "country");
      }
    });
  };

  const updateInputsessionCost = (evt) => {
    const value = evt.target.value;

    if (value === '' || (/^\d+(\.\d{0,2})?$/.test(value) && parseFloat(value) >= 1 && parseFloat(value) <= 1000)) {
      setValue('priority',value);
    }
    if (value === '' || parseFloat(value) > 1000) {
      return;
    }
  };

  function handleSelect(data, field) {
    let result = data.map(function getItem(item) {
      return [item.value].join(" ");
    });

    if (field === "tagGroup") {
      setTagGroupvalue(data);
    } else if (field === "tags") {
      setTagvalue(data);
    } else if (field === "race") {
      setRacevalue(data);
    } else if (field === "cancerType") {
      setCancerTypevalue(data);
    } else if (field === "gender") {
      setGenderValue(data);
    } else if (field === "ageGroup") {
      setAgeGroupvalue(data);
    }

    setValue(field, result);
  }

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row">
          <div className="col-sm-12">
            <h5 className="fw-medium mb-4">Add Video</h5>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row">
          <div className="col-12">
            <div className="bg-muted-50 rounded">
              <div className="p-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Title <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video title"
                            onChange={(e) =>
                              setValue("videoTitle", e.target.value)
                            }
                          />
                        </div>
                        <p className="error-field">{errors.videoTitle}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tag Group <i className="star">*</i>
                          </label>

                          <Select
                            class="form-select form-select-lg"
                            options={tagGroupList}
                            value={tagGroupvalue ? tagGroupvalue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find(
                                (tagGroup) => tagGroup.value === "all"
                              )
                                ? handleSelect(
                                    tagGroupList.slice(1),
                                    "tagGroup"
                                  )
                                : handleSelect(selected, "tagGroup")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.tagGroup}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Tag <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={tagsList}
                            value={tagvalue ? tagvalue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((tags) => tags.value === "all")
                                ? handleSelect(tagsList.slice(1), "tags")
                                : handleSelect(selected, "tags")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.tags}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Ethnicity <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={raceList}
                            value={racevalue ? racevalue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((race) => race.value === "all")
                                ? handleSelect(raceList.slice(1), "race")
                                : handleSelect(selected, "race")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.race}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Episode Video <i className="star">*</i>
                          </label>
                          <select
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            onChange={(e) =>
                              setValue("isEpisode", e.target.value)
                            }
                          >
                            <option value="">Select Episode Type</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                        <p className="error-field">{errors.isEpisode}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Priority <i className="star">*</i>
                          </label>
                          <input
                          type="text"
                          inputMode="numeric"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video priority"
                            value = {fields.priority}
                            onChange={updateInputsessionCost}
                          />
                        </div>
                        <p className="error-field">{errors.priority}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Video Index <i className="star">*</i>
                          </label>
                          <input
                            type="text"
                            id="input1"
                            className="form-control form-control-lg border-0"
                            placeholder="Enter video index"
                            maxLength={20}
                            onChange={(e) =>
                              setValue("videoIndex", e.target.value)
                            }
                          />
                        </div>
                        <p className="error-field">{errors.videoIndex}</p>
                      </div>
                      <div className="col-sm-12" disabled={isSubmit}>
                        <div
                          className="mb-3"
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        >
                          <label htmlFor="input1" className="form-label">
                            Upload Video <i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area text-center">
                              <div className="text-secondary xs-small">
                                ( Supported video format - *.mp4 )
                              </div>
                              <div className="my-2">
                                <button
                                  tupe="button"
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button"
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    class="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Video
                                </button>
                                <span
                                  className={
                                    newVideoSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"
                                  }
                                >
                                  {newVideoSource.name
                                    ? newVideoSource.name
                                    : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input"
                                  type="file"
                                  onChange={handleVideoFileChange}
                                />
                              </div>
                            </div>
                          </div>
                          <p className="error-field">
                            {errors.videoSourceName}
                          </p>
                        </div>
                        {progress > 0 ? (
                          <ProgressBar className="mt-2">
                            <ProgressBar
                              striped
                              animated
                              variant="success"
                              now={progress}
                              label={`${progress}%`}
                              key={3}
                            />
                          </ProgressBar>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Cancer Type <i className="star">*</i>
                          </label>
                          <Select
                            class={{
                              control: (state) =>
                                state.isFocused ? "css-13cymwt-control" : "",
                            }}
                            options={cancerTypeList}
                            value={cancerTypevalue ? cancerTypevalue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((race) => race.value === "all")
                                ? handleSelect(
                                    cancerTypeList.slice(1),
                                    "cancerType"
                                  )
                                : handleSelect(selected, "cancerType")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.cancerType}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3" disabled="true">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Country <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={countryList}
                            // onChange={(event) => handleSelect(event, "country")}
                            isSearchable={true}
                            value={countryList[0]}
                            isMulti
                            disabled="true"
                          />
                        </div>
                        <p className="error-field">{errors.country}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Gender <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={genderList}
                            value={genderValue ? genderValue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find((race) => race.value === "all")
                                ? handleSelect(genderList.slice(1), "gender")
                                : handleSelect(selected, "gender")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.gender}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Age Group <i className="star">*</i>
                          </label>
                          <Select
                            class="form-select form-select-lg"
                            options={ageGroupList}
                            value={ageGroupvalue ? ageGroupvalue : null}
                            onChange={(selected) =>
                              selected.length &&
                              selected.find(
                                (agegroup) => agegroup.value === "all"
                              )
                                ? handleSelect(
                                    ageGroupList.slice(1),
                                    "ageGroup"
                                  )
                                : handleSelect(selected, "ageGroup")
                            }
                            isSearchable={true}
                            isMulti
                          />
                        </div>
                        <p className="error-field">{errors.ageGroup}</p>
                      </div>

                      <div className="col-sm-12">
                        <div className="mb-3">
                          <label htmlFor="input2" className="form-label">
                            Description <i className="star">*</i>
                          </label>

                          <textarea
                            className="form-control form-control-lg border-0"
                            style={{ whiteSpace: "pre-wrap" }}
                            id="exampleFormControlTextarea1"
                            rows="5"
                            placeholder="Video description"
                            name="videoDescription"
                            onChange={(e) =>
                              setValue("videoDescription", e.target.value)
                            }
                          ></textarea>
                          <p className="error-field">
                            {errors.videoDescription}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12" disabled={isSubmit}>
                        <div
                          className="mb-3"
                          onDrop={handleImageDrop}
                          onDragEnter={handleImageDrag}
                          onDragLeave={handleImageDrag}
                          onDragOver={handleImageDrag}
                        >
                          <label htmlFor="input1" className="form-label">
                            Upload Thumbnail <i className="star">*</i>
                          </label>
                          <div className="bg-secondary-50 p-3 rounded">
                            <div className="file-drop-area text-center ">
                              <div className="text-secondary xs-small">
                                ( Supported image formats - *.jpg, *.jpeg, *.png
                                )
                              </div>
                              <div className="my-2">
                                <button
                                  tupe="button"
                                  className="btn btn-primary btn-sm text-white rounded-pill choose-file-button "
                                >
                                  <iconify-icon
                                    icon="ic:round-upload"
                                    class="text-white fa-lg me-2"
                                  ></iconify-icon>
                                  Select Image
                                </button>
                                <span
                                  className={
                                    newImageSource.name
                                      ? "file-message text-white fw-normal mt-3 d-flex justify-content-center"
                                      : "file-message text-secondary fw-normal mt-3 d-flex justify-content-center"
                                  }
                                >
                                  {newImageSource.name
                                    ? newImageSource.name
                                    : "Drag and drop"}
                                </span>
                                <input
                                  className="file-input"
                                  type="file"
                                  onChange={(e) => handleImageFileChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="error-field">{errors.imageSourceName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 d-flex">
                    <button
                      className="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                      disabled={isSubmit}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={onSubmit}
                      disabled={isSubmit}
                    >
                      {isSubmit ? "Processing..." : "Submit"}
                    </button>
                    <ModalPopup page="videos"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoAdd;
