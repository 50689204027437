import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, common, } from "../../helpers";
import { ValidateForm } from "./validation";
import Select from "react-select";
import ModalPopup from '../../elements/Modal';

const EpisodeAdd = () => {
  const navigate = useNavigate();
  const [showEpisodeErrorNotification, setshowEpisodeErrorNotification] =
    useState(false);
  const [error, setError] = useState([]);
  const { handleEpisodeSubmit, handleFormSubmit } = ValidateForm();
  const [videoList, setVideoList] = useState([]);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [accordion, setAccordion] = useState(false);
  const [episodeName, setEpisodeName] = useState("");
  const [episodeDescription, setEpisodeDescription] = useState("");

  const [inputEpisodeList, setInputEpisodeList] = useState([
    {
      videoId: "",
      displayOrder: 0,
    },
  ]);

  const getVideoList = () => {
    let data = {
      url: "vedioSelect",
      method: "GET",
      query: `?pagination=false`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setVideoList(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getVideoList();
  }, []);

  useEffect(() => {}, [showErrorNotification, showEpisodeErrorNotification]);
  const handleEpisodeInputChange = (e, index, fieldName) => {
    let value = "";
    if (fieldName === "videoId") {
      value = e;
    
    } else value = e.target.value;

    const list = [...inputEpisodeList];
    list[index][fieldName] = value;
    setInputEpisodeList(list);
  };
  const numberValidation = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (isFormSubmitted) {
      if (episodeDescription !== "" && episodeName !== "") {
        setshowEpisodeErrorNotification(false);
        setShowErrorNotification(false);
        setError([]);
      } else {
        let formErrors = {};
        if (episodeName === "") {
          formErrors.episodeName = "Please select Episode Name";
        }
        if (episodeDescription === "") {
          formErrors.episodeDescription = "Please select Episode Description";
        }
        setshowEpisodeErrorNotification(true);
        setError(formErrors);
      }
    }
  }, [episodeName, episodeDescription]);
  const handleEpisodeAddClick = () => {
    let lastEpisode = inputEpisodeList[inputEpisodeList.length - 1];
    let response = handleEpisodeSubmit(lastEpisode);
    if (!response.isFormValid) {
      debugger;
      setShowErrorNotification(true);
      setError(response.formErrors);
    } else {
      setAccordion(true);
      setShowErrorNotification(false);
      setInputEpisodeList([
        ...inputEpisodeList,
        {
          videoId: "",
          displayOrder: 0,
        },
      ]);
    }
  };

  const handleEpisodeRemoveClick = (index) => {
    const list = [...inputEpisodeList];
    list.splice(index, 1);
    setInputEpisodeList(list);
  };
  const handleCurrentEpisodeRemoveClick = (index) => {
    const list = [...inputEpisodeList];
    list.splice(inputEpisodeList.length - 1, 1);
    setInputEpisodeList(list);
    setShowErrorNotification(false);
    setError([]);
  };

  const handleSubmit = (e) => {
    setIsFormSubmitted(true);
    let lastQuestion = inputEpisodeList[inputEpisodeList.length - 1];
    let response = handleEpisodeSubmit(lastQuestion);
    if (!response.isFormValid) {
      debugger;
      setShowErrorNotification(true);
      setError(response.formErrors);
    } else {
      let formErrors = {};

      if (episodeName !== "" && episodeDescription !== "") {
        setshowEpisodeErrorNotification(false);
        setShowErrorNotification(false);
        setError([]);
        let res = handleFormSubmit(inputEpisodeList);
        if (res.isFormValid) {
          setshowEpisodeErrorNotification(false);
          
          inputEpisodeList.map((videos) => {
            videos.videoId = videos.videoId.value
            return true;
        } )
        let payloadEpisode = JSON.parse(JSON.stringify(inputEpisodeList));
          const payload = {
            episodeName: episodeName,
            episodeDescription: episodeDescription,
            videos: payloadEpisode,
            releaseDate: "2022-09-06T18:34:19.767+05:30",
          };

          let data = {
            url: "addEpisode",
            method: "POST",
            body: payload,
          };
          api
            .call(data, async (res) => {
              debugger;
              if (res.data.status === "success") {

                navigate("/episode");
                common.notify("S", res.data.msg);
               
              } else {
                window.scrollTo(0, 0);
                common.notify("E", res.data.message);
              }
            })
            .catch((err) => {
              window.scrollTo(0, 0);
              common.notify("E", err);
            });
        } else {
          setshowEpisodeErrorNotification(true);
          setShowErrorNotification(true);
          setError(res.formErrors);
        }
      } else {
        if (episodeName === "") {
          formErrors.episodeName = "Please enter title";
        }
        if (episodeDescription === "") {
          formErrors.episodeDescription = "Please enter description";
        }
        setshowEpisodeErrorNotification(true);
        setError(formErrors);
      }
    }
  };
  return (
    <>
      <div class="container-fluid pt-3 px-4">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="fw-medium mb-4">Add Episodes & group</h5>
          </div>
        </div>
      </div>
      <div class="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div class="row h-100">
          <div class="col-12">
            <div class="bg-muted-50 rounded h-100">
              <div class="p-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">
                        Title
                        <i className="star">*</i>
                      </label>
                      <input
                        type="text"
                        id="input1"
                        class="form-control form-control-lg border-0"
                        placeholder="User name"
                        name="episodeName"
                        defaultValue={""}
                        value={episodeName || ""}
                        onChange={(e) => setEpisodeName(e.target.value)}
                      />
                      <p className="error-field">{error.episodeName}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label for="input2" class="form-label">
                        Description
                        <i className="star">*</i>
                      </label>
                      <textarea
                        class="form-control form-control-lg border-0"
                        id="exampleFormControlTextarea1"
                        rows="1"
                        placeholder="Description"
                        name="episodeDescription"
                        onChange={(e) => setEpisodeDescription(e.target.value)}
                      ></textarea>
                      <p className="error-field">{error.episodeDescription}</p>
                    </div>
                  </div>
                  {showErrorNotification ? (
                    <div className="d-table-caption">
                      {error.requiredFields ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.requiredFields}{" "}
                        </span>
                      ) : null}
                      {error.duplicateEpisode ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.duplicateEpisode}{" "}
                        </span>
                      ) : null}
                      {error.displayOrder ? (
                        <span class="badge rounded-pill bg-danger-75 text-danger fs-5 fw-medium px-3 py-2 mb-2">
                          <i class="fas fa-exclamation-triangle me-3"></i>
                          {error.displayOrder}{" "}
                        </span>
                      ) : null}
                    </div>
                  ) : null}

                  <div class="col-sm-12">
                    {accordion
                      ? inputEpisodeList.map((item, i) =>
                          inputEpisodeList.length !== i + 1 ? (
                            <div class="bg-muted-50 rounded p-3 mb-3">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="accordion" id="accordionExample">
                                    <div class="accordion-item border-0">
                                      <h2
                                        class="accordion-header d-flex"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button collapsed px-0 pb-3"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          <span class="col-6 pe-3">
                                            Video: {item.videoId.label || ""}
                                          </span>
                                          <span class="ms-2">
                                            Display Order:{" "}
                                            {item.displayOrder || ""}
                                          </span>
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-muted text-danger d-flex p-2 align-self-center align-items-center btn-trash"
                                          onClick={(e) => {
                                            if (
                                              window.confirm(
                                                "Are you sure you wish to delete this item?"
                                              )
                                            )
                                              handleEpisodeRemoveClick(i);
                                          }}
                                        >
                                          <iconify-icon
                                            icon="mdi:trash"
                                            class="fa-2x align-self-center"
                                          ></iconify-icon>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body px-0">
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <div class="mb-md-0 mb-3">
                                                <label
                                                  for="input2"
                                                  class="form-label"
                                                >
                                                  Video
                                                </label>
                                                <Select
                                                  class="form-select form-select-lg"
                                                  options={videoList}
                                                  value={item.videoId}
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e,
                                                      i,
                                                      "videoId",
                                                      
                                                    )
                                                  }
                                                  isSearchable={true}
                                                  isMulti={false}
                                                />
                                                {/* <select
                                                  class="form-select form-select-lg"
                                                  aria-label="Default select example"
                                                  value={item.videoId || ""}
                                                  name="videoId"
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e,
                                                      i,
                                                      "videoId"
                                                    )
                                                  }
                                                >
                                                  {videoList.length > 0
                                                    ? videoList.map((item) => (
                                                        <option
                                                          value={item.value}
                                                        >
                                                          {item.label}
                                                        </option>
                                                      ))
                                                    : null}
                                                </select> */}
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                              <div class="mb-md-0 mb-3">
                                                <label
                                                  for="exampleFormControlInput1"
                                                  class="form-label"
                                                >
                                                  Display Order
                                                </label>
                                                <input
                                                  type="text"
                                                  id="input1"
                                                  class="form-control form-control-lg border-0"
                                                  placeholder="Enter display order"
                                                  value={
                                                    item.displayOrder || ""
                                                  }
                                                  min="1"
                                                  onKeyPress={(e) =>
                                                    numberValidation(e)
                                                  }
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e,
                                                      i,
                                                      "displayOrder"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )
                      : null}
                    {inputEpisodeList.map((x, i, inputEpisodeList) => {
                      if (i + 1 === inputEpisodeList.length) {
                        return (
                          <div class="bg-muted-50 rounded p-3">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="mb-md-0 mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Video
                                    <i className="star">*</i>
                                  </label>
                                  <Select
                                                  class="form-select form-select-lg"
                                                  options={videoList}
                                                  value={x.videoId}
                                                  onChange={(e) =>
                                                    handleEpisodeInputChange(
                                                      e,
                                                      i,
                                                      "videoId",
                                                      
                                                    )
                                                  }
                                                  isSearchable={true}
                                                   isMulti={false}
                                                />

                                  {/* <select
                                    class="form-select form-select-lg"
                                    aria-label="Default select example"
                                    value={x.videoId || ""}
                                    name="videoId"
                                    onChange={(e) =>
                                      handleEpisodeInputChange(e, i, "videoId")
                                    }
                                  >
                                    <option selected="">Select video</option>

                                    {videoList.length > 0
                                      ? videoList.map((item) => (
                                          <option value={item.value}>
                                            {item.label}
                                          </option>
                                        ))
                                      : null}
                                  </select> */}
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="mb-md-0 mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Display Order
                                    <i className="star">*</i>
                                  </label>
                                  <input
                                    type="text"
                                    id="input1"
                                    class="form-control form-control-lg border-0"
                                    placeholder="Enter display order"
                                    value={x.displayOrder || ""}
                                    min="1"
                                    onKeyPress={(e) => numberValidation(e)}
                                    onChange={(e) =>
                                      handleEpisodeInputChange(
                                        e,
                                        i,
                                        "displayOrder"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return true;
                    })}

                    <div class="d-flex">
                      <button
                        type="button"
                        class="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium me-3"
                        onClick={handleEpisodeAddClick}
                      >
                        <iconify-icon
                          icon="material-symbols:add-box-outline"
                          class="me-2 fa-lg"
                        ></iconify-icon>
                        Add Video
                      </button>
                      {inputEpisodeList.length !== 1 ? (
                        <button
                          type="button"
                          class="btn btn-muted btn-lg w-auto text-white d-flex px-4 mt-3 fw-medium"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Are you sure you wish to delete this item?"
                              )
                            )
                              handleCurrentEpisodeRemoveClick();
                          }}
                        >
                          <iconify-icon
                            icon="mingcute:delete-2-line"
                            class="me-2 fa-lg"
                          ></iconify-icon>
                          Delete Current Video
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12 d-flex">
                    <button
                      class="btn btn-muted btn-lg text-white fw-semibold" data-bs-toggle="modal" data-bs-target="#backAlert"
                      type="button"
                    >
                      Back
                    </button>
                    <button
                      class="btn btn-primary btn-lg text-white fw-semibold ms-3"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <ModalPopup page="episode"></ModalPopup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EpisodeAdd;
