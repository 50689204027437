import React, { useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { api, common } from "../../helpers";
import Table from "../../elements/Table";

export default function AgeGroupList(props) {
  //inti
  const [ageGroupList, setAgeGroupList] = useState([]);
  const [itemSize, setItemSize] = useState(10);
  const [loading, setLoading]=useState(false);
  
  const [search, setSearch] = useState("");

  const tableHeader = ["#", "Age","Display Order", "Status", " "];
  const tableColumn = ["answer","displayOrder","status"];


  useEffect(() => {
    getAgeGroupList();
  }, [search]);

  
  const deleteItem = function (id) {
    let data = {
      url: "ageGroupDelete",
      method: "DELETE",
      query: `${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", res?.data?.msg);
        getAgeGroupList();
      }
    });
  };

   //API
  const getAgeGroupList = () => {
    setLoading(true);
    let data = {
      url: "agegroupList",
      method: "GET",
      query: `?search=${search}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setAgeGroupList(res?.data?.data);
        setItemSize(res?.data?.data?.limit);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className="container-fluid pt-3 px-4">
        <div className="row mb-4">
          <div className="col-sm-6 d-flex">
            <h5 className="fw-medium mb-0 align-self-center">Age Group</h5>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-end align-self-center">
              <div className="input-group input-group-sm w-50 me-3">
                <span
                  className="input-group-text rounded-start bg-muted"
                  id="basic-addon1"
                >
                  <i className="bi bi-search text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control rounded-end ps-0 bg-muted border-0"
                  placeholder="Search here..."
                  aria-label="Search here"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Link
                to="/ageGroup-add"
                className="btn btn-primary btn-lg w-auto text-white d-flex px-4 fw-medium col-3"
              >
                <iconify-icon
                  icon="material-symbols:add-box-outline"
                  class="me-2 fa-lg"
                ></iconify-icon>
                Add Age Group
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4 mb-4 overflow-auto flex-fill">
        <div className="row h-100">
          <div className="col-12">
            <div className="bg-muted-50 rounded-1 h-100">
              
              <div className="table-responsive">
                <Table
                  tableHeader={tableHeader}
                  tableColumn={tableColumn}
                  tableData={ageGroupList}
                  type={"ageGroup"}
                  onDelete={deleteItem}
                  activePage={1}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
